import { useLocation, useNavigate, Link } from "react-router-dom";
import { IFleet } from "@interfaces/index";
import {
  useAuthStore,
  useDashboardStore,
  useSidebarStore
} from "@store/index";
import useFleetAndDevicesStore from "@store/fleet-and-devices/fleet-and-devices.store";
import SettingsDropdownMenu from "./settings-dropdown-menu";
import HelpDropdownMenu from "./help-dropdown-menu";
import useWalkthroughStore from "@store/walkthrough.store";
import ProjectsDropdownMenu from "./common-header/projects-dropdown-menu.component";
import OrgDropdownMenu from "./common-header/org-dropdown-menu.component";
import useOTAReleaseStore from "@store/ota/ota-release.store";

import { useEffect, useMemo, useState } from "react";
import { overviewSteps } from "../walkthroughs";
import { useTour } from "@reactour/tour";
import Modal from "./modal.component";
import { Button, Callout } from "@tremor/react";
import ELEMENT_IDS from "../walkthroughs/walkthrough-elements-ids.util";
import useThemeStore from "@/store/theme.store";
import {
  ArrowRightIcon,
  InformationCircleIcon,
  UserPlusIcon
} from "@heroicons/react/24/outline";
import DashboardSelector from "./dashboard-selector";
import { useDuplicateProject } from "../hooks/post/duplicate-projcet";
import { toast } from "react-toastify";

const WALKTHROUGH_NAME = "golain-overview";
const WALKTHROUGH_ITEM_NAME = "collaborators";

function CommonHeader() {
  const navigate = useNavigate();
  const location = useLocation();

  const { setIsOpen, setCurrentStep, setSteps } = useTour();

  const [sidebarOpen, toggleSidebar] = useSidebarStore((state) => [
    state.sidebarOpen,
    state.toggleSidebar
  ]);

  const theme = useThemeStore((state) => state.theme);

  const [showTourModal, setShowTourModal] = useState(false);
  const [showDuplicateProjModal, setShowDuplicateProjModal] = useState(false);

  const [duplicateProjectName, setDuplicateProjectName] = useState("");

  const [shownOverview, setShownOverview] = useWalkthroughStore((state) => [
    state.shownOverview,
    state.setShownOverview
  ]);

  const duplicateProjMutation = useDuplicateProject();

  const [
    setActiveDashboard,
    setDashboards,
    setPanels,
    setCarousels,
    setLayouts
  ] = useDashboardStore((state) => [
    state.setActiveDashboard,
    state.setDashboards,
    state.setPanels,
    state.setCarousels,
    state.setLayouts
  ]);

  const [setReleaseList, setActiveRelease, setDeploymentList] =
    useOTAReleaseStore((state) => [
      state.setReleaseList,
      state.setActiveRelease,
      state.setDeploymentList
    ]);

  const [{ selectedOrg, organization }, updateAuthUser] = useAuthStore(
    (state) => [state.user, state.updateAuthUser]
  );

  const [
    projects,
    selectedProject,
    setSelectedProject,
    setFleets,
    setSelectedFleet
  ] = useFleetAndDevicesStore((state) => [
    state.projects,
    state.selectedProject,
    state.setSelectedProject,
    state.setFleets,
    state.setSelectedFleet
  ]);

  const handleProjectSelection = (projectId) => {
    if (projectId === selectedProject.id) {
      return;
    }

    const selectedProjectId = projectId;

    if (selectedProjectId === "Create Project") {
      return navigate("fleet-and-devices/projects/project-details");
    }

    const project = projects.find(
      (project) => project.id === selectedProjectId
    );

    setSelectedProject(project);

    handleReRoutesOnProjectChange();
  };

  const handleReRoutesOnProjectChange = () => {
    // reset states:
    // DASHBOARDS:
    setActiveDashboard({} as any);
    setDashboards([]);
    setCarousels({});
    setPanels([]);
    setLayouts({});

    // FLEETS and DEVICES:
    setSelectedFleet({} as IFleet);
    setFleets([]);

    // OTA DEPLOYMENTS and RELEASES:
    setReleaseList([]);
    setActiveRelease({} as any);
    setDeploymentList([]);

    if (location.pathname.includes("/dashboard/")) {
      return navigate("/dashboard", { replace: true });
    }

    if (location.pathname.includes("/definitions/shadows")) {
      return navigate("/definitions/shadows", { replace: true });
    }

    if (location.pathname.includes("/definitions/data-points")) {
      return navigate("/definitions/data-points", { replace: true });
    }

    if (
      location.pathname.includes("/settings/organization-settings/groups/")
    ) {
      return navigate("/settings/organization-settings/groups", {
        replace: true
      });
    }

    const fadProjectSubPaths = [
      "project-details",
      "about-fleet",
      "provisioning-policy",
      "shadow-policy",
      "create-device",
      "about-device",
      "apply-shadow",
      "apply-data-points",
      "download-certificates",
      "device-creation-successful",
      "device-details"
    ];

    if (
      fadProjectSubPaths.some((path) =>
        location.pathname.startsWith("/fleet-and-devices/projects/" + path)
      )
    ) {
      return navigate("/fleet-and-devices/projects");
    }

    if (
      location.pathname.includes(
        "/ota-updates/projects/deployment/deployment-details"
      ) ||
      location.pathname.includes(
        "/ota-updates/projects/deployment/create-deployment"
      )
    ) {
      return navigate("/ota-updates/projects/deployment");
    }
  };

  const handleOrgSelection = async (orgId: string) => {
    const selectedOrgId = orgId;

    if (selectedOrgId === "Create Org") {
      return navigate("/onboarding/organization-details");
    }

    if (selectedOrgId === selectedOrg.id) {
      return;
    }

    updateAuthUser({
      selectedOrg: organization.find((org) => org.id === selectedOrgId)
    });

    handleReRoutesOnProjectChange();
  };

  useEffect(() => {
    let timer;
    if (location.state?.showOverviewTourModal && !shownOverview) {
      timer = setTimeout(() => {
        setShowTourModal(true);
      }, 2000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [location.state?.showOverviewTourModal, shownOverview]);

  let greeting = useMemo(() => {
    let timeNow = new Date().getHours();
    const _greeting =
      timeNow >= 5 && timeNow < 12
        ? "Good Morning"
        : timeNow >= 12 && timeNow < 18
        ? "Good Afternoon"
        : "Good Evening";

    return _greeting;
  }, []);

  const onTakeATourClick = () => {
    setSteps(
      overviewSteps({
        openSidebar: () => {
          toggleSidebar(true);
        },
        closeSidebar: () => {
          toggleSidebar(false);
        },
        setShownOverviewTour: () => {
          setShownOverview(true);
        },
        endTour: () => {
          setIsOpen(false);
          navigate("/fleet-and-devices/projects");
        }
      })
    );

    setCurrentStep(0);

    setIsOpen(true);
    setShowTourModal(false);
  };

  const onDuplicateClick = () => {
    duplicateProjMutation.mutate(
      {
        project_body: {
          project_name: duplicateProjectName,
          // FIXME: Take inputs
          hosting_platform: "AWS",
          region: "IN"
        }
      },
      {
        onSuccess: () => {
          toast.success("Project Duplicated Successfully!");
        },
        onSettled: () => {
          setShowDuplicateProjModal(false);
        }
      }
    );
  };

  return (
    <>
      <header className="bg-background z-50 fixed w-full top-0 left-0 text-contentColor border-b text-xs border-b-background-layer2">
        <nav className="flex items-center justify-between h-16 px-4 lg:px-8 sm:px-6">
          <button
            id={ELEMENT_IDS.SIDEBAR_OPEN}
            onClick={() => toggleSidebar(!sidebarOpen)}
            className="text-contentColor pr-4"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-5 h-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16m-7 6h7"
              />
            </svg>
          </button>
          {/* <img src={ezpedalLogo} alt="clientLogo" className="max-w-[2rem]" /> */}
          <div className="flex w-8/12 items-center gap-2">
            <OrgDropdownMenu
              organizations={organization}
              selectedOrg={selectedOrg}
              handleOrgSelection={handleOrgSelection}
            />

            <span
              className="bg-gray-300 text-xl h-4"
              style={{ width: 1, transform: "rotate(20deg)" }}
            ></span>

            <ProjectsDropdownMenu
              projects={projects}
              selectedProject={selectedProject}
              handleProjectSelection={handleProjectSelection}
              setShowDuplicateProjModal={setShowDuplicateProjModal}
            />
            {location.pathname.includes("/dashboard/") ? (
              <>
                <span
                  className="bg-background-layer3 text-xl h-4"
                  style={{ width: 1, transform: "rotate(20deg)" }}
                />
                <span className="text-contentColorLight mx-2 text-sm italic">
                  dashboards
                </span>
                <span
                  className="bg-background-layer3 text-xl h-4"
                  style={{ width: 1, transform: "rotate(20deg)" }}
                />
                <DashboardSelector />
              </>
            ) : null}
          </div>

          <div className="flex justify-center items-center">
            <Button
              tooltip="Overview"
              variant="light"
              onClick={() => {
                setShowTourModal(true);
              }}
              className="ml-6 cursor-pointer w-10 h-10 flex justify-center items-center bg-background-layer1 rounded-full"
            >
              <InformationCircleIcon
                className="text-primaryLight"
                width={20}
              />
            </Button>

            <Link to="/settings/organization-settings/invites">
              <Button
                tooltip="Collaborators"
                variant="light"
                className="ml-6 w-10 h-10 flex justify-center items-center bg-background-layer1 rounded-full"
              >
                <UserPlusIcon
                  id={ELEMENT_IDS.COLLABORATORS_ICON}
                  className="text-primaryLight"
                  width={20}
                />
              </Button>
            </Link>
            <SettingsDropdownMenu />

            <HelpDropdownMenu />
          </div>
        </nav>
      </header>
      <Modal
        open={showTourModal}
        setOpen={setShowTourModal}
        title="Hey there!"
      >
        <div className="flex flex-col max-w-lg gap-4 p-6 bg-background-layer1 text-contentColor">
          <h1 className="text-lg">{greeting}!</h1>
          <img
            src={`${process.env.PUBLIC_URL}/logos/golain-full-${
              theme === "dark-default" ? "light" : "dark"
            }.svg`}
            alt="golain logo"
            className="my-12"
          />
          <h1 className="text-lg font-bold">Looks like you're new here.</h1>
          <p>
            Let us take you on a tour of the platform to highlight some of the
            most important features!
          </p>
          <Callout title={"Note:"} icon={InformationCircleIcon}>
            You can always open this tour again by clicking the '
            <InformationCircleIcon
              className="inline text-primary"
              width={20}
            />
            ' icon in the top bar.
          </Callout>

          <div className="flex gap-6 justify-end items-end">
            <Button
              variant="light"
              onClick={() => {
                setShownOverview(true);
                setShowTourModal(false);
              }}
              className="mb-2.5"
            >
              Don't show again
            </Button>
            <div className="flex flex-col gap-2 items-end">
              <span className="text-xs mr-2 text-contentColorLight">
                {"ETA: < 1min"}
              </span>
              <Button
                icon={ArrowRightIcon}
                iconPosition="right"
                onClick={onTakeATourClick}
              >
                Take a tour!
              </Button>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        open={showDuplicateProjModal}
        setOpen={setShowDuplicateProjModal}
        title="Duplicate Project"
      >
        <div className="flex flex-col max-w-lg gap-4 p-6 bg-background-layer1 text-contentColor">
          <h1 className="text-lg font-bold">Duplicate Project</h1>
          <p>
            Duplicate all the resources from this project excluding devices!
          </p>
          <Callout title={"Note:"} icon={InformationCircleIcon}>
            You can only run this operation once every 15mins!
          </Callout>

          <div>
            <label>
              Project Name
              <input
                value={duplicateProjectName}
                onChange={(e) => setDuplicateProjectName(e.target.value)}
                placeholder="Project Name"
                className="block w-[350px] p-3 mt-2 bg-background-layer1 border border-background-layer3 rounded-md focus:ring focus:ring-opacity-40 focus:ring-primaryLight focus:border-primaryLight sm:text-sm"
              />
            </label>
            {/* TODO: Add error messages */}
          </div>

          <div className="flex gap-6 justify-end items-end">
            <Button
              variant="light"
              onClick={() => {
                setShowDuplicateProjModal(false);
              }}
              className="mb-2.5"
            >
              Close
            </Button>
            <div className="flex flex-col gap-2 items-end">
              <Button
                disabled={duplicateProjectName?.trim()?.length === 0}
                loading={duplicateProjMutation.isLoading}
                onClick={onDuplicateClick}
              >
                Duplicate
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default CommonHeader;
