import { useEffect, useMemo, useState } from "react";
import Editor from "@monaco-editor/react";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";

import { Field, Form, Formik, ErrorMessage } from "formik";

import { toast } from "react-toastify";
import { IFleet } from "../../../interfaces";
import { useAuthStore } from "../../../store";
import useFleetAndDevicesStore, {
  IFleetCreationFlow
} from "../../../store/fleet-and-devices/fleet-and-devices.store";
import useShadowStore from "../../../store/shadow/shadow.store";
import { FieldError } from "../../shared/components";
import {
  copyToClipboard,
  pasteFromClipboard,
  reactSelectClassNames
} from "../../shared/utils/helper.util";
import SuggestionPanel from "../components/fad-suggestion-panel.component";
import FleetCreationSteps from "../components/fleet-creation-steps.component";
import { object, string } from "yup";
import { useGetShadowDefinitions } from "@app/shared/hooks/get/shadow-definitions";
import { useCreateShadowDefinitions } from "@app/shared/hooks/post/create-shadow-definition";
import { useCreateFleet } from "@app/shared/hooks/post/create-fleet";
import { useCreateDeviceDataPolicy } from "@app/shared/hooks/post/device-data-policy";
import { useGetFleets } from "@app/shared/hooks/get/fleets";
import useThemeStore from "@store/theme.store";
import {
  FieldInfo,
  getUserInputForField,
  parseProtoFile,
  updateProtoFile,
  verifySemicolonEndingsInProto
} from "@/app/shared/utils/proto-helper.util";
import Modal from "@/app/shared/components/modal.component";
import { Button } from "@tremor/react";
import ProtoLimitsForm from "../components/proto-limits-form.component";

const defaultShadow =
  `syntax = "proto3";\n\n` +
  // `import "nanopb.proto";\n\n` +
  `message Shadow {\n` +
  `\tint32 red = 1;\n` +
  `\tint32 green = 2;\n` +
  `\tint32 blue = 3;\n` +
  `\tbool on = 4;\n` +
  `}`;

const newShadowDefinitionSchema = object().shape({
  shadowName: string().required("Please enter shadow name.")
});

interface IShadowPolicyProps {
  hideSteps?: boolean;
  backClick?: () => void;
  nextClick?: (selectedShadowDefId: string) => void;
}

const ShadowPolicy: React.FC<IShadowPolicyProps> = ({
  hideSteps,
  backClick,
  nextClick
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const cameFromCreateShadowDef =
    location.state === "create-shadow-definition";

  const [theme] = useThemeStore((state) => [state.theme]);
  const [updateAuthUser] = useAuthStore((state) => [state.updateAuthUser]);
  const [fleetCreation, setFleetCreation] = useFleetAndDevicesStore(
    (state) => [state.fleetCreation, state.setFleetCreation]
  );
  const [shadow, setMaximizeShadow, setShadow] = useShadowStore((state) => [
    state.shadow,
    state.setMaximizeShadow,
    state.setShadow
  ]);
  const [setFleets, setSelectedFleet] = useFleetAndDevicesStore((state) => [
    state.setFleets,
    state.setSelectedFleet
  ]);

  const [isCreateShadow, setIsCreateShadow] = useState(
    cameFromCreateShadowDef
  );
  const [shadowName, setShadowName] = useState("");

  const [selectedShadow, setSelectedShadow] = useState<
    { value: string; label: string } | undefined
  >();
  const [customShadow, setCustomShadow] = useState(defaultShadow);
  const [recentCreatedShadow, setRecentCreatedShadow] = useState("");
  const [errorMsgs, setErrorMsgs] = useState({
    predefinedShadow: "",
    customShadow: ""
  });

  const [modalStates, setModalStates] = useState({
    openOptionsPrompt: false,
    optionsModal: false
  });

  const [fieldInfos, setFieldInfos] = useState<FieldInfo[]>([]);

  const { data: predefinedShadows, isLoading: isShadowDefsLoading } =
    useGetShadowDefinitions({
      fields: "shadow_proto_structure,shadow_proto"
    });
  const { refetch: refetchFleets } = useGetFleets();

  const createShadowDefMutation = useCreateShadowDefinitions();
  const createFleetMutation = useCreateFleet();
  const createDeviceDataPolicyMutation = useCreateDeviceDataPolicy();

  useEffect(() => {
    // if (!fleetCreation?.fleet_name?.trim()) {
    //   navigate("/fleet-and-devices/projects/about-fleet/", { replace: true });
    //   return;
    // }
    updateAuthUser({ fleetCreationStatus: "2" });
  }, [updateAuthUser]);

  useEffect(() => {
    if (shadow) {
      setCustomShadow(shadow);
    }
  }, [shadow]);

  const handleClick = async () => {
    if (selectedShadow) {
      if (nextClick) {
        nextClick(selectedShadow.value);
        return;
      }
      createFleetMutation.mutate(
        {
          fleet_name: fleetCreation.fleet_name,
          fleet_description: fleetCreation.fleet_description,
          shadow_definition_id: selectedShadow.value
        },
        {
          onSuccess: (id) => {
            createDeviceDataPolicyMutation.mutate({
              data: {
                policy_name: "default",
                policy:
                  '{"v":1,"def":{"storage-format":"TSDB","retention-period":0}}'
              },
              fleetId: id
            });

            refetchFleets().then(({ data: fleets }) => {
              if (fleets?.length) {
                setFleets(fleets);

                const newlyCreatedFleet = fleets.filter(
                  (fleet) => fleet.id === id
                );
                setSelectedFleet({ ...newlyCreatedFleet[0] });
              } else {
                setFleets([]);
                setSelectedFleet({} as IFleet);
              }

              const createdFleetName = fleetCreation.fleet_name;
              toast.success("Created Fleet successfully!");
              navigate("/fleet-and-devices/projects/create-device/", {
                state: { fleetName: createdFleetName }
              });
              setFleetCreation({} as IFleetCreationFlow);
            });
          }
        }
      );
    } else {
      setErrorMsgs({
        ...errorMsgs,
        predefinedShadow: "Please select a shadow"
      });
    }
  };

  const handleMaximizeEditor = () => {
    setShadow(customShadow);
    setMaximizeShadow({ state: true, readOnly: false });
  };

  const createShadow = ({ shadow, values }) => {
    let shadowWithNanoPBImport: string = shadow;
    // const nanoPBImportLine = 'import "nanopb.proto";';
    // if (shadow.indexOf(nanoPBImportLine) < 0) {
    //   const syntaxLine = 'syntax = "proto3";';

    //   const protoSyntaxIndex = shadow.indexOf(syntaxLine);

    //   if (protoSyntaxIndex >= 0) {
    //     shadowWithNanoPBImport = shadowWithNanoPBImport.slice(
    //       0,
    //       protoSyntaxIndex + syntaxLine.length
    //     );
    //     shadowWithNanoPBImport += "\n" + nanoPBImportLine + "\n";
    //     shadowWithNanoPBImport += shadow.slice(
    //       protoSyntaxIndex + syntaxLine.length
    //     );
    //   }
    // }

    const file = new File([shadowWithNanoPBImport], "datap.proto", {
      type: "text/plain"
    });

    const formData = new FormData();
    formData.append("protoFile", file);
    formData.append("name", values.shadowName);

    createShadowDefMutation.mutate(formData, {
      onSuccess: (id) => {
        setRecentCreatedShadow(id);
        setIsCreateShadow(false);
        if (cameFromCreateShadowDef) {
          navigate("/definitions/shadows");
          toast.success("Created new Shadow Definition Successfully!");
        }
      },
      onSettled: () => {
        setModalStates({
          openOptionsPrompt: false,
          optionsModal: false
        });
      }
    });
  };

  const submitCustomShadow = async (
    values: {
      shadowName: string;
    },
    { resetForm }
  ) => {
    if (!customShadow) {
      setErrorMsgs({ ...errorMsgs, customShadow: "Please define shadow" });
    } else {
      setErrorMsgs({ ...errorMsgs, customShadow: "" });
    }

    const validProto = verifySemicolonEndingsInProto(customShadow);

    if (!validProto) {
      toast.error(
        <div>
          <h3 className="text-base">{"Bad Proto Syntax"}</h3>

          <p className="text-sm">
            {
              "Ensure you have a single field on every line and don't have any trailing spaces."
            }
          </p>
        </div>
      );
      return;
    }

    let fieldInfos = parseProtoFile(customShadow);

    if (fieldInfos.length) {
      setShadowName(values.shadowName);
      setFieldInfos(fieldInfos);
      setModalStates({ openOptionsPrompt: true, optionsModal: false });
    } else {
      createShadow({ shadow: customShadow, values });
    }
  };

  const handleShadowEditor = (value: string) => {
    setCustomShadow(value);
  };

  const handleShadowSelect = (shadow: { value: string; label: string }) => {
    if (errorMsgs.predefinedShadow) {
      setErrorMsgs({ ...errorMsgs, predefinedShadow: "" });
    }
    setSelectedShadow(shadow);
  };

  const downloadFile = () => {
    const filename = document.getElementById("shadowName")["value"];

    if (filename) {
      const url = window.URL.createObjectURL(
        new Blob([customShadow], { type: "text/plain" })
      );

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${filename}.proto`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } else {
      toast.warning("To download please enter shadow name.");
    }
  };

  const options = useMemo(() => {
    let selected = undefined;
    const shadows =
      predefinedShadows?.map(({ name, id }) => {
        if (recentCreatedShadow === id) {
          selected = { value: id, label: name };
        }

        return { value: id, label: name };
      }) || [];

    if (shadows.length) {
      if (!selected) {
        selected = shadows[0];
      }

      setSelectedShadow(selected);
    }

    return shadows;
  }, [predefinedShadows, recentCreatedShadow]);

  const handleSetLimitsSubmit = (values: Record<string, number>) => {
    const _fieldInfos = fieldInfos.map((fieldInfo) => {
      fieldInfo.fieldLimit =
        values[`${fieldInfo.name}-${fieldInfo.type}-${fieldInfo.line}`];

      return getUserInputForField(fieldInfo);
    });

    const updatedShadow = updateProtoFile(customShadow, _fieldInfos);

    createShadow({ shadow: updatedShadow, values: { shadowName } });
  };

  return (
    <>
      <div className="flex w-full h-full">
        <div className="w-5/12  pb-8">
          {!cameFromCreateShadowDef && !hideSteps ? (
            <div className="mt-7 mb-5 w-10/12">
              <FleetCreationSteps />
            </div>
          ) : null}

          <div>
            <h1 className="text-lg text-left font-medium mb-2.5">
              Shadow Policy
            </h1>

            <Formik
              initialValues={{ shadowName: "" }}
              validationSchema={newShadowDefinitionSchema}
              onSubmit={submitCustomShadow}
            >
              <Form>
                {/* Conditional Render For Predefined Shadows or Shadow name */}
                {isCreateShadow ? (
                  <div className="form-group mb-5 w-10/12">
                    <label className="flex font-medium text-sm mb-2">
                      Shadow Name
                    </label>
                    <Field
                      type="text"
                      id="shadowName"
                      name="shadowName"
                      placeholder="Shadow Name"
                      className="block w-full p-3 mt-2 bg-background text-contentColor border-background-layer3 border rounded-md focus:ring focus:ring-opacity-40 focus:ring-primary focus:border-primaryLight sm:text-sm"
                    />
                    <ErrorMessage name="shadowName">
                      {(msg) => <FieldError message={msg} />}
                    </ErrorMessage>
                  </div>
                ) : (
                  <div className="form-group mb-5 w-10/12">
                    <label className="flex font-medium text-sm mb-2">
                      Predefined Shadows
                    </label>
                    <Select
                      isDisabled={isCreateShadow}
                      placeholder="Select"
                      isSearchable={true}
                      options={options}
                      isLoading={isShadowDefsLoading}
                      value={selectedShadow}
                      onChange={handleShadowSelect}
                      classNames={reactSelectClassNames}
                    />
                    {errorMsgs.predefinedShadow ? (
                      <FieldError message={errorMsgs.predefinedShadow} />
                    ) : (
                      ""
                    )}
                  </div>
                )}

                {/* Conditional Render for Shadow Def Editor */}
                {isCreateShadow && (
                  <div className="form-group mb-5 lg:w-10/12">
                    <label className="flex font-medium text-sm">
                      Shadow Definition
                    </label>
                    <p className="text-xs mb-2">
                      Define your device's shadow using{" "}
                      <a
                        href="https://protobuf.dev/programming-guides/proto3/"
                        target="_blank"
                        rel={"noreferrer noopener"}
                        className=" underline"
                      >
                        Protocol Buffers 3 syntax
                      </a>
                      .
                    </p>
                    <div className="p-2 bg-background rounded-lg border border-solid border-background-layer3 w-min lg:w-full">
                      <div>
                        <div className="flex justify-between items-center">
                          <ul className="flex justify-center items-center">
                            <li className="flex items-center">
                              <button
                                type="button"
                                onClick={() => copyToClipboard(customShadow)}
                                className="text-xs text-contentColorLight flex justify-center items-center"
                              >
                                Copy
                              </button>
                              <div className="mx-3.5 h-3 border border-solid border-background-layer3"></div>
                            </li>

                            <li className="flex items-center">
                              <button
                                type="button"
                                onClick={() =>
                                  pasteFromClipboard().then((res) =>
                                    setCustomShadow(res)
                                  )
                                }
                                className="text-xs text-contentColorLight flex justify-center items-center"
                              >
                                Paste
                              </button>
                              <div className="mx-3.5 h-3 border border-solid border-background-layer3"></div>
                            </li>

                            <li className="flex items-center">
                              <button
                                type="button"
                                className="text-xs text-contentColorLight flex justify-center items-center"
                              >
                                Upload
                              </button>
                              <div className="mx-3.5 h-3 border border-solid border-background-layer3"></div>
                            </li>

                            <li className="flex items-center mr-3.5">
                              <button
                                type="button"
                                onClick={downloadFile}
                                className="text-xs text-contentColorLight flex justify-center items-center"
                              >
                                Download
                              </button>
                            </li>
                          </ul>

                          <div className="flex justify-center items-center">
                            <button
                              onClick={handleMaximizeEditor}
                              type="button"
                              className="p-1 bg-background-layer1 rounded"
                            >
                              <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M10.6663 2H14.6663V6H13.333V3.33333H10.6663V2ZM1.33301 2H5.33301V3.33333H2.66634V6H1.33301V2ZM13.333 12.6667V10H14.6663V14H10.6663V12.6667H13.333ZM2.66634 12.6667H5.33301V14H1.33301V10H2.66634V12.6667Z"
                                  fill="#546CCC"
                                />
                              </svg>
                            </button>
                          </div>
                        </div>
                        <div className="my-2 border border-solid border-background-layer3"></div>
                      </div>
                      <Editor
                        height="30vh"
                        language="cpp"
                        value={customShadow}
                        onChange={handleShadowEditor}
                        theme={
                          theme === "golain" || theme === "none"
                            ? "vs"
                            : "vs-dark"
                        }
                        options={{
                          lineNumbers: "off",
                          minimap: { enabled: false },
                          scrollbar: { vertical: "hidden" },
                          overviewRulerBorder: false,
                          overviewRulerLanes: 0,
                          folding: false,
                          matchBrackets: "never",
                          theme:
                            theme === "golain" || theme === "none"
                              ? "vs"
                              : "vs-dark"
                        }}
                      />
                    </div>
                    {errorMsgs.customShadow ? (
                      <FieldError message={errorMsgs.customShadow} />
                    ) : (
                      ""
                    )}
                  </div>
                )}
                {!cameFromCreateShadowDef ? (
                  <h1 className="flex mb-5 text-sm text-contentColorLight">
                    {isCreateShadow ? "Or Select from" : "Or"} &nbsp;
                    <span
                      className="font-medium text-primary cursor-pointer"
                      onClick={() => setIsCreateShadow(!isCreateShadow)}
                    >
                      {isCreateShadow
                        ? "Predefined Shadow"
                        : "Create New Shadow"}
                    </span>
                  </h1>
                ) : null}
                {isCreateShadow && (
                  <div>
                    <Button
                      type="submit"
                      loading={createShadowDefMutation.isLoading}
                      className="px-5 py-3.5 font-medium text-center text-white transition-colors duration-200 transform rounded-md focus:outline-none bg-primary hover:bg-opacity-80"
                    >
                      Done
                    </Button>
                  </div>
                )}
              </Form>
            </Formik>
          </div>

          {!cameFromCreateShadowDef ? (
            <div
              className={`w-10/12 ${
                isCreateShadow ? "" : ""
              } flex items-center mt-12 space-x-4`}
            >
              <Button
                variant="secondary"
                onClick={() => {
                  backClick
                    ? backClick()
                    : navigate(
                        "/fleet-and-devices/projects/provisioning-policy/"
                      );
                }}
                className="w-1/2 px-8 py-3 space-x-3 font-medium text-center text-primary"
              >
                Back
              </Button>

              <Button
                loading={createFleetMutation.isLoading}
                onClick={handleClick}
                className="w-1/2 px-8 py-3.5 font-medium text-center text-white transition-colors duration-200 transform rounded-md focus:outline-none bg-primary hover:bg-opacity-80"
              >
                Next
              </Button>
            </div>
          ) : null}
        </div>

        <div className="w-7/12">
          <SuggestionPanel type="shadow" />
        </div>
      </div>
      {/* Open Options Modal Prompt */}
      <Modal
        open={modalStates.openOptionsPrompt}
        setOpen={(isOpen) =>
          setModalStates((prev) => ({ ...prev, openOptionsPrompt: isOpen }))
        }
        title="JSON Editor"
        className="w-full max-w-lg"
      >
        <div className="flex flex-col gap-4 p-6 bg-background-layer1 text-contentColor">
          <h1 className="text-lg font-bold">Set Fields Limits</h1>
          <p>
            Looks like you have{" "}
            <span className="font-mono text-orange-500">string</span> /{" "}
            <span className="font-mono text-orange-500">byte</span> /{" "}
            <span className="font-mono text-orange-500">repeated</span> fields
            in your Proto definition. Do you want to set max limits for these
            fields or deal with them yourself
            <a
              href="https://jpa.kapsi.fi/nanopb/docs/concepts.html#field-callbacks"
              target="_blank"
              rel={"noreferrer noopener"}
              className="mx-1 underline"
            >
              (like a pro)
            </a>
            in your device's code?
          </p>
          <div className="flex gap-4 justify-end">
            <Button
              variant="secondary"
              onClick={() =>
                setModalStates((prev) => ({
                  optionsModal: false,
                  openOptionsPrompt: false
                }))
              }
            >
              Cancel
            </Button>
            <Button
              variant="secondary"
              onClick={() =>
                createShadow({ shadow: customShadow, values: { shadowName } })
              }
            >
              Skip
            </Button>
            <Button
              onClick={() =>
                setModalStates((prev) => ({
                  optionsModal: true,
                  openOptionsPrompt: false
                }))
              }
            >
              Set Limits
            </Button>
          </div>
        </div>
      </Modal>

      {/*  Options Modal  */}
      <Modal
        open={modalStates.optionsModal}
        setOpen={(isOpen) =>
          setModalStates((prev) => ({ ...prev, optionsModal: isOpen }))
        }
        title="JSON Editor"
        className="w-full max-w-2xl"
      >
        <div className="flex flex-col gap-4 p-6 bg-background-layer1 text-contentColor">
          <h1 className="text-lg font-bold">Set Fields Limits</h1>
          <ProtoLimitsForm
            onSubmit={handleSetLimitsSubmit}
            fieldInfos={fieldInfos}
          />
        </div>
      </Modal>
    </>
  );
};

export default ShadowPolicy;
