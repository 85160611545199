import { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import ezpedalLogo from "../../../assets/images/Elansol.png";
import clientLogo from "../../../assets/images/golain_text_logo.svg";
import {
  useAuthStore,
  useDashboardStore,
  useFleetAndDevicesStore
} from "@store/index";
import { Close, Device, FourSquareGrid, TriangularWave } from "../icons";
import DefinitionsIcon from "../icons/definitions.icon";
import {
  ChevronDownIcon,
  CircleStackIcon,
  CloudArrowUpIcon,
  CodeBracketSquareIcon,
  DocumentCheckIcon,
  DocumentDuplicateIcon,
  FlagIcon,
  Square3Stack3DIcon
} from "@heroicons/react/24/outline";
import useThemeStore from "@store/theme.store";
import ELEMENT_IDS from "../walkthroughs/walkthrough-elements-ids.util";

interface ISidebarInterface {
  sidebarOpen: boolean;
  setSidebarOpen: (x: boolean) => void;
}

const Sidebar: React.FC<ISidebarInterface> = ({
  sidebarOpen,
  setSidebarOpen
}) => {
  const [projects] = useFleetAndDevicesStore((state) => [state.projects]);
  const [name] = useAuthStore((state) => [
    state.user.email ?? state.user.preferred_username ?? state.user.name ?? ""
  ]);

  const [openedItems, setOpenedItems] = useState({
    ota: false,
    devices: false,
    logs: false,
    definitions: false,
    blueprints: false
  });

  const [theme, setTheme] = useThemeStore((state) => [
    state.theme,
    state.setTheme
  ]);

  const activeDashboard = useDashboardStore((store) => store.activeDashboard);

  const handleNavItemClick = (item = null) => {
    setSidebarOpen(false);
    if (!item) return;
    setOpenedItems({
      ...{
        ota: false,
        devices: false,
        logs: false,
        definitions: false,
        blueprints: false
      },
      [item]: true
    });
  };

  return (
    <div
      className={`text-contentColor bg-background 
      ${projects?.length > 0 ? "lg:flex" : "hidden"} font-dm-sans`}
    >
      <div
        onClick={() => setSidebarOpen(false)}
        style={{ zIndex: 100 }}
        className={
          "fixed inset-0 transition-opacity bg-black opacity-50 " +
          (sidebarOpen ? "block" : "hidden")
        }
      ></div>

      <div
        style={{ zIndex: 100 }}
        className={
          "text-contentColor bg-background text-xs fixed inset-y-0 left-0 flex flex-col h-screen overflow-y-auto transition duration-200 transform border-r border-background-layer2 w-80 lg:w-72 2xl:w-64  " +
          (sidebarOpen
            ? "translate-x-0 ease-in"
            : "-translate-x-full ease-out")
        }
      >
        <div className="flex items-center justify-between px-6 py-12">
          <div className="w-full flex flex-col items-center space-x-4">
            <div className="p-2 text-base lg:p-2.5 rounded-xl">
              ELANSOL TECHNOLOGIES
              <img
                className="max-w-[10rem]"
                src={ezpedalLogo}
                alt="clientLogo"
              />
            </div>

            <div className="w-full">
              <p className="text-xs text-gray-500 mb-2">Powered by</p>
              <div className="w-full flex justify-center">
                <img
                  src={clientLogo}
                  alt="clientLogo"
                  className="max-w-[8rem]"
                />
              </div>
            </div>
          </div>

          <button
            onClick={() => setSidebarOpen(false)}
            className="text-gray-100 focus:text-primary focus:outline-none"
          >
            <Close />
          </button>
        </div>

        <nav className="flex-1 px-3 space-y-3 text-xs">
          <SideBarItem to="/home" onClick={() => handleNavItemClick()}>
            {({ isActive }) => (
              <>
                <TriangularWave isActive={isActive} />

                <span>Home</span>
              </>
            )}
          </SideBarItem>

          <SideBarItem
            id={ELEMENT_IDS.SIDEBAR_DASHBOARD}
            to={`/dashboard/${activeDashboard?.id ?? ""}`}
            onClick={() => handleNavItemClick()}
            className={({ isActive }) =>
              `flex items-center px-4 py-3 space-x-3 transition-colors duration-200 transform rounded-lg bg-opacity-40 ${
                isActive ? "text-primary-600 bg-gray-200" : "text-[#00000080]"
              }`
            }
          >
            {({ isActive }) => (
              <>
                <FourSquareGrid isActive={isActive} />

                <span>Dashboard</span>
              </>
            )}
          </SideBarItem>

          <div
            id={ELEMENT_IDS.SIDEBAR_DEVICES}
            className={`flex text-contentColor items-center px-4 pt-3 ${
              openedItems.devices ? "pb-3" : "pb-0"
            } rounded-lg ${openedItems.devices ? "bg-background-layer1" : ""}`}
          >
            <div className="w-full">
              <div
                className={`w-full cursor-pointer flex items-center space-x-3`}
                onClick={() =>
                  setOpenedItems({
                    ...openedItems,
                    devices: !openedItems.devices
                  })
                }
              >
                <Device className="opacity-50" />

                <div className="w-full flex justify-between items-center">
                  <span className={`capitalize`}>Devices</span>

                  {
                    <ChevronDownIcon
                      className={`w-4 h-4 text-contentColor transition-transform ${
                        openedItems.devices ? "rotate-180" : "rotate-0"
                      }`}
                    />
                  }
                </div>
              </div>

              <div
                className={`pl-[18px] inner overflow-hidden ${
                  openedItems.devices
                    ? "max-h-40 transition-all duration-700 ease-in"
                    : "max-h-0 transition-all duration-700 ease-out"
                } list-none`}
              >
                <SideBarItem
                  to="/fleet-and-devices/projects"
                  onClick={() => handleNavItemClick("devices")}
                  className={({ isActive }) =>
                    `flex items-center px-4 py-3 space-x-3  transition-colors duration-200 transform rounded-lg bg-opacity-40 ${
                      isActive ? "text-primary" : "text-contentColor"
                    }`
                  }
                >
                  {({ isActive }) => (
                    <>
                      <span>Fleet & Devices</span>
                      {isActive && (
                        <svg
                          width="7"
                          height="12"
                          viewBox="0 0 7 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1 10.5L5.5 6L1 1.5"
                            stroke="#546CCC"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      )}
                    </>
                  )}
                </SideBarItem>

                <SideBarItem
                  to="/fleet-and-devices/certificates"
                  onClick={() => handleNavItemClick("devices")}
                  className={({ isActive }) =>
                    `flex items-center px-4 py-3 space-x-3  transition-colors duration-200 transform rounded-lg bg-opacity-40 ${
                      isActive ? "text-primary" : "text-contentColor"
                    }`
                  }
                >
                  {({ isActive }) => (
                    <>
                      <span>Certificates</span>
                      {isActive && (
                        <svg
                          width="7"
                          height="12"
                          viewBox="0 0 7 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1 10.5L5.5 6L1 1.5"
                            stroke="#546CCC"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      )}
                    </>
                  )}
                </SideBarItem>
              </div>
            </div>
          </div>

          <div
            className={`flex text-contentColor items-center px-4 pt-3 ${
              openedItems.blueprints ? "pb-3" : "pb-0"
            } rounded-lg ${
              openedItems.blueprints ? "bg-background-layer1" : ""
            }`}
          >
            <div className="w-full">
              <div
                className={`w-full cursor-pointer flex items-center space-x-3`}
                onClick={() =>
                  setOpenedItems({
                    ...openedItems,
                    blueprints: !openedItems.blueprints
                  })
                }
              >
                <DocumentDuplicateIcon width={18} className="opacity-50" />

                <div className="w-full flex justify-between items-center">
                  <span className={`capitalize`}>blueprints</span>

                  {
                    <ChevronDownIcon
                      className={`w-4 h-4 text-contentColor transition-transform ${
                        openedItems.blueprints ? "rotate-180" : "rotate-0"
                      }`}
                    />
                  }
                </div>
              </div>

              <div
                className={`pl-[18px] inner overflow-hidden ${
                  openedItems.blueprints
                    ? "max-h-40 transition-all duration-700 ease-in"
                    : "max-h-0 transition-all duration-700 ease-out"
                } list-none`}
              >
                <SideBarItem
                  to="/blueprints/panels"
                  onClick={() => handleNavItemClick("blueprints")}
                  className={({ isActive }) =>
                    `flex items-center px-4 py-3 space-x-3  transition-colors duration-200 transform rounded-lg bg-opacity-40 ${
                      isActive ? "text-primary" : "text-contentColor"
                    }`
                  }
                >
                  {({ isActive }) => (
                    <>
                      <span>Dashboard Panels</span>
                      {isActive && (
                        <svg
                          width="7"
                          height="12"
                          viewBox="0 0 7 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1 10.5L5.5 6L1 1.5"
                            stroke="#546CCC"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      )}
                    </>
                  )}
                </SideBarItem>

                {/* <SideBarItem
                  to="/blueprints/dashboards"
                  onClick={() => handleNavItemClick("blueprints")}
                  className={({ isActive }) =>
                    `flex items-center px-4 py-3 space-x-3  transition-colors duration-200 transform rounded-lg bg-opacity-40 ${
                      isActive ? "text-primary" : "text-contentColor"
                    }`
                  }
                >
                  {({ isActive }) => (
                    <>
                      <span>Dashboards</span>
                      {isActive && (
                        <svg
                          width="7"
                          height="12"
                          viewBox="0 0 7 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1 10.5L5.5 6L1 1.5"
                            stroke="#546CCC"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      )}
                    </>
                  )}
                </SideBarItem> */}

                <SideBarItem
                  to="/blueprints/devices"
                  onClick={() => handleNavItemClick("blueprints")}
                  className={({ isActive }) =>
                    `flex items-center px-4 py-3 space-x-3  transition-colors duration-200 transform rounded-lg bg-opacity-40 ${
                      isActive ? "text-primary" : "text-contentColor"
                    }`
                  }
                >
                  {({ isActive }) => (
                    <>
                      <span>Devices</span>
                      {isActive && (
                        <svg
                          width="7"
                          height="12"
                          viewBox="0 0 7 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1 10.5L5.5 6L1 1.5"
                            stroke="#546CCC"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      )}
                    </>
                  )}
                </SideBarItem>
              </div>
            </div>
          </div>

          <div
            className={`flex text-contentColor items-center px-4 pt-3 ${
              openedItems.definitions ? "pb-3" : "pb-0"
            } rounded-lg ${
              openedItems.definitions ? "bg-background-layer1" : ""
            }`}
          >
            <div className="w-full">
              <div
                className={`w-full cursor-pointer flex items-center space-x-3`}
                onClick={() =>
                  setOpenedItems({
                    ...openedItems,
                    definitions: !openedItems.definitions
                  })
                }
              >
                <DefinitionsIcon isActive={openedItems.definitions} />

                <div className="w-full flex justify-between items-center">
                  <span className={`capitalize`}>Definitions</span>

                  {
                    <ChevronDownIcon
                      className={`w-4 h-4 text-contentColor transition-transform ${
                        openedItems.definitions ? "rotate-180" : "rotate-0"
                      }`}
                    />
                  }
                </div>
              </div>

              <div
                className={`pl-[18px] inner overflow-hidden ${
                  openedItems.definitions
                    ? "max-h-40 transition-all duration-700 ease-in"
                    : "max-h-0 transition-all duration-700 ease-out"
                } list-none`}
              >
                <SideBarItem
                  to="definitions/shadows"
                  onClick={() => handleNavItemClick("definitions")}
                >
                  {({ isActive }) => (
                    <>
                      <svg
                        className={
                          isActive
                            ? "fill-primary"
                            : "fill-contentColor opacity-50"
                        }
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M4.66683 4.66666V1.99999C4.66683 1.82318 4.73707 1.65361 4.86209 1.52859C4.98712 1.40357 5.15669 1.33333 5.3335 1.33333H14.0002C14.177 1.33333 14.3465 1.40357 14.4716 1.52859C14.5966 1.65361 14.6668 1.82318 14.6668 1.99999V10.6667C14.6668 10.8435 14.5966 11.013 14.4716 11.1381C14.3465 11.2631 14.177 11.3333 14.0002 11.3333H11.3335V13.9953C11.3335 14.366 11.0342 14.6667 10.6622 14.6667H2.00483C1.91664 14.6667 1.82931 14.6494 1.74782 14.6157C1.66633 14.582 1.59229 14.5326 1.52993 14.4702C1.46757 14.4079 1.41813 14.3338 1.38442 14.2523C1.35071 14.1708 1.33341 14.0835 1.3335 13.9953L1.3355 5.33799C1.3355 4.96733 1.63483 4.66666 2.00683 4.66666H4.66683ZM6.00016 4.66666H10.6622C11.0328 4.66666 11.3335 4.96599 11.3335 5.33799V9.99999H13.3335V2.66666H6.00016V4.66666ZM10.0002 5.99999H2.66883L2.66683 13.3333H10.0002V5.99999ZM5.66883 12L3.3115 9.64266L4.25416 8.69999L5.66883 10.1147L8.49683 7.28599L9.4395 8.22866L5.66883 12Z" />
                      </svg>

                      <span
                        className={`capitalize ${
                          isActive ? "text-primary" : ""
                        }`}
                      >
                        Shadows
                      </span>

                      {isActive && (
                        <svg
                          width="7"
                          height="12"
                          viewBox="0 0 7 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1 10.5L5.5 6L1 1.5"
                            className="stroke-primary"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      )}
                    </>
                  )}
                </SideBarItem>

                <SideBarItem
                  to="definitions/data-points"
                  onClick={() => handleNavItemClick("definitions")}
                >
                  {({ isActive }) => (
                    <>
                      <svg
                        className={
                          isActive
                            ? "fill-primary"
                            : "fill-contentColor opacity-50"
                        }
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_1768_22317)">
                          <path d="M7.99993 8.39066L10.8286 11.2187L9.88526 12.162L8.6666 10.9433V14.6667H7.33326V10.942L6.1146 12.162L5.17126 11.2187L7.99993 8.39066ZM7.99993 1.33333C9.14458 1.33338 10.2493 1.75407 11.1041 2.51541C11.9588 3.27674 12.504 4.32564 12.6359 5.46266C13.4654 5.68887 14.1891 6.1994 14.6804 6.90501C15.1717 7.61063 15.3994 8.46646 15.3237 9.32292C15.248 10.1794 14.8737 10.982 14.2663 11.5905C13.6589 12.1991 12.8569 12.5748 12.0006 12.652V11.3093C12.3074 11.2655 12.6024 11.161 12.8683 11.002C13.1343 10.8429 13.3659 10.6325 13.5496 10.3829C13.7334 10.1334 13.8656 9.84974 13.9385 9.54855C14.0114 9.24736 14.0236 8.93466 13.9744 8.62871C13.9252 8.32275 13.8155 8.02966 13.6517 7.76656C13.488 7.50346 13.2735 7.27561 13.0207 7.09631C12.768 6.91701 12.482 6.78985 12.1796 6.72227C11.8772 6.65468 11.5643 6.64802 11.2593 6.70266C11.3637 6.21658 11.358 5.71331 11.2427 5.2297C11.1275 4.74608 10.9055 4.29438 10.593 3.90768C10.2805 3.52098 9.88553 3.20907 9.43691 2.99479C8.98829 2.78052 8.49743 2.66931 8.00026 2.66931C7.5031 2.66931 7.01223 2.78052 6.56361 2.99479C6.115 3.20907 5.71999 3.52098 5.40753 3.90768C5.09507 4.29438 4.87307 4.74608 4.75778 5.2297C4.6425 5.71331 4.63686 6.21658 4.74126 6.70266C4.13303 6.58844 3.50434 6.72052 2.99349 7.06984C2.48264 7.41915 2.13148 7.9571 2.01726 8.56533C1.90304 9.17356 2.03512 9.80225 2.38444 10.3131C2.73376 10.8239 3.2717 11.1751 3.87993 11.2893L3.99993 11.3093V12.652C3.14356 12.5749 2.34153 12.1993 1.73402 11.5908C1.1265 10.9823 0.752147 10.1797 0.676379 9.32321C0.60061 8.46672 0.828249 7.61085 1.31951 6.90518C1.81077 6.1995 2.5344 5.68892 3.36393 5.46266C3.49573 4.32558 4.04087 3.2766 4.89565 2.51524C5.75043 1.75387 6.85523 1.33324 7.99993 1.33333Z" />
                        </g>
                        <defs>
                          <clipPath id="clip0_1768_22317">
                            <rect width="16" height="16" />
                          </clipPath>
                        </defs>
                      </svg>

                      <span
                        className={`capitalize ${
                          isActive ? "text-primary" : ""
                        }`}
                      >
                        Data Points
                      </span>

                      {isActive && (
                        <svg
                          width="7"
                          height="12"
                          viewBox="0 0 7 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1 10.5L5.5 6L1 1.5"
                            stroke="#546CCC"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      )}
                    </>
                  )}
                </SideBarItem>

                <SideBarItem
                  to="definitions/contexts"
                  onClick={() => handleNavItemClick("definitions")}
                >
                  {({ isActive }) => (
                    <>
                      <CircleStackIcon
                        className={
                          isActive
                            ? "stroke-primary"
                            : "stroke-contentColor opacity-50"
                        }
                        width={16}
                      />

                      <span
                        className={`capitalize ${
                          isActive ? "text-primary" : ""
                        }`}
                      >
                        Contexts
                      </span>

                      {isActive && (
                        <svg
                          width="7"
                          height="12"
                          viewBox="0 0 7 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1 10.5L5.5 6L1 1.5"
                            stroke="#546CCC"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      )}
                    </>
                  )}
                </SideBarItem>
              </div>
            </div>
          </div>

          <div
            id={ELEMENT_IDS.SIDEBAR_OTA}
            className={`flex items-center px-4 pt-3 ${
              openedItems.ota ? "pb-3" : "pb-0"
            } rounded-lg ${openedItems.ota ? "bg-background-layer1" : ""}`}
          >
            <div className="w-full">
              <div
                className={`w-full cursor-pointer flex items-center space-x-3`}
                onClick={() =>
                  setOpenedItems({ ...openedItems, ota: !openedItems.ota })
                }
              >
                <Square3Stack3DIcon
                  width={18}
                  className={`opacity-50 stroke-contentColor`}
                />

                <div className="w-full flex justify-between items-center">
                  <span
                    className={`capitalize ${
                      openedItems.ota ? "text-contentColor" : ""
                    }`}
                  >
                    OTA Updates
                  </span>

                  {
                    <ChevronDownIcon
                      className={`w-4 h-4 text-contentColor transition-transform ${
                        openedItems.ota ? "rotate-180" : "rotate-0"
                      }`}
                    />
                  }
                </div>
              </div>

              <div
                className={`pl-[18px] inner overflow-hidden ${
                  openedItems.ota
                    ? "max-h-40 transition-all duration-700 ease-in"
                    : "max-h-0 transition-all duration-700 ease-out"
                } list-none`}
              >
                <SideBarItem
                  to="ota-updates/projects/release"
                  onClick={() => handleNavItemClick("ota")}
                  // className="mt-4 flex items-center space-x-3 mb-3.5"
                >
                  {({ isActive }) => (
                    <>
                      <DocumentCheckIcon width={14} className={`opacity-50`} />

                      <span>Release</span>

                      {isActive && (
                        <svg
                          width="7"
                          height="12"
                          viewBox="0 0 7 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1 10.5L5.5 6L1 1.5"
                            stroke="#546CCC"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      )}
                    </>
                  )}
                </SideBarItem>

                <SideBarItem
                  to="ota-updates/projects/deployment"
                  onClick={() => handleNavItemClick("ota")}
                  // className="flex items-center space-x-3"
                >
                  {({ isActive }) => (
                    <>
                      <CloudArrowUpIcon width={14} className="opacity-50" />

                      <span
                        className={`capitalize ${
                          isActive ? "text-primary" : ""
                        }`}
                      >
                        Deployment
                      </span>

                      {isActive && (
                        <svg
                          width="7"
                          height="12"
                          viewBox="0 0 7 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1 10.5L5.5 6L1 1.5"
                            stroke="#546CCC"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      )}
                    </>
                  )}
                </SideBarItem>
              </div>
            </div>
          </div>

          <SideBarItem
            to="/rule-engine"
            onClick={() => handleNavItemClick()}
            className={({ isActive }) =>
              `flex items-center px-4 py-3 space-x-3  transition-colors duration-200 transform rounded-lg bg-opacity-40 ${
                isActive ? "text-primary-600 bg-gray-200 dark:bg-gray-800" : ""
              }`
            }
          >
            {({ isActive }) => (
              <>
                <CodeBracketSquareIcon className={`opacity-50`} width={18} />
                <span>Rule Engine</span>
              </>
            )}
          </SideBarItem>

          <SideBarItem
            id={ELEMENT_IDS.SIDEBAR_LOGS}
            to="/logs-and-activities/projects"
            onClick={() => handleNavItemClick()}
          >
            {({ isActive }) => (
              <>
                <FlagIcon
                  width={18}
                  className={` ${
                    isActive
                      ? "stroke-primaryLight"
                      : "opacity-50 stroke-contentColor"
                  }`}
                />

                <span>Logs & Activities</span>
              </>
            )}
          </SideBarItem>

          <SideBarItem
            id={ELEMENT_IDS.SIDEBAR_CONSUMER}
            to="/user-authentication"
            onClick={() => handleNavItemClick()}
            className={({ isActive }) =>
              `flex items-center px-4 py-3 space-x-3  transition-colors duration-200 transform rounded-lg bg-opacity-40 ${
                isActive ? "text-primary-600 bg-gray-200 dark:bg-gray-800" : ""
              }`
            }
          >
            {({ isActive }) => (
              <>
                <svg
                  className={` ${
                    isActive
                      ? "stroke-primaryLight"
                      : "opacity-50 stroke-contentColor"
                  }`}
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9 8.25C10.0355 8.25 10.875 7.41053 10.875 6.375C10.875 5.33947 10.0355 4.5 9 4.5C7.96447 4.5 7.125 5.33947 7.125 6.375C7.125 7.41053 7.96447 8.25 9 8.25Z"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M6 11V10.0983C6 9.49192 6.80533 9 7.7993 9H10.2007C11.1941 9 12 9.49159 12 10.0983V11"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M8.99663 16.5C15.7467 13 15.8007 6.22222 15.7467 2.55556C11.8079 3.44444 9.05387 1.5 9.05387 1.5C9.05387 1.5 6.13782 3.61111 2.24976 2.55556C2.19576 6.22222 2.24983 13 8.94587 16.5H8.99663Z"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>

                <span>Consumer Auth</span>
              </>
            )}
          </SideBarItem>
        </nav>

        <hr className="my-6 border-background-layer2" />

        <div className="flex gap-2 px-6 items-center text-base">
          <span>Theme: </span>
          <button
            onClick={() => setTheme("golain")}
            className="w-5 h-5 rounded-full bg-gray-200 border-2 border-gray-200"
          ></button>
          <button
            onClick={() => setTheme("dark-default")}
            className="w-5 h-5 rounded-full bg-gray-800 border-2 border-gray-200"
          ></button>
        </div>
        <div className="px-6 pt-4 pb-10">
          <div className="flex items-center mt-4 space-x-3 font-bold text-contentColor hover:underline dark:text-gray-100">
            <h1 className="w-full whitespace-nowrap overflow-hidden text-ellipsis">
              {name}
            </h1>
          </div>

          <Link
            to="/logout"
            className="flex items-center mt-4 space-x-3 font-bold hover:underline"
          >
            <span>Log Out</span>

            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-5 h-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
              />
            </svg>
          </Link>
        </div>
      </div>
    </div>
  );
};

const SideBarItem = ({ children, ...props }) => {
  return (
    <NavLink
      {...props}
      to={props.to}
      className={({ isActive }) =>
        `flex items-center px-4 pt-3 space-x-3 transition-colors duration-200 transform rounded-lg ${
          isActive ? "text-primary" : ""
        }`
      }
    >
      {({ isActive }) => <>{children({ isActive })}</>}
    </NavLink>
  );
};

export default Sidebar;
