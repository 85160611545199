import ShowLoading from "@/app/shared/components/loading.component";
import { useGetUserGroupsDetails } from "@/app/shared/hooks/get/user-groups-details";
import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import UserGroupDetailsTab from "./user-group-details-tabs/details.component";
import UserGroupDetailsTabs, {
  UserGroupTabs
} from "./user-group-details-tabs/user-group-tabs.component";
import UserGroupCustomAuth from "./user-group-details-tabs/user-group-custom-auth.component";

const tab_map = {
  details: UserGroupTabs.DETAILS
};

const UserGroupsProvider: React.FC = () => {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab");

  const groupId = params["groupId"];

  const {
    data: userGroup,
    isLoading,
    isFetching,
    isError
  } = useGetUserGroupsDetails(groupId);

  /* State Variables */
  const [activeTab, setActiveTab] = useState(
    tab ? tab_map[tab] : UserGroupTabs.DETAILS
  );

  useEffect(() => {
    if (isError) {
      toast.warning("User Group not found!");
    }
  }, [isError]);

  if (isLoading || isFetching) {
    return <ShowLoading />;
  }

  const tabs = {
    [UserGroupTabs.DETAILS]: <UserGroupDetailsTab userGroup={userGroup} />,
    [UserGroupTabs.CUSTOM_AUTH]: <UserGroupCustomAuth userGroup={userGroup} />
  };

  return (
    <main className="flex-1 flex flex-col gap-4 bg-background text-contentColor rounded-md">
      <UserGroupDetailsTabs
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      {userGroup ? tabs[activeTab] : null}
    </main>
  );
};

export default UserGroupsProvider;
