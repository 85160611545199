import { useMemo, useState } from "react";
import SettingsGenericTable from "../components/settings-generic-table.component";
import dateService from "@services/date.service";
import { Button } from "@tremor/react";
import {
  PencilSquareIcon,
  TrashIcon,
  UserPlusIcon
} from "@heroicons/react/24/outline";
import { useConfirmDelete } from "@app/shared/hooks/use-confirm-delete.hooks";
import { IUserGroup } from "@/interfaces/user-groups.interface";
import { useGetUserGroups } from "@/app/shared/hooks/get/user-groups";
import { AddOverlayLayout, Unauthorized } from "@/app/shared/components";
import InviteForm, {
  IInviteFormState
} from "../components/invite-form.component";
import { FormikHelpers } from "formik";
import NoData from "@/app/shared/components/no-data.component";
import {
  ICreateUserGroupPayload,
  useCreateUserGroup
} from "@/app/shared/hooks/post/create-user-group";
import { toast } from "react-toastify";
import ShowLoading from "@/app/shared/components/loading.component";
import { useUpdateUserGroup } from "@/app/shared/hooks/patch/update-user-group";
import { useDeleteUserGroup } from "@/app/shared/hooks/delete/delete-user-group";
import EditUserForm from "../components/edit-users-form.component";
import { Link } from "react-router-dom";
import ShowError from "@/app/shared/components/error.component";

function UserGroups() {
  const [createOverlayOpen, setCreateOverlayOpen] = useState(false);
  const [userListOverlayOpen, setUserListOverlayOpen] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState<IUserGroup>(null);

  const { data: userGroups, isLoading, isError, error } = useGetUserGroups();

  const createUserGroupMutation = useCreateUserGroup();
  const updateUserGroupMutation = useUpdateUserGroup(selectedGroup?.id);
  const deleteUserGroupMutation = useDeleteUserGroup();

  const { openConfirmDeleteModal } = useConfirmDelete();

  const renderStatus = (status: string) => {
    const statusUI = {
      admin: (
        <span className="flex">
          <span className="bg-[#25a96a36] py-2 px-5 rounded text-[#25A96A] font-medium text-xs mr-6">
            ADMIN
          </span>
        </span>
      ),
      viewer: (
        <span className="flex">
          <span className="bg-[#3659997d] py-2 px-[17px] rounded text-[#4281ff] font-medium text-xs mr-6">
            VIEWER
          </span>
        </span>
      ),
      member: (
        <span className="flex">
          <span className="bg-[#166ff629] py-2 px-[17px] rounded text-[#1366E5] font-medium text-xs mr-6">
            MEMBER
          </span>
        </span>
      )
    };

    return statusUI[status.toLowerCase()];
  };

  const handleSubmit = async (
    values: IInviteFormState,
    formikHelpers: FormikHelpers<IInviteFormState>
  ) => {
    const payload: ICreateUserGroupPayload = {
      name: values.name,
      permissions: values.resource_access.map((perm) => ({
        resource: perm.resource.value,
        actions: perm.actions.map((action) => action.value),
        resource_type: perm.resource_type.value
      })),
      org_access: values.org_access
    };

    if (selectedGroup) {
      updateUserGroupMutation.mutate(payload, {
        onSuccess: () => {
          toast.success("Updated User Group Successfully!");
          setCreateOverlayOpen(false);

          formikHelpers.resetForm();
        }
      });
    } else {
      createUserGroupMutation.mutate(payload, {
        onSuccess: () => {
          toast.success("Created User Group Successfully!");
          setCreateOverlayOpen(false);

          formikHelpers.resetForm();
        }
      });
    }
  };

  const groups = useMemo(() => {
    if (isError) {
      return [];
    }
    if (userGroups?.length <= 0) return [];
    return userGroups?.map((group) => {
      return {
        name: (
          <Link to={group?.id} className="text-primary hover:underline">
            {group?.group_name}
          </Link>
        ),
        "org access": renderStatus(group?.org_access),
        "updated at": dateService.convertUTCToLocalDate(group?.updated_at),
        "Edit Permissions": (
          <Button
            variant="secondary"
            icon={PencilSquareIcon}
            size="xs"
            onClick={() => {
              setSelectedGroup(group);
              setCreateOverlayOpen(true);
            }}
          >
            Edit Permission
          </Button>
        ),
        "Edit Users": (
          <Button
            variant="secondary"
            icon={PencilSquareIcon}
            size="xs"
            onClick={() => {
              setSelectedGroup(group);
              setUserListOverlayOpen(true);
            }}
          >
            Edit Users
          </Button>
        ),
        Delete: (
          <Button
            variant="light"
            icon={TrashIcon}
            color="red"
            onClick={() => {
              openConfirmDeleteModal(() => {
                deleteUserGroupMutation.mutate(group?.id, {
                  onSuccess: () => {
                    toast.success("User Group deleted successfully");
                  }
                });
              }, "Are you sure you want to delete this user group?");
            }}
          />
        )
      };
    });
  }, [isError, userGroups]);

  if (isLoading) {
    return <ShowLoading />;
  }

  if (isError) {
    const msg = (error as any).message;
    if (msg === "Missing sufficient permissions") {
      return <Unauthorized />;
    } else {
      return <ShowError />;
    }
  }

  return (
    <>
      <AddOverlayLayout
        title={selectedGroup ? "Edit User Group" : "Create User Group"}
        overlayOpen={createOverlayOpen}
        setOverlayOpen={setCreateOverlayOpen}
      >
        <div className="flex flex-col h-full items-center">
          <InviteForm
            renderAs="group"
            showAddToGroup={false}
            hideOrgPerms
            showNameField
            overlayOpen={createOverlayOpen}
            selectedGroup={selectedGroup}
            handleSubmit={handleSubmit}
          />
        </div>
      </AddOverlayLayout>
      <AddOverlayLayout
        title={"Edit Users"}
        overlayOpen={userListOverlayOpen}
        setOverlayOpen={setUserListOverlayOpen}
      >
        <div className="flex flex-col h-full items-center">
          <EditUserForm
            overlayOpen={userListOverlayOpen}
            selectedGroup={selectedGroup}
          />
        </div>
      </AddOverlayLayout>
      <div>
        <div className="flex flex-col mt-4 w-full max-h-[70vh] bg-background text-contentColor pb-6">
          <div className="flex justify-end">
            <Button
              icon={UserPlusIcon}
              onClick={() => {
                setSelectedGroup(null);
                setCreateOverlayOpen(true);
              }}
              className="uppercase mb-4 !text-white"
            >
              {"Create User Group"}
            </Button>
          </div>
          <div className="overflow-auto">
            {!!groups?.length ? (
              <SettingsGenericTable
                column={Object.keys(groups[0])}
                row={groups}
              />
            ) : (
              <NoData msg="No User Groups" />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default UserGroups;
