import { useCallback, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import useFleetAndDevicesStore from "@store/fleet-and-devices/fleet-and-devices.store";
import dateService from "@services/date.service";
import { useConfirmDelete } from "../../shared/hooks/use-confirm-delete.hooks";
import ShowLoading from "@app/shared/components/loading.component";
import { useDeleteDeviceMutation } from "@app/shared/hooks/delete/delete-device";
import { toast } from "react-toastify";
import Pagination from "@app/shared/components/pagination.component";
import {
  useReactTable,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getPaginationRowModel,
  getSortedRowModel,
  ColumnDef,
  flexRender
} from "@tanstack/react-table";
import {
  ArrowDownIcon,
  ArrowUpIcon,
  ArrowsUpDownIcon,
  PlusIcon,
  TrashIcon
} from "@heroicons/react/24/outline";
import { Button } from "@tremor/react";
import { useGetFleetDeviceBlueprints } from "@/app/shared/hooks/get/device-blueprints";
import { IFleetDeviceBlueprint } from "@/interfaces/device-blueprint.interface";
import NoData from "@/app/shared/components/no-data.component";

function TemplatesAll() {
  const [selectedFleet] = useFleetAndDevicesStore((state) => [
    state.selectedFleet
  ]);

  const navigate = useNavigate();

  const { openConfirmDeleteModal } = useConfirmDelete();

  const {
    data: fleetTemplates,
    isFetching,
    isLoading,
    status
  } = useGetFleetDeviceBlueprints(selectedFleet.id);

  const deleteDeviceMutation = useDeleteDeviceMutation(selectedFleet.id);

  const deleteDevice = useCallback(
    async (deviceId: string) => {
      deleteDeviceMutation.mutate(deviceId, {
        onSuccess: (ok) => {
          if (ok) {
            toast.success("Deleted device successfully!");
          }
        }
      });
    },
    [deleteDeviceMutation]
  );

  const columns = useMemo<ColumnDef<IFleetDeviceBlueprint, any>[]>(
    () => [
      {
        id: "template_name",
        accessorKey: "template_name",
        header: "Blueprint Name",
        cell: ({ getValue, row }) => {
          return (
            <Link
              to={{
                pathname: `/blueprints/devices/${row.original.id}`
              }}
            >
              {getValue()}
            </Link>
          );
        },
        size: 30
      },
      {
        id: "created_at",
        accessorKey: "created_at",
        header: "Created At",
        sortingFn: (a, b, colId) =>
          dateService
            .convertDateStrToMoment(a.getValue(colId))
            .diff(dateService.convertDateStrToMoment(b.getValue(colId))),
        cell: ({ getValue }) => {
          return (
            <div className="text-contentColorLight">
              {dateService.convertUTCToLocalDate(getValue())}
            </div>
          );
        },
        size: 40
      },
      {
        id: "delete",
        accessorKey: null,
        header: "Delete",
        enableSorting: false,
        cell: ({ getValue, row }) => {
          return (
            <Button
              icon={TrashIcon}
              variant="light"
              color="red"
              onClick={() =>
                openConfirmDeleteModal(
                  () => deleteDevice(row.original.id),
                  "Are you sure you want to delete this device?"
                )
              }
              type="button"
              aria-label="delete device"
            />
          );
        },
        size: 40
      }
    ],
    [deleteDevice, openConfirmDeleteModal]
  );

  const table = useReactTable({
    data: fleetTemplates || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues()
  });

  const onCreateTemplateClick = () => {
    navigate("create");
  };

  if (
    isFetching ||
    isLoading ||
    fleetTemplates === undefined ||
    status === "loading"
  ) {
    return <ShowLoading />;
  }

  return (
    <>
      <main className="flex-1 px-4 py-8 pt-5 space-y-4 overflow-y-auto lg:px-8 sm:px-6">
        <div className="flex justify-between">
          <h1 className="text-2xl font-medium text-contentColor">
            Device Blueprints
          </h1>
          <Button onClick={onCreateTemplateClick} icon={PlusIcon}>
            Create
          </Button>
        </div>
        <hr className="border-background-layer3" />
        <div className="flex flex-col mt-12">
          <div className="-my-2 overflow-x-auto xl:-mx-8 px-4 lg:px-8">
            <div className="inline-block min-w-full py-2 align-middle">
              <div className="overflow-hidden">
                {fleetTemplates?.length ? (
                  <>
                    <table className="w-full h-full my-6 ">
                      <thead className="">
                        {table.getHeaderGroups().map((headerGroup) => (
                          <tr key={headerGroup.id}>
                            {headerGroup.headers.map((header) => {
                              return (
                                <th
                                  key={header.id}
                                  colSpan={header.colSpan}
                                  style={{
                                    width: header.column.getSize()
                                  }}
                                  className="px-2 py-2 text-xs text-center uppercase text-contentColorLight font-normal"
                                >
                                  {header.isPlaceholder ? null : (
                                    <>
                                      <div
                                        {...{
                                          className: header.column.getCanSort()
                                            ? "cursor-pointer select-none flex items-center justify-center gap-1"
                                            : "",
                                          onClick:
                                            header.column.getToggleSortingHandler()
                                        }}
                                      >
                                        {flexRender(
                                          header.column.columnDef.header,
                                          header.getContext()
                                        )}
                                        {{
                                          asc: <ArrowUpIcon width={10} />,
                                          desc: <ArrowDownIcon width={10} />
                                        }[
                                          header.column.getIsSorted() as string
                                        ] ??
                                          (header.column.getCanSort() ? (
                                            <ArrowsUpDownIcon width={10} />
                                          ) : null)}
                                      </div>
                                      {header.column.getCanFilter() ? (
                                        <div>{""}</div>
                                      ) : null}
                                    </>
                                  )}
                                </th>
                              );
                            })}
                          </tr>
                        ))}
                      </thead>
                      <tbody className="rounded-md">
                        {table.getRowModel().rows.map((row, ind) => {
                          return (
                            <tr
                              key={row.id}
                              className={`bg-background ${
                                ind === 0
                                  ? "!rounded-t-md"
                                  : ind === table.getTotalSize() - 1
                                  ? "!rounded-b-md"
                                  : ""
                              }`}
                            >
                              {row.getVisibleCells().map((cell, cellInd) => {
                                return (
                                  <td
                                    key={cell.id}
                                    className={`mx-2 whitespace-nowrap !bg-transparent text-sm text-center p-2 py-3 ${
                                      ind === 0 && cellInd === 0
                                        ? "rounded-tl-md"
                                        : ind === table.getTotalSize() - 1 &&
                                          cellInd === 0
                                        ? "rounded-bl-md"
                                        : ind === 0 &&
                                          cellInd === columns.length - 1
                                        ? "rounded-tr-md"
                                        : ind === table.getTotalSize() - 1 &&
                                          cellInd === columns.length - 1
                                        ? "rounded-br-md"
                                        : ""
                                    }`}
                                    style={{
                                      width: cell.column.getSize()
                                    }}
                                  >
                                    {flexRender(
                                      cell.column.columnDef.cell,
                                      cell.getContext()
                                    )}
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>

                    <Pagination<IFleetDeviceBlueprint> table={table} />
                  </>
                ) : (
                  <NoData msg="No Blueprints Available" />
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default TemplatesAll;
