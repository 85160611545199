import React, { useEffect, useId, useMemo, useRef, useState } from "react";
import L, { DivIcon, Icon, LatLng, LatLngExpression, icon } from "leaflet";
import {
  MapContainer,
  TileLayer,
  useMap,
  useMapEvents,
  ImageOverlay,
  Polygon,
  Rectangle,
  Popup,
  Circle,
  Marker,
  Tooltip
} from "react-leaflet";
import { ICluster, ILocation, IMapView } from "@interfaces/index";

import "leaflet/dist/leaflet.css";
import LocationMarker from "./dash-map-marker-component";
import LocationCluster from "./dash-map-cluster-component";
import { Button } from "@tremor/react";
import useThemeStore from "@store/theme.store";
import {
  Bars3Icon,
  ChevronDownIcon,
  MapPinIcon,
  XMarkIcon
} from "@heroicons/react/24/outline";
import ReactSelect from "react-select";
import { reactSelectClassNames } from "@app/shared/utils/helper.util";

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png")
});

const tileLayerProps = {
  "dark-default": {
    url: "https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}.png",
    attribution:
      '&copy; <a href="https://www.stadiamaps.com/" target="_blank">Stadia Maps</a> &copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
  },
  golain: {
    url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
    attribution:
      '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
  }
};

const _towerData = [
  {
    Tower: "T1",
    Area: "Pre-roll",
    Vehicle: 3,
    Bounds: [
      {
        lat: 12.973277894811467,
        lng: 80.24247097337478
      },
      {
        lat: 12.973249807394115,
        lng: 80.24253995457309
      }
    ],
    Devices: [
      {
        VSN: "1",
        EOL: 1,
        position: {
          lat: 12.973272088771743,
          lng: 80.24247856569514
        }
      },
      {
        VSN: "2",
        EOL: 3,
        position: {
          lat: 12.973273103491316,
          lng: 80.24250078007046
        }
      },
      {
        VSN: "3",
        EOL: 5,
        position: {
          lat: 12.973261603335855,
          lng: 80.24252230024649
        }
      }
    ]
  },
  {
    Tower: "T2",
    Area: "WA-Parking",
    Vehicle: 4,
    Bounds: [
      {
        lat: 12.973378252074562,
        lng: 80.24244927506355
      },
      {
        lat: 12.973315765481548,
        lng: 80.24249947264919
      }
    ],
    Devices: [
      {
        VSN: "",
        EOL: 1,
        position: {
          lat: 12.973367850070757,
          lng: 80.2424623921639
        }
      },
      {
        VSN: "",
        EOL: 3,
        position: {
          lat: 12.973367156997115,
          lng: 80.24248064701766
        }
      },
      {
        VSN: "",
        EOL: 3,
        position: {
          lat: 12.973334120485058,
          lng: 80.24246476292414
        }
      },
      {
        VSN: "",
        EOL: 5,
        position: {
          lat: 12.973332734337612,
          lng: 80.24247922456155
        }
      }
    ]
  },
  {
    Tower: "T3",
    Area: "Post-roll/3rd Line",
    Vehicle: 6,
    Bounds: [
      {
        lat: 12.973276948044647,
        lng: 80.24254157385003
      },
      {
        lat: 12.973254856817691,
        lng: 80.24269508130543
      }
    ],
    Devices: [
      {
        VSN: "",
        EOL: 1,
        position: {
          lat: 12.973269664620227,
          lng: 80.24255390350874
        },
        path: [
          {
            lat: 12.973272767618393,
            lng: 80.24254210909433
          },
          {
            lat: 12.973269053527318,
            lng: 80.24255328913306
          }
        ]
      },
      {
        VSN: "",
        EOL: 1,
        position: {
          lat: 12.973269895644854,
          lng: 80.24257215836248
        },
        path: [
          {
            lat: 12.973271900997139,
            lng: 80.24254198204844
          },
          {
            lat: 12.973269301133385,
            lng: 80.24257221897145
          }
        ]
      },
      {
        VSN: "",
        EOL: 3,
        position: {
          lat: 12.973268509497053,
          lng: 80.2425961030408
        },
        path: [
          {
            lat: 12.973269796345534,
            lng: 80.24254185500254
          },
          {
            lat: 12.973268186906063,
            lng: 80.24259623064557
          }
        ]
      },
      {
        VSN: "",
        EOL: 3,
        position: {
          lat: 12.973268971546332,
          lng: 80.24261625450274
        },
        path: [
          {
            lat: 12.973269796345534,
            lng: 80.24254185500254
          },
          {
            lat: 12.97326831070909,
            lng: 80.24261643094289
          }
        ]
      },
      {
        VSN: "",
        EOL: 5,
        position: {
          lat: 12.973268509497053,
          lng: 80.24264209578925
        },
        path: [
          {
            lat: 12.973269467412821,
            lng: 80.24254191137467
          },
          {
            lat: 12.973268114453358,
            lng: 80.24264204961331
          }
        ]
      },
      {
        VSN: "",
        EOL: 5,
        position: {
          lat: 12.973268971546332,
          lng: 80.24267149321611
        },
        path: [
          {
            lat: 12.973269467412821,
            lng: 80.24254191137467
          },
          {
            lat: 12.973267931935458,
            lng: 80.2426717302921
          }
        ]
      }
    ]
  },
  {
    Tower: "T4",
    Area: "Minor-Repair",
    Vehicle: 2,
    Bounds: [
      {
        lat: 12.97339084836023,
        lng: 80.24261065421723
      },
      {
        lat: 12.973362826403465,
        lng: 80.242695152522
      }
    ],
    Devices: [
      {
        VSN: "",
        EOL: 1,
        position: {
          lat: 12.973379366367073,
          lng: 80.24262920022012
        }
      },
      {
        VSN: "",
        EOL: 3,
        position: {
          lat: 12.973379366367073,
          lng: 80.242673791945
        }
      }
    ]
  },
  {
    Tower: "T5",
    Area: "Shower Area",
    Vehicle: 4,
    Bounds: [
      {
        lat: 12.973322392848209,
        lng: 80.24268989961918
      },
      {
        lat: 12.97328610012381,
        lng: 80.24273362009697
      }
    ],
    Devices: [
      {
        VSN: "",
        EOL: 1,
        position: {
          lat: 12.973315329625345,
          lng: 80.24270094931126
        }
      },
      {
        VSN: "",
        EOL: 1,
        position: {
          lat: 12.973315002907242,
          lng: 80.242721401155
        }
      },
      {
        VSN: "",
        EOL: 1,
        position: {
          lat: 12.973295399819714,
          lng: 80.24269793182611
        }
      },
      {
        VSN: "",
        EOL: 1,
        position: {
          lat: 12.97329474638343,
          lng: 80.24272073060276
        }
      }
    ]
  },
  {
    Tower: "T6",
    Area: "Outer Area",
    Vehicle: 0,
    Bounds: [
      {
        lat: 12.973664795234614,
        lng: 80.2423384666278
      },
      {
        lat: 12.973481375271325,
        lng: 80.24255475985298
      }
    ],
    Devices: []
  }
];

const Bounds = ({ onChangeBoundingBox }) => {
  const maphand = useMap();

  useMapEvents({
    zoomend: () => {
      onChangeBoundingBox(
        maphand
          .getBounds()
          .toBBoxString()
          .split(",")
          .map((ele) => +ele)
      );
    },
    moveend: () => {
      onChangeBoundingBox(
        maphand
          .getBounds()
          .toBBoxString()
          .split(",")
          .map((ele) => +ele)
      );
    }
  });
  return null;
};

const center = (bounding_box: number[]) => {
  return {
    lng: (bounding_box[0] + bounding_box[2]) / 2,
    lat: (bounding_box[1] + bounding_box[3]) / 2
  };
};

const ZoomToArea = ({ area, device, setSelectedArea }) => {
  const map = useMapEvents({
    click(e) {
      console.log(e.latlng);
    }
  });

  useEffect(() => {
    if (area) {
      const el = _towerData.find((el) => el.Area === area);
      // map.setZoom(3);
      if (!device) {
        map.closePopup();
      }
      map.flyTo(
        center([
          el.Bounds[0].lng,
          el.Bounds[0].lat,
          el.Bounds[1].lng,
          el.Bounds[1].lat
        ]),
        22
      );
      // setTimeout(() => {
      //   map.setZoom(9);
      // }, 500);
    }
  }, [area, map]);

  useEffect(() => {
    if (device) {
      const el = device.towerData;
      if (!el) return;
      setSelectedArea({ label: el.Area, value: el.Area });
      map.flyTo(
        center([
          el.Bounds[0].lng,
          el.Bounds[0].lat,
          el.Bounds[1].lng,
          el.Bounds[1].lat
        ]),
        22
      );
    }
  }, [device?.VSN, map, setSelectedArea]);

  return null;
};

const IndoorMapView: React.FC<IMapView> = ({
  devices,
  clusters,
  bounding_box,
  deviceShadows,
  deviceShadowDefs,
  icons,
  onChangeBoundingBox,
  geoMapAdditionalInfo
}) => {
  const resizeObserverRef = React.useRef<ResizeObserver>(null);

  const [tableOpen, setTableOpen] = useState(true);

  const [selectedArea, setSelectedArea] = useState(null);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [markerRefs, setMarkerRefs] = useState({});

  const [theme] = useThemeStore((state) => [state.theme]);

  const tileRef = useRef(null);

  const mapRef = useRef(null);

  const id = useId();

  useEffect(() => {
    const resizeObserver = resizeObserverRef.current;
    return () => {
      if (resizeObserver) {
        resizeObserver.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    setSelectedDevice(null);
  }, [selectedArea]);

  const towerData = useMemo(() => {
    let vsnCounter = 1;

    return _towerData.map((val) => ({
      ...val,
      Devices: val.Devices.map((device) => ({
        ...device,
        VSN: "MECXXXXXXXX" + vsnCounter++
      }))
    }));
  }, []);

  const devicesFlat = useMemo(() => {
    const opts = [];
    towerData.forEach((el) => {
      el.Devices.forEach((dev) => {
        opts.push({
          ...dev,
          towerData: {
            Area: el.Area,
            Bounds: el.Bounds
          }
        });
      });
    });

    return opts;
  }, [towerData]);

  return (
    <div className="h-full w-full p-2 mb-2 -mt-[15px]">
      <MapContainer
        ref={(ref) => {
          if (!ref) return;
          mapRef.current = ref;
          const container = document.getElementById(id);
          if (!container) return;

          L.DomEvent.disableClickPropagation(
            container
          ).disableScrollPropagation(container);

          resizeObserverRef.current = new ResizeObserver(() => {
            if (!ref) {
              return;
            }
            if (ref.getContainer()) {
              ref?.invalidateSize({ pan: false });
            }
          });
          if (container) {
            resizeObserverRef.current.observe(container);
          }
        }}
        id={id}
        center={[12.973257251140335, 80.24305716228164] as LatLngExpression}
        zoom={19}
        maxZoom={40}
        zoomSnap={0.45}
        zoomControl={false}
        style={{
          width: "100%",
          height: "100%",
          marginTop: "6px",
          borderRadius: "6px",
          zIndex: 0
        }}
      >
        <TileLayer
          ref={tileRef}
          attribution={tileLayerProps[theme].attribution}
          url={tileLayerProps[theme].url}
        />

        <ImageOverlay
          url={`${process.env.PUBLIC_URL}/indoor-map.png`}
          bounds={[
            [12.973769794104062, 80.24210751056673],
            [12.9731, 80.2434]
          ]}
        />
        <ZoomToArea
          area={selectedArea?.value}
          device={devicesFlat.find((d) => d.VSN === selectedDevice?.value)}
          setSelectedArea={setSelectedArea}
        />

        <Bounds onChangeBoundingBox={onChangeBoundingBox} />

        {towerData.map((val) => (
          <>
            <Rectangle
              bounds={val.Bounds.map((pos) => [pos.lat, pos.lng])}
              pathOptions={{
                color: "orange",
                opacity: selectedArea?.value === val.Area ? 0.5 : 0.3,
                fillOpacity: selectedArea?.value === val.Area ? 0.4 : 0.2
              }}
            >
              <Popup>{val.Area}</Popup>
            </Rectangle>
            <DevicesContainer val={val} selectedDevice={selectedDevice} />
          </>
        ))}
      </MapContainer>

      <div className="absolute top-[2.75rem] left-4 flex gap-2">
        <ReactSelect
          placeholder="Search Serial Number"
          isSearchable
          options={devicesFlat.map((dev) => ({
            label: dev.VSN,
            value: dev.VSN
          }))}
          value={selectedDevice}
          onChange={(opt) => {
            setSelectedDevice(opt);
          }}
          // classNames={reactSelectClassNames}
          className="w-[250px]"
        />
        <ReactSelect
          placeholder="Search Area"
          isSearchable
          options={towerData.map((val) => ({
            label: val.Area,
            value: val.Area
          }))}
          value={selectedArea}
          onChange={(opt) => {
            setSelectedArea(opt);
          }}
          // classNames={reactSelectClassNames}
          className="w-[150px]"
        />
      </div>

      <div className="absolute top-[2.75rem] min-w-[350px] rounded-md right-4 bg-white flex flex-col">
        <div
          onClick={() => setTableOpen(!tableOpen)}
          className="text-center py-1 rounded-md select-none cursor-pointer relative flex justify-center bg-primary text-white"
        >
          <span>EOL WIP-66</span>
          <ChevronDownIcon
            width={16}
            className={`absolute right-2 top-2 transition-transform duration-100 ${
              tableOpen ? "rotate-180" : "rotate-0"
            }`}
          />
        </div>

        <table className={`${tableOpen ? "" : "hidden"} text-center`}>
          <tr className="border-b border-background-layer3">
            <th className="pl-4">Tower</th>
            <th className="">Area</th>
            <th className="pr-4">Devices</th>
          </tr>
          <tbody>
            {towerData.map((val, ind) => (
              <tr
                className={`border-b cursor-pointer select-none border-background-layer3 ${
                  selectedArea?.value === val.Area ? "bg-blue-300" : ""
                }`}
                onClick={() => {
                  setSelectedArea({
                    label: val.Area,
                    value: val.Area
                  });
                }}
              >
                <td>{val.Tower}</td>
                <td>{val.Area}</td>
                <td>{val.Vehicle}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const DevicesContainer = ({
  val,
  selectedDevice
}: {
  val: (typeof _towerData)[0];
  selectedDevice: any;
}) => {
  const map = useMap();
  const refs = useRef({});

  useEffect(() => {
    console.log({ refs, selectedDevice });
    if (
      selectedDevice?.value &&
      refs.current?.[selectedDevice.value] instanceof L.Popup
    ) {
      setTimeout(() => {
        refs.current[selectedDevice.value].openOn(map);
      });
    }
  }, [map, refs, selectedDevice]);

  return map.getZoom() < 20 ? (
    <Circle
      center={center([
        val.Bounds[0].lng,
        val.Bounds[0].lat,
        val.Bounds[1].lng,
        val.Bounds[1].lat
      ])}
      radius={val.Devices.length}
    />
  ) : (
    <>
      {val.Devices.map((device) => (
        <Marker
          icon={
            new Icon({
              iconUrl:
                device.EOL < 2
                  ? "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-green.png"
                  : device.EOL < 4
                  ? "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-yellow.png"
                  : "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png",
              shadowUrl:
                "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
              iconSize: [25, 41],
              iconAnchor: [12, 41],
              popupAnchor: [1, -34],
              shadowSize: [41, 41]
            })
          }
          position={new LatLng(device.position.lat, device.position.lng)}
        >
          {device.path ? (
            <Rectangle
              bounds={device.path.map((pos) => [pos.lat, pos.lng])}
              pathOptions={{
                color:
                  device.EOL < 2 ? "green" : device.EOL < 4 ? "yellow" : "red",
                opacity: 0.7,
                fillOpacity: 0.5
              }}
            >
              {/* <Popup>{val.Area}</Popup> */}
            </Rectangle>
          ) : null}
          <Popup
            ref={(ref) =>
              (refs.current = { ...refs.current, [device.VSN]: ref })
            }
          >
            <div className="flex flex-col gap-1 ">
              <div className="text-base font-medium ">{device.VSN}</div>
              <div className="flex gap-1 items-center">
                <MapPinIcon width={18} />
                <div>
                  {device.position.lat.toFixed(4)},{" "}
                  {device.position.lng.toFixed(4)}
                </div>
              </div>
              <div className="flex gap-1 items-center">
                Area:
                <div>{val.Area}</div>
              </div>
              <div className="flex gap-1 items-center">
                Last Moved:
                <div>{"10:11 AM"}</div>
              </div>
              <div className="flex gap-1 items-center">
                EOL Ageing:
                <div>{`${device.EOL} Hrs`}</div>
              </div>
            </div>
          </Popup>
          <Tooltip>{device.VSN}</Tooltip>
        </Marker>
      ))}
    </>
  );
};

export default React.memo(IndoorMapView);
