import { useMutation, useQueryClient } from "react-query";

import networkService from "@services/network.service";
import { useAuthStore, useFleetAndDevicesStore } from "@store/index";
import {
  ICreateAPIKeyResponse,
  IPermission
} from "@interfaces/apps.interface";
import { OrgAccessLevel } from "@/interfaces/user-groups.interface";

export interface ICreateAppPayload {
  app_name: string;
  app_description: string;
  permissions: IPermission[];
  org_access: OrgAccessLevel;
}

export const createApp = async (
  projectId: string,
  orgId: string,
  payload: ICreateAppPayload
) => {
  const { data, ok } = await networkService.post<ICreateAPIKeyResponse>(
    `organizations/apps/`,
    payload,
    {
      headers: {
        "ORG-ID": orgId
      }
    }
  );

  if (ok) {
    return data;
  } else return null;
};

export const useCreateApp = () => {
  const [selectedProject] = useFleetAndDevicesStore((state) => [
    state.selectedProject
  ]);
  const [user] = useAuthStore((state) => [state.user]);
  const queryClient = useQueryClient();

  const res = useMutation(
    async (data: ICreateAppPayload) =>
      await createApp(selectedProject.id, user.selectedOrg.id, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getApps");
      }
    }
  );

  return res;
};
