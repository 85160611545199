import {
  ArchiveBoxXMarkIcon,
  DocumentTextIcon,
  KeyIcon,
  UserGroupIcon,
  UserPlusIcon,
  UsersIcon
} from "@heroicons/react/24/outline";
import { NavLink } from "react-router-dom";

function OrgNavigation() {
  return (
    <div className="px-4 pt-6 bg-background border-background-layer3 border-b lg:px-8 sm:px-6 ">
      <div className=" md:grid md:gap-2 md:grid-cols-6 md:place-items-start">
        <NavLink
          to="/settings/organization-settings/org-details"
          className={({ isActive }) =>
            `${
              isActive ? "text-primaryLight border-b-2 border-primary" : ""
            } flex items-center px-2 py-5 -mb-px text-center text-contentColor lg:px-4 whitespace-nowrap`
          }
        >
          {({ isActive }) => (
            <div className="flex flex-row justify-between items-center">
              <DocumentTextIcon
                width={20}
                className={`${
                  isActive ? "text-primaryLight" : "text-contentColorLighter"
                }`}
              />

              <span
                className={`${
                  isActive && "text-primborder-primary font-medium"
                } text-sm ml-2`}
              >
                Org Details
              </span>
            </div>
          )}
        </NavLink>

        <NavLink
          to="/settings/organization-settings/members"
          className={({ isActive }) =>
            `${
              isActive ? "text-primaryLight border-b-2 border-primary" : ""
            } flex items-center px-2 py-5 -mb-px text-center text-contentColor lg:px-4 whitespace-nowrap`
          }
        >
          {({ isActive }) => (
            <div className="flex flex-row justify-between items-center">
              <UsersIcon
                width={20}
                className={`${
                  isActive ? "text-primaryLight" : "text-contentColorLighter"
                }`}
              />

              <span
                className={`${
                  isActive && "text-primborder-primary font-medium"
                } text-sm ml-2`}
              >
                Members
              </span>
            </div>
          )}
        </NavLink>

        <NavLink
          to="/settings/organization-settings/invites"
          className={({ isActive }) =>
            `${
              isActive ? "text-primaryLight border-b-2 border-primary" : ""
            } flex items-center px-2 py-5 -mb-px text-center text-contentColor lg:px-4 whitespace-nowrap`
          }
        >
          {({ isActive }) => (
            <div className="flex flex-row justify-between items-center">
              <UserPlusIcon
                width={20}
                className={`${
                  isActive ? "text-primaryLight" : "text-contentColorLighter"
                }`}
              />

              <span
                className={`${
                  isActive && "text-primborder-primary font-medium"
                } text-sm ml-2`}
              >
                Invites
              </span>
            </div>
          )}
        </NavLink>

        <NavLink
          to="/settings/organization-settings/groups"
          className={({ isActive }) =>
            `${
              isActive ? "text-primaryLight border-b-2 border-primary" : ""
            } flex items-center px-2 py-5 -mb-px text-center text-contentColor lg:px-4 whitespace-nowrap`
          }
        >
          {({ isActive }) => (
            <div className="flex flex-row justify-between items-center">
              <UserGroupIcon
                width={20}
                className={`${
                  isActive ? "text-primaryLight" : "text-contentColorLighter"
                }`}
              />

              <span
                className={`${
                  isActive && "text-primborder-primary font-medium"
                } text-sm ml-2`}
              >
                Groups
              </span>
            </div>
          )}
        </NavLink>

        <NavLink
          to="/settings/organization-settings/api-keys"
          className={({ isActive }) =>
            `${
              isActive ? "text-primaryLight border-b-2 border-primary" : ""
            } flex items-center px-2 py-5 -mb-px text-center text-contentColor lg:px-4 whitespace-nowrap`
          }
        >
          {({ isActive }) => (
            <div className="flex flex-row justify-between items-center">
              <KeyIcon
                width={20}
                className={`${
                  isActive ? "text-primaryLight" : "text-contentColorLighter"
                }`}
              />

              <span
                className={`${
                  isActive && "text-primborder-primary font-medium"
                } text-sm ml-2`}
              >
                API Keys
              </span>
            </div>
          )}
        </NavLink>

        <NavLink
          to="/settings/organization-settings/deleted-projects"
          className={({ isActive }) =>
            `${
              isActive ? "text-primaryLight border-b-2 border-primary" : ""
            } flex items-center px-2 py-5 -mb-px text-center text-contentColor lg:px-4 whitespace-nowrap`
          }
        >
          {({ isActive }) => (
            <div className="flex flex-row justify-between items-center">
              <ArchiveBoxXMarkIcon
                width={20}
                className={`${
                  isActive ? "text-primaryLight" : "text-contentColorLighter"
                }`}
              />

              <span
                className={`${
                  isActive && "text-primborder-primary font-medium"
                } text-sm ml-2`}
              >
                Deleted Projects
              </span>
            </div>
          )}
        </NavLink>
      </div>
    </div>
  );
}

export default OrgNavigation;
