import React, { useEffect, useState } from "react";
import BlueprintDetailsTabs, {
  BlueprintTabs
} from "./device-blueprint-tabs.component";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import DeviceBlueprintDetails from "./tabs/device-blueprint-details.component";
import {
  IBlueprint,
  useGetDashPanelBlueprints
} from "@/app/shared/hooks/get/blueprints";
import ShowLoading from "@/app/shared/components/loading.component";
import BlueprintUsage from "./tabs/device-blueprint-usage.component";
import { useGetFleetDeviceBlueprints } from "@/app/shared/hooks/get/device-blueprints";
import { useFleetAndDevicesStore } from "@/store";
import { IFleetDeviceBlueprint } from "@/interfaces/device-blueprint.interface";

const DeviceBlueprintTabsRenderer: React.FC = () => {
  const [blueprint, setBlueprint] = useState<IFleetDeviceBlueprint>(null);

  const params = useParams();
  const blueprintId = params?.["id"];

  const [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get("tab") as BlueprintTabs;

  const selectedFleet = useFleetAndDevicesStore(
    (state) => state.selectedFleet
  );

  const { isLoading } = useGetFleetDeviceBlueprints(
    selectedFleet?.id,
    {
      fleet_device_template_id: blueprintId
    },
    (blueprints) => {
      if (!blueprints?.length) {
        toast.error(`Device Blueprint with ID ${blueprintId} not found.`);

        return;
      }

      const _blueprint = blueprints[0];
      setBlueprint(_blueprint);
    }
  );

  const [activeTab, setActiveTab] = useState<BlueprintTabs>(
    tab ?? BlueprintTabs.DETAILS
  );

  useEffect(() => {
    setSearchParams(
      { tab: activeTab ?? BlueprintTabs.DETAILS },
      { replace: true }
    );
  }, [activeTab, setSearchParams]);

  if (isLoading) {
    return <ShowLoading />;
  }

  return (
    <>
      <BlueprintDetailsTabs
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />

      {activeTab === BlueprintTabs.DETAILS && blueprint ? (
        <DeviceBlueprintDetails blueprint={blueprint} />
      ) : null}
    </>
  );
};

export default DeviceBlueprintTabsRenderer;
