import { ShieldCheckIcon } from "@heroicons/react/24/outline";
import React from "react";

export enum UserGroupTabs {
  DETAILS = "Details",
  CUSTOM_AUTH = "Custom Auth"
  // USERS = "Users"
}

const iconsMap = {
  [UserGroupTabs.DETAILS]: (activeTab) => (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.5 14.625C3.5 14.1277 3.69754 13.6508 4.04917 13.2992C4.40081 12.9475 4.87772 12.75 5.375 12.75H15.5"
        stroke={activeTab === UserGroupTabs.DETAILS ? "#546CCC" : "#565759"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.375 1.5H15.5V16.5H5.375C4.87772 16.5 4.40081 16.3025 4.04917 15.9508C3.69754 15.5992 3.5 15.1223 3.5 14.625V3.375C3.5 2.87772 3.69754 2.40081 4.04917 2.04917C4.40081 1.69754 4.87772 1.5 5.375 1.5V1.5Z"
        stroke={activeTab === UserGroupTabs.DETAILS ? "#546CCC" : "#565759"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  [UserGroupTabs.CUSTOM_AUTH]: (activeTab) => (
    <ShieldCheckIcon
      className={`${
        activeTab === UserGroupTabs.CUSTOM_AUTH
          ? "text-primary"
          : "text-contentColor"
      }`}
      width={20}
    />
  )
  // [UserGroupTabs.USERS]: (activeTab) => (
  //   <UserGroupIcon
  //     className={`${
  //       activeTab === UserGroupTabs.USERS
  //         ? "text-primary"
  //         : "text-contentColor"
  //     }`}
  //     width={20}
  //   />
  // )
};

interface IUserGroupDetailsTabs {
  activeTab: UserGroupTabs;
  setActiveTab: (tab: UserGroupTabs) => void;
}

const UserGroupDetailsTabs: React.FC<IUserGroupDetailsTabs> = ({
  activeTab,
  setActiveTab
}) => {
  return (
    <div className="px-4 pt-6 bg-background border-background-layer3 border-b lg:px-8 sm:px-6 ">
      <div className=" md:grid md:gap-4 md:grid-cols-5 ">
        {(Object.keys(UserGroupTabs) as Array<keyof typeof UserGroupTabs>).map(
          (key) => (
            <button
              onClick={() => setActiveTab(UserGroupTabs[key])}
              className={`${
                activeTab === UserGroupTabs[key]
                  ? "text-primaryLight border-b-2 border-primary"
                  : ""
              } flex items-center justify-center px-2 py-5 -mb-px text-center text-contentColor lg:px-4 whitespace-nowrap`}
            >
              {
                <div className="flex flex-row justify-between items-center">
                  {iconsMap[UserGroupTabs[key]](activeTab)}

                  <span
                    className={`${
                      activeTab === UserGroupTabs[key] &&
                      "text-primary border-primary font-medium"
                    } text-sm ml-2`}
                  >
                    {UserGroupTabs[key]}
                  </span>
                </div>
              }
            </button>
          )
        )}
      </div>
    </div>
  );
};

export default UserGroupDetailsTabs;
