import { useState } from "react";
import { AddOverlayLayout } from "@app/shared/components";
import { FormikHelpers } from "formik";
import useSettingsStore from "@store/settings/settings.store";
import { copyToClipboard } from "@app/shared/utils/helper.util";
import {
  ICreateAppPayload,
  useCreateApp
} from "@app/shared/hooks/post/create-app";
import { toast } from "react-toastify";
import InviteForm, { IInviteFormState } from "./invite-form.component";
import { Callout } from "@tremor/react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { IProjectApp } from "@/interfaces/apps.interface";

interface IApiKeysFormProps {
  selectedApp: IProjectApp;
}

const ApiKeyForm: React.FC<IApiKeysFormProps> = ({ selectedApp }) => {
  const [appKey, setAppKey] = useState<string>("");

  const [showGenerateAPIKeyForm, setShowGenerateAPIKeyForm] = useSettingsStore(
    (state) => [state.showGenerateAPIKeyForm, state.setShowGenerateAPIKeyForm]
  );

  const createApiKeyMutation = useCreateApp();

  const handleSubmit = async (
    values: IInviteFormState,
    formikHelpers: FormikHelpers<IInviteFormState>
  ) => {
    const payload: ICreateAppPayload = {
      app_name: values.name,
      app_description: values.name,
      permissions: values.resource_access.map((perm) => ({
        resource: perm.resource.value,
        actions: perm.actions.map((action) => action.value),
        resource_type: perm.resource_type.value
      })),
      org_access: values.org_access
    };

    createApiKeyMutation.mutate(payload, {
      onSuccess: (projectAppKey) => {
        if (projectAppKey) {
          setAppKey(projectAppKey);

          formikHelpers.resetForm();
          toast.success("Created API Key successfully.");
        }
      }
    });
  };

  const closeAndClear = () => {
    setAppKey("");
    setShowGenerateAPIKeyForm(false);
  };

  return (
    <AddOverlayLayout
      title="Generate API key"
      overlayOpen={showGenerateAPIKeyForm}
      setOverlayOpen={closeAndClear}
    >
      {appKey ? (
        <div className="w-full flex flex-col flex-1 mt-7">
          <div className="mb-5">
            <div className="flex items-end">
              <input
                disabled
                type="text"
                id="appKey"
                name="appKey"
                value={appKey}
                placeholder="Org Name"
                className="block w-10/12 p-3 mt-2 border-background-layer3 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm"
              />
              <div>
                <button
                  type="button"
                  onClick={() => copyToClipboard(appKey)}
                  className="block ml-3.5 bg-[#166ff629] p-3 rounded"
                >
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.41667 5.50016V2.75016C6.41667 2.50705 6.51324 2.27389 6.68515 2.10198C6.85706 1.93007 7.09022 1.8335 7.33333 1.8335H18.3333C18.5764 1.8335 18.8096 1.93007 18.9815 2.10198C19.1534 2.27389 19.25 2.50705 19.25 2.75016V15.5835C19.25 15.8266 19.1534 16.0598 18.9815 16.2317C18.8096 16.4036 18.5764 16.5002 18.3333 16.5002H15.5833V19.2502C15.5833 19.7562 15.1708 20.1668 14.6603 20.1668H3.67308C3.5522 20.1676 3.43236 20.1444 3.32046 20.0987C3.20856 20.0529 3.1068 19.9855 3.02102 19.9004C2.93524 19.8152 2.86714 19.7139 2.82063 19.6023C2.77412 19.4907 2.75012 19.371 2.75 19.2502L2.75275 6.41683C2.75275 5.91083 3.16525 5.50016 3.67492 5.50016H6.41667ZM4.58517 7.3335L4.58333 18.3335H13.75V7.3335H4.58517ZM8.25 5.50016H15.5833V14.6668H17.4167V3.66683H8.25V5.50016ZM6.41667 10.0835H11.9167V11.9168H6.41667V10.0835ZM6.41667 13.7502H11.9167V15.5835H6.41667V13.7502Z"
                      fill="#546CCC"
                    />
                  </svg>
                </button>
              </div>
            </div>
            <Callout
              color={"red"}
              icon={ExclamationTriangleIcon}
              title="Copy the key before closing, You cannot access it again."
              className="mt-6"
            />
          </div>
        </div>
      ) : (
        <InviteForm
          renderAs="app"
          selectedApp={selectedApp}
          showAddToGroup={false}
          hideOrgPerms
          showDescField={true}
          handleSubmit={handleSubmit}
        />
      )}
    </AddOverlayLayout>
  );
};

export default ApiKeyForm;
