import { FieldError } from "@/app/shared/components";
import { CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { Badge, Button, Callout } from "@tremor/react";
import { Formik, Field, Form, ErrorMessage, FormikHelpers } from "formik";
import { useCallback } from "react";
import * as Yup from "yup";

export interface ICreateExternalUserFormState {
  username: string;
  first_name: string;
  last_name: string;
  email: string;
  email_verified: boolean;
  set_initial_password: boolean;
  initial_password: string;
}

export interface ICreateExternalUserFormProps {
  onSubmit: (
    values: ICreateExternalUserFormState,
    formikHelpers: FormikHelpers<ICreateExternalUserFormState>
  ) => void;
  loading: boolean;
  error: string;
}

const validationSchema = Yup.object().shape({
  username: Yup.string().required("Username is required"),
  first_name: Yup.string().required("First name is required"),
  last_name: Yup.string().required("Last name is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  email_verified: Yup.boolean().required("Email verification is required"),
  set_initial_password: Yup.boolean().required("Initial password is required"),
  initial_password: Yup.string().when("set_initial_password", {
    is: true,
    then: Yup.string().required("Initial password is required")
  })
});

const initialFormValues: ICreateExternalUserFormState = {
  username: "",
  first_name: "",
  last_name: "",
  email: "",
  email_verified: false,
  set_initial_password: false,
  initial_password: ""
};

const CreateExternalUserForm: React.FC<ICreateExternalUserFormProps> = ({
  onSubmit,
  loading,
  error
}) => {
  const handleSubmit = (
    values: ICreateExternalUserFormState,
    formikHelpers: FormikHelpers<ICreateExternalUserFormState>
  ) => {
    onSubmit(values, formikHelpers);
  };

  const validInitialPassword = useCallback((password) => {
    // password requirements:
    // Minimum length 8
    // Uppercase letter
    // Lowercase letter
    // Number
    // Symbol
    // Confirmation match
    // return which of the requirements are not met.

    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasSymbol = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(password);
    const hasLength = password.length >= 8;

    return {
      hasUpperCase,
      hasLowerCase,
      hasNumber,
      hasSymbol,
      hasLength,
      valid:
        hasUpperCase && hasLowerCase && hasNumber && hasSymbol && hasLength
    };
  }, []);

  return (
    <Formik
      // innerRef={formikRef}
      initialValues={initialFormValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ values, handleSubmit, isSubmitting }) => (
        <Form onSubmit={handleSubmit} className="flex-1">
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
            <div className="col-span-2">
              <label
                htmlFor="username"
                className="block text-sm font-medium text-gray-700"
              >
                Username
              </label>
              <Field
                id="username"
                name="username"
                type="text"
                autoComplete="off"
                className="mt-1 block w-full px-3 py-2 border border-background-layer3 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />

              <ErrorMessage name={`username`}>
                {(msg) => <FieldError message={msg} />}
              </ErrorMessage>
            </div>
            <div>
              <label
                htmlFor="first_name"
                className="block text-sm font-medium text-gray-700"
              >
                First Name
              </label>
              <Field
                id="first_name"
                name="first_name"
                type="text"
                autoComplete="off"
                className="mt-1 block w-full px-3 py-2 border border-background-layer3 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
              <ErrorMessage name={`first_name`}>
                {(msg) => <FieldError message={msg} />}
              </ErrorMessage>
            </div>
            <div>
              <label
                htmlFor="last_name"
                className="block text-sm font-medium text-gray-700"
              >
                Last Name
              </label>
              <Field
                id="last_name"
                name="last_name"
                type="text"
                autoComplete="off"
                className="mt-1 block w-full px-3 py-2 border border-background-layer3 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
              <ErrorMessage name={`last_name`}>
                {(msg) => <FieldError message={msg} />}
              </ErrorMessage>
            </div>
            <div className="col-span-2">
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email
              </label>
              <Field
                id="email"
                name="email"
                type="email"
                autoComplete="off"
                className="mt-1 block w-full px-3 py-2 border border-background-layer3 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
              <ErrorMessage name="email">
                {(msg) => <FieldError message={msg} />}
              </ErrorMessage>
            </div>
            <div>
              <div className=" flex gap-1 items-center">
                <Field
                  id="email_verified"
                  name="email_verified"
                  type="checkbox"
                  className="block w-min p-2 border border-background-layer3 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
                <label
                  htmlFor="email_verified"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email Verified
                </label>
              </div>
              <ErrorMessage name="email_verified">
                {(msg) => <FieldError message={msg} />}
              </ErrorMessage>
            </div>
            <div>
              <div className=" flex gap-1 items-center">
                <Field
                  id="set_initial_password"
                  name="set_initial_password"
                  type="checkbox"
                  className="block w-min p-2 border border-background-layer3 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
                <label
                  htmlFor="set_initial_password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Set Initial Password
                </label>
              </div>
              <ErrorMessage name="set_initial_password">
                {(msg) => <FieldError message={msg} />}
              </ErrorMessage>
            </div>
            {values.set_initial_password ? (
              <div className="col-span-2">
                <label
                  htmlFor="initial_password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Initial Password
                </label>
                <Field
                  id="initial_password"
                  name="initial_password"
                  type="password"
                  autoComplete="off"
                  className="mt-1 block w-full px-3 py-2 border border-background-layer3 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />

                <div className="grid grid-cols-2 gap-2 mt-2">
                  {validInitialPassword(values.initial_password).hasLength ? (
                    <Badge
                      className="!border-none !ring-transparent bg-transparent"
                      icon={CheckIcon}
                      size={"xs"}
                      color="green"
                    >
                      Minimum length 8
                    </Badge>
                  ) : (
                    <Badge
                      className="!border-none !ring-transparent bg-transparent"
                      icon={XMarkIcon}
                      size={"xs"}
                      color="red"
                    >
                      Minimum length 8
                    </Badge>
                  )}

                  {validInitialPassword(values.initial_password)
                    .hasUpperCase ? (
                    <Badge
                      className="!border-none !ring-transparent bg-transparent"
                      icon={CheckIcon}
                      size={"xs"}
                      color="green"
                    >
                      Uppercase letter
                    </Badge>
                  ) : (
                    <Badge
                      className="!border-none !ring-transparent bg-transparent"
                      icon={XMarkIcon}
                      size={"xs"}
                      color="red"
                    >
                      Uppercase letter
                    </Badge>
                  )}

                  {validInitialPassword(values.initial_password)
                    .hasLowerCase ? (
                    <Badge
                      className="!border-none !ring-transparent bg-transparent"
                      icon={CheckIcon}
                      size={"xs"}
                      color="green"
                    >
                      Lowercase letter
                    </Badge>
                  ) : (
                    <Badge
                      className="!border-none !ring-transparent bg-transparent"
                      icon={XMarkIcon}
                      size={"xs"}
                      color="red"
                    >
                      Lowercase letter
                    </Badge>
                  )}

                  {validInitialPassword(values.initial_password).hasNumber ? (
                    <Badge
                      className="!border-none !ring-transparent bg-transparent"
                      icon={CheckIcon}
                      size={"xs"}
                      color="green"
                    >
                      Number
                    </Badge>
                  ) : (
                    <Badge
                      className="!border-none !ring-transparent bg-transparent"
                      icon={XMarkIcon}
                      size={"xs"}
                      color="red"
                    >
                      Number
                    </Badge>
                  )}

                  {validInitialPassword(values.initial_password).hasSymbol ? (
                    <Badge
                      className="!border-none !ring-transparent bg-transparent"
                      icon={CheckIcon}
                      size={"xs"}
                      color="green"
                    >
                      Symbol
                    </Badge>
                  ) : (
                    <Badge
                      className="!border-none !ring-transparent bg-transparent"
                      icon={XMarkIcon}
                      size={"xs"}
                      color="red"
                    >
                      Symbol
                    </Badge>
                  )}
                </div>
              </div>
            ) : null}
          </div>
          <div className="mt-4">
            <Callout title="Note">
              If both options are selected, no email for initialization will be
              sent. If only one options is selected, a mail to provide / verify
              the data will be sent.
            </Callout>
          </div>
          <div className="mt-12">
            <Button
              type="submit"
              className="w-full"
              loading={isSubmitting || loading}
              disabled={
                isSubmitting ||
                (values.set_initial_password &&
                  !validInitialPassword(values.initial_password).valid)
              }
            >
              Create User
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default CreateExternalUserForm;
