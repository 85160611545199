import { useEffect, useMemo, useState } from "react";
import useFleetAndDevicesStore from "../../../../store/fleet-and-devices/fleet-and-devices.store";
import { toast } from "react-toastify";
import { IDevice } from "../../../../interfaces";
import Details from "../../../dashboard/dash-panel-details.component";
import networkService from "@services/network.service";
import useAuthStore from "@store/auth.store";
import { loggerService } from "@services/logger";

const CREATE_EV_PANELS = false;

interface IDetailsTab {
  device: IDevice;
}

interface IDeviceDataPointDefinitionResponse {
  data: {
    deviceDataPoints: any[];
  };
  ok: number;
}

function DeviceDashboard(props: IDetailsTab) {
  const { device } = props;
  const [dataPointId, setDataPointId] = useState("");
  const [devices] = useFleetAndDevicesStore((state) => [state.devices]);
  let [isOpen, setIsOpen] = useState(false);
  const [selectedProject] = useFleetAndDevicesStore((state) => [
    state.selectedProject
  ]);

  const [user] = useAuthStore((state) => [state.user]);

  useEffect(() => {
    networkService
      .get<IDeviceDataPointDefinitionResponse>(
        `projects/${selectedProject.id}/device_data_points`,
        {},
        {
          headers: {
            "ORG-ID": user.selectedOrg.id
          }
        }
      )
      .then((res) => {
        if (res.ok) {
          const ddp = res.data.deviceDataPoints.find(
            (dataPoint) => dataPoint.device_id === device?.id
          );
          setDataPointId(ddp.data_point_definition_id);
        }
      })
      .catch((err) => {
        loggerService.error(err);
      });

    return () => {};
  }, [device?.id, selectedProject.id, user.selectedOrg.id]);

  return (
    <>
      {!!device && (
        <div>
          <Details
            // hideAddPanel
            device={{ id: device.id, data_point_id: dataPointId }}
            onAddPanel={() => setIsOpen(true)}
          />
          {CREATE_EV_PANELS && <div></div>}
        </div>
      )}
    </>
  );
}

export default DeviceDashboard;
