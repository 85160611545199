import { ErrorMessage, Field, Form, Formik } from "formik";
import { FieldError } from "@app/shared/components";
import * as yup from "yup";
import { Button } from "@tremor/react";
import { copyToClipboard } from "@/app/shared/utils/helper.util";

interface CreateBlueprintInfoProps {
  initialState: {
    deviceBlueprintName: string;
    deviceNamePrefix: string;
    deviceNameSuffix: string;
  };
  readonly?: boolean;
  onCancel?: () => void;
  onNext?: (values: any) => void;
}

const validationSchema = yup.object({
  deviceBlueprintName: yup.string().required("Blueprint Name is required.")
});

const CreateBlueprintInfo: React.FC<CreateBlueprintInfoProps> = ({
  readonly,
  initialState,
  onCancel,
  onNext
}) => {
  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialState}
      onSubmit={onNext}
    >
      <Form className="flex flex-col">
        <div>
          <h1 className="text-lg text-left font-medium mb-2.5">
            Blueprint Info
          </h1>

          <div className="mb-5">
            <label className="text-sm font-medium text-contentColorLight">
              Blueprint Name*
            </label>
            <div className="flex items-end">
              <Field
                type="text"
                disabled={readonly}
                id="deviceBlueprintName"
                name="deviceBlueprintName"
                placeholder="Blueprint Name"
                className={`block w-6/12 p-3 mt-2 border-background-layer3 rounded-md focus:ring focus:ring-opacity-40 focus:ring-primary focus:border-primaryLight sm:text-sm ${
                  readonly ? "bg-background-layer1" : "bg-background"
                }`}
              />

              {readonly ? (
                <button
                  type="button"
                  onClick={() =>
                    copyToClipboard(initialState.deviceBlueprintName)
                  }
                  className="block ml-3.5 bg-[#166ff629] p-3 rounded"
                >
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.41667 5.50016V2.75016C6.41667 2.50705 6.51324 2.27389 6.68515 2.10198C6.85706 1.93007 7.09022 1.8335 7.33333 1.8335H18.3333C18.5764 1.8335 18.8096 1.93007 18.9815 2.10198C19.1534 2.27389 19.25 2.50705 19.25 2.75016V15.5835C19.25 15.8266 19.1534 16.0598 18.9815 16.2317C18.8096 16.4036 18.5764 16.5002 18.3333 16.5002H15.5833V19.2502C15.5833 19.7562 15.1708 20.1668 14.6603 20.1668H3.67308C3.5522 20.1676 3.43236 20.1444 3.32046 20.0987C3.20856 20.0529 3.1068 19.9855 3.02102 19.9004C2.93524 19.8152 2.86714 19.7139 2.82063 19.6023C2.77412 19.4907 2.75012 19.371 2.75 19.2502L2.75275 6.41683C2.75275 5.91083 3.16525 5.50016 3.67492 5.50016H6.41667ZM4.58517 7.3335L4.58333 18.3335H13.75V7.3335H4.58517ZM8.25 5.50016H15.5833V14.6668H17.4167V3.66683H8.25V5.50016ZM6.41667 10.0835H11.9167V11.9168H6.41667V10.0835ZM6.41667 13.7502H11.9167V15.5835H6.41667V13.7502Z"
                      fill="#546CCC"
                    />
                  </svg>
                </button>
              ) : null}
            </div>
            <ErrorMessage name="deviceBlueprintName">
              {(msg) => <FieldError message={msg} />}
            </ErrorMessage>
          </div>

          <label className="text-sm font-medium text-contentColorLight">
            Device Name (Optional)
          </label>
          <div className="flex justify-between gap-4">
            <div className="mb-5">
              <Field
                type="text"
                disabled={readonly}
                id="deviceNamePrefix"
                name="deviceNamePrefix"
                placeholder="Prefix"
                className={`block p-3 mt-2 border-background-layer3 bg-background rounded-md focus:ring focus:ring-opacity-40 focus:ring-primary focus:border-primaryLight sm:text-sm ${
                  readonly ? "bg-background-layer1" : "bg-background"
                }`}
              />
            </div>
            <div className="mb-5 mt-auto flex-grow">
              <Field
                type="text"
                readonly
                value="Auto Generated ID"
                disabled
                placeholder="Auto Generated ID"
                className={`block w-full p-3 mt-auto border-background-layer3 bg-background-layer1 rounded-md sm:text-sm`}
              />
            </div>
            <div className="mb-5">
              <Field
                type="text"
                disabled={readonly}
                id="deviceNameSuffix"
                name="deviceNameSuffix"
                placeholder="Suffix"
                className={`block p-3 mt-2 border-background-layer3 bg-background rounded-md focus:ring focus:ring-opacity-40 focus:ring-primary focus:border-primaryLight sm:text-sm ${
                  readonly ? "bg-background-layer1" : "bg-background"
                }`}
              />
            </div>
          </div>
        </div>

        {onCancel && onNext ? (
          <div className={`w-10/12 flex items-center mt-6 space-x-4`}>
            {onCancel ? (
              <Button
                type="button"
                variant="secondary"
                className="w-1/2 px-8 py-3 space-x-3 font-medium text-center text-primary"
                onClick={onCancel}
              >
                Cancel
              </Button>
            ) : null}
            {onNext ? (
              <Button
                type="submit"
                className="w-1/2 px-8 py-3.5 font-medium text-center text-white transition-colors duration-200 transform rounded-md focus:outline-none bg-primary hover:bg-opacity-80"
              >
                Next
              </Button>
            ) : null}
          </div>
        ) : null}
      </Form>
    </Formik>
  );
};

export default CreateBlueprintInfo;
