import React, { useEffect, useState, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PanelCreationSteps from "./components/panel-creation-steps.component";
import { useAuthStore, useDashboardStore } from "../../store";

//Panels
import {
  BarChart,
  LineChart,
  PieChart,
  GaugeChart,
  TempChart,
  GaugeForm,
  LineChartForm,
  PieChartForm,
  BarChartForm,
  InfoGraphForm,
  HeatMapForm,
  TableForm,
  NumericInfoGraph
} from "./components/graphs";
import { chartdata, data } from "./sample-data";
import HeatMap from "./components/graphs/heatmap.component";
import useCreateHeatmapStore from "../../store/dashboard/create-heatmap.store";
import TableComponent from "./components/table/dash-table.component";
import dateService from "@services/date.service";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { useCreateDashboardPanel } from "@app/shared/hooks/post/create-panel";
import {
  // ILocationDevice,
  IMapDataConfig,
  IPanelCreatePayload
} from "@interfaces/dashboard.interface";
import { PanelDataSourceSelector } from "./components/forms/panel-data-source-selector.component";
import { useUpdateDashboardPanel } from "../shared/hooks/patch/update-panel";
import { toast } from "react-toastify";
import { Button } from "@tremor/react";
import {
  DASH_PANEL_TYPE,
  TGraphDefinitions
} from "@/interfaces/dashboard-panel.interface";
import { IServerQuery } from "../query-builder/query-builder.helper";
import DashMapComponent from "./components/map/dash-map-component";
import { GeoMapForm } from "./components/forms/geomap-form.component";
import { cloneDeep, cloneDeepWith } from "lodash";

interface IPanelType {
  name: string;
  component: React.ReactNode;
  formComponent: React.ReactNode;
}

interface IPanels {
  BAR_CHART: IPanelType;
  LINE_CHART: IPanelType;
  PIE_CHART: IPanelType;
  GAUGE_1: IPanelType;
  GAUGE_2: IPanelType;
  NUMERIC: IPanelType;
}

const INITIAL_GAUGE_STATE = {
  minThres: 120,
  maxThres: 180,
  minDisplay: 0,
  maxDisplay: 240,
  unit: "C",
  gradientEnabled: true
};

const graphDefinitions: TGraphDefinitions = {
  LINE_CHART: {
    primarySource: "data-points",
    maxParamsLength: 5,
    datatype: "number",
    hideConfigs: ["aggregate", "timeBucket"]
  },
  BAR_CHART: {
    primarySource: "data-points",
    maxParamsLength: 5,
    datatype: "number",
    hideConfigs: ["aggregate"]
  },
  PIE_CHART: {
    primarySource: "data-points",
    maxParamsLength: 1,
    datatype: "enum",
    hideConfigs: ["aggregate", "timeBucket"]
  },
  GAUGE_1: {
    primarySource: "shadows, data-points",
    maxParamsLength: 1,
    datatype: "number",
    hideConfigs: ["timeBucket", "aggregate"]
  },
  GAUGE_2: {
    primarySource: "shadows, data-points",
    maxParamsLength: 1,
    datatype: "number",
    hideConfigs: ["timeBucket", "aggregate"]
  },
  NUMERIC: {
    primarySource: "shadows, data-points",
    maxParamsLength: 1,
    datatype: "number",
    hideConfigs: ["aggregate", "timeBucket"]
  },
  HEATMAP: {
    primarySource: "shadows, data-points",
    maxParamsLength: 1,
    datatype: "number",
    hideConfigs: ["aggregate"]
  },
  TABLE: {
    primarySource: "shadows, data-points",
    maxParamsLength: 1000,
    datatype: "number",
    hideConfigs: ["parameters"]
  },
  GEO_MAP: {
    primarySource: "shadows, data-points",
    maxParamsLength: 1000,
    datatype: "number",
    hideConfigs: ["parameters"]
  }
};

function DashboardNewPanelInfo() {
  const navigate = useNavigate();
  const location = useLocation();
  const editMode = location.state?.editPanel;

  const [updateAuthUser] = useAuthStore((state) => [state.updateAuthUser]);
  const [
    activeDashboard,
    createPanelState,
    createPanelAppearance,
    clearCreatePanelState
  ] = useDashboardStore((state) => [
    state.activeDashboard,
    state.createPanelState,
    state.createPanelAppearance,
    state.clearCreatePanelState
  ]);

  const [submit, setSubmit] = useState(true);
  const [actionButtonLoading, setActionButtonLoading] = useState(false);

  const activePanel: DASH_PANEL_TYPE = useMemo(
    () =>
      editMode
        ? location.state?.panel.panel_type === DASH_PANEL_TYPE.GENERIC
          ? location.state?.panel.definition.panel_type
          : location.state?.panel.panel_type === DASH_PANEL_TYPE.GAUGE
          ? location.state?.panel.definition.options["gauge-type"] === "c"
            ? DASH_PANEL_TYPE.GAUGE_1
            : DASH_PANEL_TYPE.GAUGE_2
          : location.state?.panel.panel_type
        : createPanelState.selectedPanel,
    [createPanelState.selectedPanel, editMode, location.state?.panel]
  );
  const [panelData, setPanelData] = useState({ ...INITIAL_GAUGE_STATE });
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    if (!editMode) {
      updateAuthUser({ panelCreationStatus: "1" });
    }
  }, [editMode, updateAuthUser]);

  useEffect(() => {
    if (!createPanelState.selectedPanel && !editMode) {
      navigate(`/dashboard/new-panel-type`);
    }
  }, [navigate, createPanelState.selectedPanel, editMode]);

  const [createHeatmapInputValues, resetInputValues] = useCreateHeatmapStore(
    (state) => [state.inputValues, state.resetInputValues]
  );

  const createPanelMutation = useCreateDashboardPanel(activeDashboard?.id);
  const updatePanelMutation = useUpdateDashboardPanel(activeDashboard?.id);

  const createPanel = (payload: IPanelCreatePayload) => {
    setActionButtonLoading(true);

    createPanelMutation.mutate(payload, {
      onSuccess: (panelId) => {
        if (panelId?.length) {
          navigate(`/dashboard/${activeDashboard.id}`);
        }
      },
      onSettled: () => {
        setActionButtonLoading(false);
      }
    });
  };

  const updatePanel = (payload: IPanelCreatePayload, panelId: string) => {
    setActionButtonLoading(true);

    updatePanelMutation.mutate(
      { data: payload, panelId },
      {
        onSuccess: (panelId) => {
          if (panelId?.length) {
            navigate(`/dashboard/${activeDashboard.id}`);
            toast.success(
              "Panel Updated! Your changes might take some time to reflect."
            );
          }
        },
        onSettled: () => {
          setActionButtonLoading(false);
        }
      }
    );
  };

  const handleClick = () => {
    let definition = { title: createPanelState.panelName };
    let data_config: IServerQuery | IMapDataConfig = {};
    let reqObj: IPanelCreatePayload = {};

    // common stuff:
    const inputValues = createPanelState.inputValues;

    data_config["data_point_id"] = inputValues.dataPoints.map(
      (dp) => dp.value
    )[0];

    data_config["select"] = [];

    data_config["where"] = [];

    if (inputValues.filters.devices.length) {
      data_config["where"].push({
        device_id: {
          in: inputValues.filters.devices.map((device) => device.value)
        }
      });
    }

    reqObj["panel_type"] = activePanel;

    switch (activePanel) {
      case DASH_PANEL_TYPE.GAUGE_1:
      case DASH_PANEL_TYPE.GAUGE_2:
        definition["options"] = {
          unit: panelData.unit,
          max_thres: panelData.maxThres,
          min_thres: panelData.minThres,
          "gauge-type": activePanel === DASH_PANEL_TYPE.GAUGE_1 ? "c" : "v",
          max_display: panelData.maxDisplay,
          min_display: panelData.minDisplay,
          "gradient-enable": panelData.gradientEnabled
        };

        const data = createPanelState.inputValues.selectedParams[0];

        data_config["select"].push({
          param: data.value,
          agg: "last",
          alias: "current_value"
        });

        data_config["select"].push({
          param: data.value,
          agg: "min",
          alias: "min_value"
        });

        data_config["select"].push({
          param: data.value,
          agg: "max",
          alias: "max_value"
        });

        reqObj["panel_type"] = DASH_PANEL_TYPE.GAUGE;
        break;

      case DASH_PANEL_TYPE.LINE_CHART:
        definition["options"] = {
          labels: [chartData.map((d: any) => d.label)],
          max_thres: 256,
          min_thres: 1,
          label_colors: chartData.map((d: any) => d.color.value)
        };

        data_config["select"].push({
          param: "timestamp",
          alias: "time"
        });

        data_config["select"].push(
          ...chartData.map((line) => {
            return {
              param: line.linesParams.value,
              alias: line.label
            };
          })
        );

        break;

      case DASH_PANEL_TYPE.PIE_CHART:
        definition["options"] = {
          max_thres: 256,
          min_thres: 1,
          label_colors: [chartData.map((d: any) => d.color)]
        };

        data_config["select"].push({
          param: chartData[0].linesParams.value,
          alias: "name"
        });

        data_config["select"].push({
          param: chartData[0].linesParams.value,
          agg: "count",
          alias: "count"
        });

        data_config["where"].push({
          [chartData[0].linesParams.value]: {
            iNN: ""
          }
        });

        data_config["group"] = [chartData[0].linesParams.value];

        break;

      case DASH_PANEL_TYPE.BAR_CHART:
        definition["options"] = {
          labels: chartData.map((d: any) => d.label),
          max_thres: 256,
          min_thres: 1,
          bar_colors: chartData.map((d: any) => d.color),
          orientation: createPanelAppearance.orientation?.value ?? "horizontal"
        };

        data_config["select"].push({
          expression: {
            pattern: "time_bucket('{timeBucket}', timestamp)",
            values: {
              timeBucket:
                inputValues.timeBucket + " " + inputValues.timeBucketUnit
            }
          },
          alias: "time"
        });

        data_config["select"].push(
          ...chartData.map((bar) => {
            return {
              param: bar.barParams.value,
              alias: bar.label,
              agg: bar.aggParam
            };
          })
        );

        data_config["group"] = ["timestamp"];
        data_config["order"] = [{ timestamp: 1 }];

        break;

      case DASH_PANEL_TYPE.NUMERIC:
        definition["options"] = {
          labels: chartData.map((d: any) => d.label),
          showMin: chartData[0].showMin,
          showMax: chartData[0].showMax,
          display_unit: chartData[0].unit
        };

        chartData.forEach((data) => {
          data_config["select"].push({
            param: data.infoParams.value,
            alias: "value",
            agg: data.aggParam
          });

          if (definition["options"].showMin) {
            data_config["select"].push({
              param: data.infoParams.value,
              alias: "min",
              agg: "min"
            });
          }

          if (definition["options"].showMax) {
            data_config["select"].push({
              param: data.infoParams.value,
              alias: "max",
              agg: "max"
            });
          }
        });

        data_config["where"].push({
          timestamp: {
            lte: dateService
              .parseRelativeDate(createPanelState.inputValues.timePeriod.end)
              .format()
          }
        });

        data_config["where"].push({
          timestamp: {
            gte: dateService
              .parseRelativeDate(createPanelState.inputValues.timePeriod.start)
              .format()
          }
        });

        reqObj["panel_type"] = activePanel;
        break;

      case DASH_PANEL_TYPE.HEATMAP:
        definition["options"] = {
          ...chartData[0],
          param: createPanelState.inputValues.selectedParams[0]
        };

        const selectedParam = inputValues.selectedParams[0].value;

        data_config["select"].push({
          param: selectedParam,
          agg: chartData[0].aggregationMode,
          alias: "value"
        });

        data_config["select"].push({
          expression: {
            pattern: "time_bucket('{timeBucket}', timestamp)",
            values: {
              timeBucket:
                inputValues.timeBucket + " " + inputValues.timeBucketUnit
            }
          },
          alias: "time"
        });

        data_config["where"].push({
          timestamp: {
            lte: dateService
              .parseRelativeDate(createPanelState.inputValues.timePeriod.end)
              .format()
          }
        });

        data_config["where"].push({
          timestamp: {
            gte: dateService
              .parseRelativeDate(createPanelState.inputValues.timePeriod.start)
              .format()
          }
        });

        data_config["group"] = ["time"];
        data_config["order"] = [{ time: -1 }];
        break;

      case DASH_PANEL_TYPE.TABLE:
        definition["columnState"] = chartData[0].columnState;
        definition["panel_type"] = activePanel;

        chartData.forEach((data) => {
          if (!data.aggregateParams) {
            data_config["select"].push({
              param: "device_id",
              alias: "device_id"
            });
            data_config["select"].push(
              ...data.columns.map((col) => {
                return {
                  param: col.parameter.name,
                  alias: col.label
                };
              })
            );
          } else {
            data_config["select"].push({
              expression: {
                pattern: "time_bucket('{timeBucket}', timestamp)",
                values: {
                  timeBucket: data.timeBucket + " " + data.timeBucketUnit
                }
              },
              alias: "bucket"
            });

            data_config["select"].push({
              param: "device_id",
              alias: "device_id"
            });

            data_config["select"].push(
              ...data.columns.map((col) => {
                if (col.parameter.type === "string") {
                  return {
                    expression: {
                      pattern: "toolkit_experimental.freq_agg(0.05, {col})",
                      values: {
                        col: col.parameter.name
                      }
                    },
                    alias: col.label
                  };
                } else {
                  return {
                    param: col.parameter.name,
                    alias: col.label,
                    agg: col.aggregationMode
                  };
                }
              })
            );

            data_config["group"] = ["bucket", "device_id"];
          }

          data_config["where"].push({
            timestamp: {
              lte: dateService
                .parseRelativeDate(data.timePeriod.end)
                .toISOString()
            }
          });

          data_config["where"].push({
            timestamp: {
              gte: dateService
                .parseRelativeDate(data.timePeriod.start)
                .toISOString()
            }
          });
        });

        reqObj["panel_type"] = "GENERIC";
        break;

      case DASH_PANEL_TYPE.GEO_MAP:
        data_config = {
          config: {
            source: {
              fleet_ids: chartData[0]?.filter?.fleetIds ?? [],
              tag_ids: chartData[0]?.filter?.tagIds ?? []
            },
            min_cluster_nodes: 3,
            min_cluster_ratio: 5
          }
        };

        definition = createPanelAppearance;
        const _def = cloneDeep(createPanelAppearance);

        delete _def["shadowDefProps"];
        delete _def["filters"];
        delete _def["configuringShadowDefId"];

        reqObj["panel_type"] = DASH_PANEL_TYPE.GEO_MAP;

        break;

      default:
        break;
    }

    reqObj = {
      ...reqObj,
      title: createPanelState.panelName,
      panel_description: "A single widget with multiple tag based graph",
      definition: definition,
      data_config: data_config
    };

    if (editMode) {
      updatePanel(reqObj, location.state?.panel.id);
    } else {
      createPanel(reqObj);
    }
  };

  const handleGaugeChange = (data) => {
    setPanelData({ ...data });
  };

  const PANELS: IPanels = useMemo(
    () => ({
      LINE_CHART: {
        name: "Line Chart",
        component: (
          <LineChart
            title={createPanelState.panelName}
            chartdata={chartdata}
            categories={["Item A", "Item B", "Item C"]}
            colors={["yellow", "blue", "green"]}
            index="temp"
            showExample
          />
        ),
        formComponent: (
          <LineChartForm
            params={createPanelState.inputValues.selectedParams || []}
            onChangeHandler={(data) => setChartData(data)}
            enableCreatebutton={(flag) => setSubmit(flag)}
            initialData={location.state?.panel.definition?.options?.labels
              ?.flat()
              .map((label, ind) => ({
                linesParams: location.state?.panel.data_config["select"]
                  .filter((param) => param.alias === label)
                  .map((param) => ({
                    value: param.param,
                    label: param.param
                  }))[0],
                label,
                color: {
                  value:
                    location.state?.panel.definition?.options?.label_colors?.[
                      ind
                    ],
                  label:
                    location.state?.panel.definition?.options?.label_colors?.[
                      ind
                    ]
                },
                labelName: `lineLabel_${ind}`,
                colorName: `lineColor_${ind}`,
                paramName: `lineParam_${ind}`
              }))}
          />
        )
      },
      BAR_CHART: {
        name: "Bar Chart",
        component: (
          <BarChart
            title={createPanelState.panelName}
            chartdata={chartdata}
            categories={["Item A", "Item B", "Item C"]}
            colors={["yellow", "blue", "green"]}
            index="temp"
            showExample
          />
        ),
        formComponent: (
          <BarChartForm
            params={createPanelState.inputValues.selectedParams || []}
            onChangeHandler={(data) => setChartData(data)}
            enableCreatebutton={(flag) => setSubmit(flag)}
            initialData={location.state?.panel.definition?.options?.labels
              ?.flat()
              .map((label, ind) => ({
                barParams: location.state?.panel.data_config["select"]
                  .filter((param) => param.alias === label)
                  .map((param) => ({
                    value: param.param,
                    label: param.param
                  }))[0],
                label,
                aggParam: location.state?.panel.data_config["select"].find(
                  (param) => param.alias === label
                )?.agg,
                color:
                  location.state?.panel.definition?.options?.bar_colors?.[ind],

                labelName: `lineLabel_${ind}`,
                colorName: `lineColor_${ind}`,
                paramName: `lineParam_${ind}`
              }))}
          />
        )
      },
      PIE_CHART: {
        name: "Pie Chart",
        component: (
          <PieChart
            title={"hosts"}
            data={data}
            category="Item A"
            colors={["yellow", "blue", "green"]}
            index="hosts"
            variant="donut"
          />
        ),
        formComponent: (
          <PieChartForm
            params={createPanelState.inputValues.selectedParams || []}
            onChangeHandler={(data) => setChartData(data)}
            enableCreatebutton={(flag) => setSubmit(flag)}
            initialData={location.state?.panel.definition?.options?.label_colors?.flat()}
          />
        )
      },
      GAUGE_1: {
        name: "Gauge 1",
        component: (
          <GaugeChart
            minValue={panelData.minThres}
            maxValue={panelData.maxThres}
            min={panelData.minDisplay}
            max={panelData.maxDisplay}
            title={createPanelState.panelName}
          />
        ),
        formComponent: (
          <GaugeForm
            onChangeHandler={handleGaugeChange}
            initState={
              editMode
                ? {
                    minThres:
                      location.state?.panel.definition?.options?.min_thres,
                    maxThres:
                      location.state?.panel.definition?.options?.max_thres,
                    minDisplay:
                      location.state?.panel.definition?.options?.min_display,
                    maxDisplay:
                      location.state?.panel.definition?.options?.max_display,
                    unit: location.state?.panel.definition?.options?.unit,
                    gradientEnabled:
                      location.state?.panel.definition?.options?.[
                        "gradient-enable"
                      ]
                  }
                : INITIAL_GAUGE_STATE
            }
          />
        )
      },
      GAUGE_2: {
        name: "Gauge 2",
        component: (
          <TempChart
            title={createPanelState.panelName}
            minValue={panelData.minThres}
            maxValue={panelData.maxThres}
            min={panelData.minDisplay}
            max={panelData.maxDisplay}
            showExample
          />
        ),
        formComponent: (
          <GaugeForm
            onChangeHandler={handleGaugeChange}
            initState={
              editMode
                ? {
                    minThres:
                      location.state?.panel.definition?.options?.min_thres,
                    maxThres:
                      location.state?.panel.definition?.options?.max_thres,
                    minDisplay:
                      location.state?.panel.definition?.options?.min_display,
                    maxDisplay:
                      location.state?.panel.definition?.options?.max_display,
                    unit: location.state?.panel.definition?.options?.unit,
                    gradientEnabled:
                      location.state?.panel.definition?.options?.[
                        "gradient-enable"
                      ]
                  }
                : INITIAL_GAUGE_STATE
            }
          />
        )
      },
      NUMERIC: {
        name: "Number Infographic",
        component: (
          <NumericInfoGraph
            title={createPanelState.panelName}
            maxValue={1000}
            minValue={0}
            showEample
            info={38.9}
          />
        ),
        formComponent: (
          <InfoGraphForm
            params={createPanelState.inputValues.selectedParams || []}
            onChangeHandler={(data) => setChartData(data)}
            enableCreatebutton={(flag) => setSubmit(flag)}
            initialData={
              editMode
                ? {
                    infoParams: {
                      label: createPanelState.inputValues.selectedParams?.find(
                        (p) =>
                          p.value ===
                          createPanelState.inputValues.selectedParams?.[0]
                            ?.value
                      )?.label,
                      value:
                        location.state?.panel.data_config["select"][0].param
                    },
                    unit: location.state?.panel.definition?.options
                      ?.display_unit,
                    showMax:
                      location.state?.panel.definition?.options?.showMax,
                    showMin:
                      location.state?.panel.definition?.options?.showMin,
                    aggParam: location.state?.panel.data_config["select"].find(
                      (param) => param.alias === "value"
                    )?.agg
                  }
                : null
            }
          />
        )
      },
      HEATMAP: {
        name: "Map Demo",
        component: (
          <HeatMap
            title="heatmap"
            configValues={createHeatmapInputValues}
            showSampleHeatmap
          />
        ),
        formComponent: (
          <HeatMapForm
            params={createPanelState.inputValues.selectedParams || []}
            onChangeHandler={(data) => setChartData(data)}
            enableCreatebutton={(flag) => setSubmit(flag)}
          />
        )
      },
      TABLE: {
        name: "Table",
        component: (
          <TableComponent
            title="table"
            showSampleTable
            onChangeHandler={(data) => setChartData(data)}
          />
        ),
        formComponent: (
          <TableForm
            onChangeHandler={(data) => setChartData(data)}
            enableCreatebutton={(flag) => setSubmit(flag)}
          />
        )
      }
    }),
    [
      createPanelState.panelName,
      createPanelState.inputValues.selectedParams,
      location.state?.panel.definition?.options,
      location.state?.panel.data_config,
      panelData.minThres,
      panelData.maxThres,
      panelData.minDisplay,
      panelData.maxDisplay,
      editMode,
      createHeatmapInputValues
    ]
  );

  return (
    <main className="flex-1 mx-8 py-4 pb-0 mt-7 overflow-y-scroll space-y-4 mr-28 lg:px-8 sm:px-6 bg-background text-contentColor rounded-md">
      <div className="flex w-full">
        <div className="w-6/12 relative">
          <div className="mt-7 mb-5 w-11/12">
            <PanelCreationSteps />
          </div>
          <div>
            <h1 className="text-lg text-left font-medium mb-2.5">
              Set Panel Options
            </h1>
            <div className="relative overflow-visible flex flex-col pr-2">
              {activePanel === DASH_PANEL_TYPE.GEO_MAP ? (
                <GeoMapForm
                  onChangeHandler={(data) => setChartData(data)}
                  setCreateButtonDisabled={(flag) => setSubmit(flag)}
                />
              ) : (
                <PanelDataSourceSelector
                  onChangeHandler={setChartData}
                  enableCreatebutton={(flag) => setSubmit(flag)}
                  graphDefinitions={graphDefinitions}
                  activePanel={activePanel}
                  editPanelDataConfig={location.state?.panel.data_config}
                >
                  {PANELS[activePanel]?.formComponent ?? "no form component"}
                </PanelDataSourceSelector>
              )}
            </div>
          </div>
          <div className="w-11/12 flex items-center my-12 space-x-4">
            <button
              onClick={() => {
                if (editMode) {
                  navigate(-1);
                } else {
                  navigate("/dashboard/new-panel-type");
                }
                resetInputValues();
                clearCreatePanelState();
              }}
              className="w-1/2 px-8 py-3 space-x-3 font-medium text-center transition-colors duration-200 transform border rounded-md focus:outline-none text-primary border-primary hover:bg-background-layer1"
            >
              Back
            </button>

            <Button
              onClick={handleClick}
              loading={actionButtonLoading}
              disabled={submit}
              className={`w-1/2 px-8 py-3 font-medium text-center !text-white transition-colors duration-200 transform rounded-md focus:outline-none   ${
                !submit
                  ? "hover:bg-opacity-80 bg-primary"
                  : "bg-gray-500 cursor-not-allowed"
              }`}
            >
              {editMode ? "Update" : "Create"}
            </Button>
          </div>
        </div>

        <div className="w-6/12">
          <div className="ml-8 mt-7 bg-background-layer0.5 rounded relative">
            <div className="px-5 py-7 mb-7 relative">
              <div className="flex items-center">
                <InformationCircleIcon className="w-5 h-5 text-primaryLight" />

                <h1 className="font-medium text-sm ml-3.5 text-primaryLight">
                  Example Chart
                </h1>
              </div>
              <div className=" flex justify-center items-center">
                <div className="w-[95%] flex pb-2 flex-col bg-background-layer1 my-5 rounded-md">
                  {PANELS[activePanel]?.component ?? null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default DashboardNewPanelInfo;
