import { useMutation, useQueryClient } from "react-query";

import networkService from "@services/network.service";
import { useAuthStore } from "@store/index";

export interface IAddUsersToGroupPayload {
  user_ids: string[];
  group_id: string;
}

interface IAddUsersToGroupResponse {
  ok: number;
}

export const addUsersToGroup = async (
  groupId: string,
  orgId: string,
  payload: IAddUsersToGroupPayload
) => {
  const { ok } = await networkService.post<IAddUsersToGroupResponse>(
    `organizations/groups/${groupId}/users`,
    payload,
    {
      headers: {
        "ORG-ID": orgId
      }
    }
  );

  return ok;
};

export const useAddUsersToGroup = (groupId: string) => {
  const [user] = useAuthStore((state) => [state.user]);
  const queryClient = useQueryClient();

  const res = useMutation(
    async (data: IAddUsersToGroupPayload) =>
      await addUsersToGroup(groupId, user.selectedOrg.id, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getUserGroupDetails");
      }
    }
  );

  return res;
};
