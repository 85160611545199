import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import useFleetAndDevicesStore from "@store/fleet-and-devices/fleet-and-devices.store";
import dateService from "@services/date.service";
import { useConfirmDelete } from "../../shared/hooks/use-confirm-delete.hooks";
import ShowLoading from "@app/shared/components/loading.component";
import { useGetFleetDevices } from "@app/shared/hooks/get/fleet-devices";
import CreateDevice from "../fleet/fad-create-device.component";
import { useDeleteDeviceMutation } from "@app/shared/hooks/delete/delete-device";
import { toast } from "react-toastify";
import Pagination from "@app/shared/components/pagination.component";
import {
  useReactTable,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getPaginationRowModel,
  getSortedRowModel,
  ColumnDef,
  flexRender,
  getFilteredRowModel
} from "@tanstack/react-table";
import {
  ArrowDownIcon,
  ArrowUpIcon,
  ArrowsUpDownIcon,
  Bars3Icon,
  TrashIcon,
  XMarkIcon
} from "@heroicons/react/24/outline";
import { Badge, Button } from "@tremor/react";
import { IDevice, IOption } from "@/interfaces";
import { Tooltip } from "react-tooltip";
import { useGetTags } from "@/app/shared/hooks/get/tags";
import ReactSelect from "react-select";
import { Unauthorized } from "@/app/shared/components";
import ShowError from "@/app/shared/components/error.component";

const initialFilterState = {
  device_name: false,
  tags: false
};

function RenderDevices() {
  const [searchParams, setSearchParams] = useSearchParams();
  const searchFilterTags = searchParams.get("tag")?.split(",");

  const [selectedFleet] = useFleetAndDevicesStore((state) => [
    state.selectedFleet
  ]);

  const { openConfirmDeleteModal } = useConfirmDelete();

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [filterTags, setFilterTags] = useState<IOption[]>([]);

  const {
    data: devicesResponse,
    isFetching,
    isLoading,
    status,
    error: devicesErr,
    isError: devicesIsError
  } = useGetFleetDevices(selectedFleet.id, {
    fields: "tags",
    page,
    limit: pageSize,
    tag_ids: filterTags?.map((opt) => opt.value)?.join(",")
  });

  const {
    data: tags,
    error: tagsError,
    isError: tagsIsError
  } = useGetTags({}, (tags) => {
    if (searchFilterTags?.length) {
      setFilterTags(
        searchFilterTags.map((tagName) => {
          const _tag = tags.find((t) => t.name === tagName);
          return {
            value: _tag.id,
            label: _tag.name
          };
        })
      );
    }
  });

  const tagsOptions: IOption[] = useMemo(() => {
    return !tags
      ? []
      : tags.map((tag) => ({ label: tag.name, value: tag.id }));
  }, [tags]);

  const deleteDeviceMutation = useDeleteDeviceMutation(selectedFleet.id);

  const deleteDevice = useCallback(
    async (deviceId: string) => {
      deleteDeviceMutation.mutate(deviceId, {
        onSuccess: (ok) => {
          if (ok) {
            toast.success("Deleted device successfully!");
          }
        }
      });
    },
    [deleteDeviceMutation]
  );

  useEffect(() => {
    setPage(1);
  }, [selectedFleet?.id]);

  const columns = useMemo<ColumnDef<IDevice, any>[]>(
    () => [
      {
        id: "device_name",
        accessorKey: "device_name",
        header: "Device Name",
        enableColumnFilter: false,
        filterFn: "includesString",
        cell: ({ getValue, row }) => {
          return (
            <Link
              to={{
                pathname: "/fleet-and-devices/projects/device-details",
                search: `?code=${row.original.id}` // inject code value into template
              }}
            >
              {getValue()}
            </Link>
          );
        },
        size: 30
      },
      {
        id: "connected",
        accessorKey: "connected",
        enableColumnFilter: false,
        enableSorting: false,
        header: "connected",
        cell: ({ getValue, row }) => {
          return getValue() === null ? (
            <Badge size="xs" color="yellow" className="!text-xs">
              {"Never Connected"}
            </Badge>
          ) : getValue() === false ? (
            <Badge
              data-tooltip-id="disconnected-tooltip"
              data-tooltip-content={`Last seen:
                ${dateService.convertUTCToLocalDate(row.original.last_seen)}`}
              size="xs"
              color="red"
              className="!text-xs"
            >
              {"Disconnected"}
            </Badge>
          ) : (
            <Badge size="xs" color="green" className="!text-xs">
              {"Connected"}
            </Badge>
          );
        },
        size: 30
      },
      {
        id: "tags",
        header: "Tags",
        accessorKey: "tags",
        enableSorting: false,
        enableColumnFilter: true,
        filterFn: "includesString",
        cell: ({ getValue }) => {
          return (
            <div className="flex flex-row items-center gap-2 flex-wrap ">
              {getValue()?.map((tagId: string) => {
                const tag = tags?.find((t) => t.id === tagId);
                if (!tag) return null;
                return (
                  <Badge
                    key={tagId}
                    size="xs"
                    color="slate"
                    onClick={() => {
                      if (!filterTags.some((t) => t.value === tag.id)) {
                        const newFilterTags = [
                          ...filterTags,
                          { label: tag.name, value: tag.id }
                        ];
                        setFilterTags(newFilterTags);
                        setSearchParams({
                          tag: newFilterTags.map((opt) => opt.label).join(",")
                        });
                      }
                    }}
                    className="!text-xs cursor-pointer"
                  >
                    {tag.name}
                  </Badge>
                );
              })}
            </div>
          );
        },
        minSize: 200,
        size: 40,
        maxSize: 200
      },
      {
        id: "delete",
        accessorKey: null,
        header: "Delete",
        enableSorting: false,
        enableColumnFilter: false,
        filterFn: "includesString",
        cell: ({ getValue, row }) => {
          return (
            <Button
              icon={TrashIcon}
              variant="light"
              color="red"
              onClick={() =>
                openConfirmDeleteModal(
                  () => deleteDevice(row.original.id),
                  "Are you sure you want to delete this device?"
                )
              }
              type="button"
              aria-label="delete device"
            />
          );
        },
        size: 40
      }
    ],
    [filterTags, tags]
  );

  const [filteringColumns, setFilteringColumns] =
    useState<Record<string, boolean>>(initialFilterState);

  const data = useMemo(
    () =>
      Array.isArray(devicesResponse?.devices) ? devicesResponse?.devices : [],
    [devicesResponse?.devices]
  );

  const table = useReactTable({
    data: data || [],
    columns,
    state: {
      pagination: {
        pageIndex: devicesResponse?.meta?.currentPage ?? 0,
        pageSize: devicesResponse?.meta?.itemsPerPage ?? 0
      }
    },
    manualPagination: true,
    pageCount: devicesResponse?.meta?.totalPages ?? 0,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues()
  });

  if (isFetching || isLoading || status === "loading") {
    return <ShowLoading />;
  }

  if (devicesIsError || tagsIsError) {
    const msg = (devicesErr as any)?.message || (tagsError as any)?.message;
    if (msg === "Missing sufficient permissions") {
      return <Unauthorized />;
    } else {
      return <ShowError />;
    }
  }

  return devicesResponse?.devices?.length ? (
    <main className="flex-1 px-4 py-8 pt-5 space-y-4 overflow-y-auto lg:px-8 sm:px-6">
      <h1 className="text-2xl font-medium text-contentColor">Devices</h1>
      <hr className="border-background-layer3" />
      <div className="flex flex-col mt-12">
        <div className="-my-2 overflow-x-auto xl:-mx-8 px-4 lg:px-8">
          <div className="inline-block min-w-full py-2 align-middle">
            <div className="overflow-hidden">
              {devicesResponse?.devices ? (
                <>
                  <table className="w-full h-full my-6 ">
                    <thead className="">
                      {table.getHeaderGroups().map((headerGroup) => (
                        <tr key={headerGroup.id}>
                          {headerGroup.headers.map((header) => {
                            return (
                              <th
                                key={header.id}
                                colSpan={header.colSpan}
                                style={{
                                  width: header.column.getSize()
                                }}
                                className="px-2 py-2 text-xs text-center uppercase text-contentColorLight font-normal"
                              >
                                {header.isPlaceholder ? null : (
                                  <>
                                    <div className="select-none flex items-center justify-center gap-2">
                                      {flexRender(
                                        <div className="flex items-center gap-2">
                                          {
                                            header.column.columnDef
                                              .header as ReactNode
                                          }
                                          {header.column.id === "tags" &&
                                          filterTags?.length ? (
                                            <div className="flex flex-col gap-1">
                                              {filterTags.map((tag) => (
                                                <Badge
                                                  size="xs"
                                                  icon={XMarkIcon}
                                                  onClick={() => {
                                                    const newFilterTags =
                                                      filterTags.filter(
                                                        (t) =>
                                                          t.value !== tag.value
                                                      );
                                                    setFilterTags(
                                                      newFilterTags
                                                    );
                                                    setSearchParams(
                                                      newFilterTags.join(",")
                                                    );
                                                    setFilteringColumns(
                                                      (prev) => {
                                                        return {
                                                          ...prev,
                                                          [header.column.id]:
                                                            false
                                                        };
                                                      }
                                                    );
                                                  }}
                                                  className="cursor-pointer"
                                                >
                                                  <span className="!text-xs">
                                                    {tag.label}
                                                  </span>
                                                </Badge>
                                              ))}
                                            </div>
                                          ) : null}
                                        </div>,
                                        header.getContext()
                                      )}
                                      {{
                                        asc: (
                                          <ArrowUpIcon
                                            width={16}
                                            className="min-w-[16px] cursor-pointer"
                                            onClick={header.column.getToggleSortingHandler()}
                                          />
                                        ),
                                        desc: (
                                          <ArrowDownIcon
                                            width={16}
                                            className="min-w-[16px] cursor-pointer"
                                            onClick={header.column.getToggleSortingHandler()}
                                          />
                                        )
                                      }[
                                        header.column.getIsSorted() as string
                                      ] || null}
                                      {header.column.getCanSort() &&
                                      !header.column.getIsSorted() ? (
                                        <ArrowsUpDownIcon
                                          width={16}
                                          className="min-w-[16px] cursor-pointer"
                                          onClick={header.column.getToggleSortingHandler()}
                                        />
                                      ) : null}
                                      {header.column.getCanFilter() ? (
                                        <>
                                          <div
                                            className="cursor-pointer"
                                            data-tooltip-id={
                                              "devices-table-filter-" +
                                              header.column.id
                                            }
                                            onClick={(e) => {
                                              setFilteringColumns((prev) => {
                                                return {
                                                  ...prev,
                                                  [header.column.id]: true
                                                };
                                              });
                                            }}
                                          >
                                            <Bars3Icon
                                              width={16}
                                              className="min-w-[16px]"
                                            />
                                          </div>
                                          <Tooltip
                                            isOpen={true}
                                            id={
                                              "devices-table-filter-" +
                                              header.column.id
                                            }
                                            style={{
                                              zIndex: 30,
                                              display: filteringColumns[
                                                header.column.id
                                              ]
                                                ? undefined
                                                : "none"
                                            }}
                                            openOnClick
                                            variant="light"
                                            border={"1px solid black"}
                                            clickable
                                            render={(props) => {
                                              return (
                                                <div className="relative min-w-[200px]">
                                                  <ReactSelect
                                                    isClearable
                                                    isMulti
                                                    value={filterTags}
                                                    options={tagsOptions}
                                                    onChange={(
                                                      opts: IOption[]
                                                    ) => {
                                                      setFilterTags(opts);
                                                      if (opts?.length) {
                                                        setSearchParams({
                                                          tag: opts
                                                            .map(
                                                              (opt) =>
                                                                opt.value
                                                            )
                                                            .join(",")
                                                        });
                                                      } else {
                                                        setSearchParams({});
                                                        setFilteringColumns(
                                                          (prev) => {
                                                            return {
                                                              ...prev,
                                                              [header.column
                                                                .id]: false
                                                            };
                                                          }
                                                        );
                                                      }
                                                    }}
                                                    placeholder={`Search tags`}
                                                  />
                                                  <XMarkIcon
                                                    className="absolute -top-1 -right-3 cursor-pointer"
                                                    width={12}
                                                    onClick={(e) => {
                                                      e.stopPropagation();
                                                      e.preventDefault();
                                                      setFilteringColumns(
                                                        (prev) => {
                                                          return {
                                                            ...prev,
                                                            [header.column.id]:
                                                              false
                                                          };
                                                        }
                                                      );
                                                    }}
                                                  />
                                                </div>
                                              );
                                            }}
                                          />
                                        </>
                                      ) : null}
                                    </div>
                                  </>
                                )}
                              </th>
                            );
                          })}
                        </tr>
                      ))}
                    </thead>
                    <tbody className="rounded-md">
                      {table.getRowModel().rows.map((row, ind) => {
                        return (
                          <tr
                            key={row.id}
                            className={`bg-background ${
                              ind === 0
                                ? "!rounded-t-md"
                                : ind === table.getTotalSize() - 1
                                ? "!rounded-b-md"
                                : ""
                            }`}
                          >
                            {row.getVisibleCells().map((cell, cellInd) => {
                              return (
                                <td
                                  key={cell.id}
                                  className={`mx-2 whitespace-nowrap !bg-transparent text-sm text-center p-2 py-3 ${
                                    ind === 0 && cellInd === 0
                                      ? "rounded-tl-md"
                                      : ind === table.getTotalSize() - 1 &&
                                        cellInd === 0
                                      ? "rounded-bl-md"
                                      : ind === 0 &&
                                        cellInd === columns.length - 1
                                      ? "rounded-tr-md"
                                      : ind === table.getTotalSize() - 1 &&
                                        cellInd === columns.length - 1
                                      ? "rounded-br-md"
                                      : ""
                                  }`}
                                  style={{
                                    width: cell.column.getSize()
                                  }}
                                >
                                  {flexRender(
                                    cell.column.columnDef.cell,
                                    cell.getContext()
                                  )}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  {devicesResponse?.meta ? (
                    <Pagination
                      setPage={setPage}
                      setPageSize={setPageSize}
                      meta={devicesResponse?.meta}
                    />
                  ) : null}
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <Tooltip
        id="disconnected-tooltip"
        variant="light"
        border={"1px solid black"}
        offset={24}
      />
    </main>
  ) : (
    <CreateDevice />
  );
}

export default RenderDevices;
