import ShowLoading from "@/app/shared/components/loading.component";
import NoData from "@/app/shared/components/no-data.component";
import { useRemoveUsersFromGroup } from "@/app/shared/hooks/delete/remove-users-from-group";
import { useGetUserGroupsDetails } from "@/app/shared/hooks/get/user-groups-details";
import { useGetUsersInOrg } from "@/app/shared/hooks/get/users-in-org";
import { useAddUsersToGroup } from "@/app/shared/hooks/post/add-users-to-group";
import { reactSelectClassNames } from "@/app/shared/utils/helper.util";
import { IOption } from "@/interfaces";
import { IUserGroup } from "@/interfaces/user-groups.interface";
import {
  ArrowLeftIcon,
  CheckIcon,
  TrashIcon
} from "@heroicons/react/24/outline";
import { Button } from "@tremor/react";
import React, { useMemo, useState } from "react";
import ReactSelect from "react-select";
import { toast } from "react-toastify";
import CreateExternalUserForm from "./create-external-user-form.component";
import { useCreateAuthUser } from "@/app/shared/hooks/post/create-custom-auth-user";
import { useDeleteCustomAuthUser } from "@/app/shared/hooks/delete/delete-custom-auth-user";

type IEditUsersFormProps = {
  selectedGroup: IUserGroup;
  overlayOpen: boolean;
};

const EditUserForm: React.FC<IEditUsersFormProps> = ({
  selectedGroup,
  overlayOpen
}) => {
  const [showCreateUser, setshowCreateUser] = useState(false);
  const [selectedUsersToAdd, setSelectedUsersToAdd] = useState<IOption[]>([]);

  const { data: usersInOrg, isLoading: usersInOrgLoading } =
    useGetUsersInOrg();
  const { data: groupDetails, isLoading: groupDetailsLoading } =
    useGetUserGroupsDetails(overlayOpen ? selectedGroup?.id : "", {
      load_users: true
    });

  const addUsersToGroupMutation = useAddUsersToGroup(selectedGroup?.id);
  const removeUsersFromGroupMutation = useRemoveUsersFromGroup(
    selectedGroup?.id
  );

  const createCustomAuthUserMutation = useCreateAuthUser(selectedGroup?.id);
  const deleteCustomAuthUserMutation = useDeleteCustomAuthUser(
    selectedGroup?.id
  );

  const filteredAddUsersOptions = useMemo(() => {
    return usersInOrg
      ?.filter(
        (user) =>
          !groupDetails?.users?.some(
            (groupUser) => groupUser.email === user.email
          ) &&
          !selectedUsersToAdd.map((user) => user.value).includes(user.user_id)
      )
      ?.map((user) => ({
        value: user.user_id,
        label: user.email
      }));
  }, [groupDetails?.users, selectedUsersToAdd, usersInOrg]);

  const onAddUsers = () => {
    addUsersToGroupMutation.mutate(
      {
        user_ids: selectedUsersToAdd.map((user) => user.value),
        group_id: selectedGroup.id
      },
      {
        onSuccess: () => {
          setSelectedUsersToAdd([]);
          toast.success("Users added successfully");
        }
      }
    );
  };

  const onRemoveUsers = (userIds: string[]) => {
    if (groupDetails?.external_auth_org) {
      // FIXME: Handle multiple users
      deleteCustomAuthUserMutation.mutate(userIds[0], {
        onSuccess: () => {
          toast.success("User removed successfully");
        }
      });
    } else {
      removeUsersFromGroupMutation.mutate(
        {
          user_ids: userIds.join(",")
        },
        {
          onSuccess: () => {
            toast.success("Users removed successfully");
          }
        }
      );
    }
  };

  const onCreateCustomAuthUser = (data) => {
    createCustomAuthUserMutation.mutate(data, {
      onSuccess: () => {
        toast.success("User created successfully");
      }
    });
  };

  if (usersInOrgLoading || groupDetailsLoading) {
    return <ShowLoading />;
  }

  return (
    <div className="w-full">
      {groupDetails?.external_auth_org !== null ? (
        !showCreateUser ? (
          <div className="flex flex-col gap-2 justify-end">
            <h4>Create an external auth user and add to group</h4>
            <Button onClick={() => setshowCreateUser(!showCreateUser)}>
              Create User
            </Button>
          </div>
        ) : (
          <div className="flex items-start flex-col">
            <Button
              onClick={() => setshowCreateUser(!showCreateUser)}
              variant="light"
              icon={ArrowLeftIcon}
              className="text-primary"
            >
              Back
            </Button>
            <div className="w-full text-center">
              <h2 className="text-xl font-semibold">Create User</h2>
            </div>
          </div>
        )
      ) : (
        <>
          <h2>Add Users</h2>
          <div className="flex gap-2 w-full">
            <ReactSelect
              isMulti
              options={filteredAddUsersOptions}
              value={selectedUsersToAdd}
              onChange={(selected: IOption[]) => {
                setSelectedUsersToAdd(selected);
              }}
              className="flex-grow"
              classNames={reactSelectClassNames}
            />
            <Button
              icon={CheckIcon}
              loading={addUsersToGroupMutation.isLoading}
              color="green"
              onClick={onAddUsers}
              className="flex-shrink self-end"
            />
          </div>
        </>
      )}
      {showCreateUser ? (
        <CreateExternalUserForm
          onSubmit={onCreateCustomAuthUser}
          error={createCustomAuthUserMutation.error as string}
          loading={createCustomAuthUserMutation.isLoading}
        />
      ) : (
        <>
          <hr className="border-t border-background-layer3 mt-6 mb-4" />
          <div className="flex flex-col items-center gap-2 max-h-fit overflow-auto">
            <span className="text-xl font-semibold">Users</span>
            {groupDetails?.users?.length ? (
              groupDetails.users.map((user) => (
                <div
                  key={user.user_id}
                  className="flex items-center justify-between gap-2 w-full p-4 rounded-md bg-background-layer1"
                >
                  <div className="flex items-center gap-2">
                    <span className="text-sm">{user.email}</span>
                  </div>

                  <Button
                    icon={TrashIcon}
                    loading={removeUsersFromGroupMutation.isLoading}
                    variant="light"
                    color="red"
                    onClick={() => {
                      onRemoveUsers([user.user_id]);
                    }}
                  />
                </div>
              ))
            ) : (
              <NoData msg="No users in group" />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default EditUserForm;
