import { useQuery } from "react-query";

import networkService from "@services/network.service";
import { useAuthStore, useFleetAndDevicesStore } from "@store/index";
import { IFleetDeviceBlueprint } from "@/interfaces/device-blueprint.interface";

interface IFleetTemplateResponse {
  ok: number;
  data: {
    fleetDeviceTemplates: IFleetDeviceBlueprint[];
  };
}

export const getFleetDeviceBlueprints = async (
  projectId: string,
  orgId: string,
  fleetId: string,
  params: any
) => {
  const { data, ok } = await networkService.get<IFleetTemplateResponse>(
    `projects/${projectId}/fleets/${fleetId}/device_templates`,
    params,
    {
      headers: {
        "ORG-ID": orgId
      }
    }
  );

  if (ok) {
    return data.fleetDeviceTemplates;
  } else return null;
};

export const useGetFleetDeviceBlueprints = (
  fleetId: string,
  params: any = {},
  successCb: ((data: IFleetDeviceBlueprint[]) => void) | null = null
) => {
  const [selectedProject] = useFleetAndDevicesStore((state) => [
    state.selectedProject
  ]);
  const [user] = useAuthStore((state) => [state.user]);
  const res = useQuery(
    [
      "getFleetDeviceBlueprints",
      selectedProject.id,
      user.selectedOrg.id,
      fleetId,
      params
    ],
    async () => {
      const fleetTemplates = await getFleetDeviceBlueprints(
        selectedProject.id,
        user.selectedOrg.id,
        fleetId,
        params
      );

      successCb && successCb(fleetTemplates);

      return fleetTemplates;
    },
    {
      enabled: !!selectedProject.id && !!user.selectedOrg.id && !!fleetId
    }
  );

  return res;
};
