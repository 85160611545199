import { NavLink } from "react-router-dom";

function ProjectNavigation() {
  return (
    <div className="px-4 pt-6 bg-background border-background-layer3 border-b lg:px-8 sm:px-6 ">
      <div className=" md:grid md:gap-4 md:grid-cols-5 md:place-items-start">
        <NavLink
          to="/settings/project-settings/project-details"
          className={({ isActive }) =>
            `${
              isActive ? "text-primary border-b-2 border-primaryLight" : ""
            } flex items-center px-2 py-5 -mb-px text-center text-contentColorLight lg:px-4 whitespace-nowrap`
          }
        >
          {({ isActive }) => (
            <div className="flex flex-row justify-between items-center">
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_1254_21407)">
                  <path
                    d="M15 1.5C15.414 1.5 15.75 1.836 15.75 2.25V5.06775L14.25 6.56775V3H3.75V15H14.25V12.9315L15.75 11.4315V15.75C15.75 16.164 15.414 16.5 15 16.5H3C2.586 16.5 2.25 16.164 2.25 15.75V2.25C2.25 1.836 2.586 1.5 3 1.5H15ZM16.3335 6.606L17.394 7.6665L11.5605 13.5L10.4985 13.4985L10.5 12.4395L16.3335 6.606V6.606ZM9.75 9V10.5H6V9H9.75ZM12 6V7.5H6V6H12Z"
                    fill={isActive ? "#546CCC" : "#565759"}
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1254_21407">
                    <rect width="18" height="18" fill="white" />
                  </clipPath>
                </defs>
              </svg>

              <span
                className={`${
                  isActive && "text-primaryLight font-medium"
                } text-sm ml-2`}
              >
                Project Details
              </span>
            </div>
          )}
        </NavLink>
      </div>
    </div>
  );
}

export default ProjectNavigation;
