import { useMemo } from "react";
import SettingsGenericTable from "../components/settings-generic-table.component";
import useSettingsStore from "@store/settings/settings.store";
import CreateInvite from "../components/settings-org-create-invite-form.component";
import dateService from "@services/date.service";
import { useGetInvites } from "@app/shared/hooks/get/invites";
import { Button } from "@tremor/react";
import { TrashIcon, UserPlusIcon } from "@heroicons/react/24/outline";
import { useDeleteInvite } from "@app/shared/hooks/delete/delete-invite";
import { useConfirmDelete } from "@app/shared/hooks/use-confirm-delete.hooks";

function Invites() {
  const [invites, setInvites, setShowCreateInviteForm] = useSettingsStore(
    (state) => [state.invites, state.setInvites, state.setShowCreateInviteForm]
  );

  useGetInvites({}, (invites) => {
    if (invites) {
      setInvites(invites);
    } else {
      setInvites([]);
    }
  });

  const deleteInviteMutation = useDeleteInvite();
  const { openConfirmDeleteModal } = useConfirmDelete();

  const renderStatus = (status: string) => {
    const statusUI = {
      admin: (
        <span className="flex">
          <span className="bg-[#25a96a36] py-2 px-5 rounded text-[#25A96A] font-medium text-xs mr-6">
            ADMIN
          </span>
        </span>
      ),
      viewer: (
        <span className="flex">
          <span className="bg-[#3659997d] py-2 px-[17px] rounded text-[#4281ff] font-medium text-xs mr-6">
            VIEWER
          </span>
        </span>
      ),
      member: (
        <span className="flex">
          <span className="bg-[#166ff629] py-2 px-[17px] rounded text-[#1366E5] font-medium text-xs mr-6">
            MEMBER
          </span>
        </span>
      )
    };

    return statusUI[status.toLowerCase()];
  };

  const collaborators = useMemo(() => {
    if (invites.length <= 0) return [];
    return invites.map((invite) => {
      const collaborator = invite?.email ? invite?.email[0] : "N/A";
      return {
        collaborator,
        org_access: renderStatus(invite?.org_access),
        created_at: dateService.convertUTCToLocalDate(invite?.created_at),
        times_used: invite?.times_used,
        revoke: (
          <Button
            variant="light"
            icon={TrashIcon}
            color="red"
            disabled={invite.times_used >= invite.limit}
            tooltip={
              invite.times_used >= invite.limit
                ? "Invite token already exhausted"
                : "Revoke Access"
            }
            onClick={() => {
              openConfirmDeleteModal(() => {
                deleteInviteMutation.mutate(invite.id);
              }, "Revoke Access to this invite token?");
            }}
          />
        )
      };
    });
  }, [deleteInviteMutation, invites, openConfirmDeleteModal]);

  return (
    <>
      <CreateInvite />
      <div className="flex flex-col mt-4 w-full max-h-[70vh] bg-background text-contentColor pb-6">
        <div className="flex justify-end">
          <Button
            icon={UserPlusIcon}
            onClick={() => setShowCreateInviteForm(true)}
            className="uppercase mb-4 !text-white"
          >
            Invite collaborator
          </Button>
        </div>
        <div className="overflow-auto">
          {!!collaborators?.length && (
            <SettingsGenericTable
              column={Object.keys(collaborators[0])}
              row={collaborators}
              searchField={
                collaborators.length ? Object.keys(collaborators[0])[0] : ""
              }
              searchTerm=""
            />
          )}
        </div>
      </div>
    </>
  );
}

export default Invites;
