import { useMutation, useQueryClient } from "react-query";
import networkService from "@services/network.service";
import { useAuthStore, useFleetAndDevicesStore } from "@store/index";

interface IDuplicateProjectPayload {
  project_body: {
    project_name: string;
    region: string;
    hosting_platform: string;
  };
}

interface IDuplicateProjectResponse {
  data: {
    newProjectId: string;
  };
  ok: number;
}

export const duplicateProject = async (
  projId: string,
  orgId: string,
  payload: IDuplicateProjectPayload
) => {
  const { data, ok } = await networkService.post<IDuplicateProjectResponse>(
    `projects/${projId}/duplicate`,
    payload,
    {
      headers: {
        "ORG-ID": orgId
      }
    }
  );

  if (ok) {
    return data.newProjectId;
  } else return null;
};

export const useDuplicateProject = () => {
  const [user] = useAuthStore((state) => [state.user]);
  const [selectedProject] = useFleetAndDevicesStore((state) => [
    state.selectedProject
  ]);
  const queryClient = useQueryClient();

  const res = useMutation(
    async (data: IDuplicateProjectPayload) =>
      await duplicateProject(selectedProject.id, user.selectedOrg.id, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getProjects");
      }
    }
  );

  return res;
};
