import { useQuery } from "react-query";

import networkService from "@services/network.service";
import { useAuthStore, useFleetAndDevicesStore } from "@store/index";
import {
  IProjectApp,
  IProjectAppDetailsResponse
} from "@interfaces/apps.interface";

export const getAppDetails = async (appId: string, orgId: string, params) => {
  const { data, ok } = await networkService.get<IProjectAppDetailsResponse>(
    `organizations/apps/${appId}`,
    params,
    {
      headers: {
        "ORG-ID": orgId
      }
    }
  );

  if (ok) {
    return data;
  } else return null;
};

export const useGetAppDetails = (
  appId: string,
  params: any = {},
  successCb: ((data: IProjectApp) => void) | null = null
) => {
  const [selectedProject] = useFleetAndDevicesStore((state) => [
    state.selectedProject
  ]);
  const [user] = useAuthStore((state) => [state.user]);
  const res = useQuery(
    ["getAppDetails", appId, selectedProject.id, user.selectedOrg.id, params],
    async () => {
      const apps = await getAppDetails(appId, user.selectedOrg.id, params);

      successCb && successCb(apps);

      return apps;
    },
    {
      enabled: !!selectedProject.id && !!user.selectedOrg.id && !!appId
    }
  );

  return res;
};
