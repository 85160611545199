import _ from "lodash";
import React, { useEffect, useState, useRef } from "react";
import {
  useAuthStore,
  useDashboardStore,
  useFleetAndDevicesStore
} from "@store/index";
import { useLocation } from "react-router-dom";
//graph helpers
import {
  IGaugeDefination,
  ILineDefination,
  IMapDefination,
  IPieDefinations
} from "@interfaces/index";
import GraphHeader from "./components/graphs/dash-graph-header.component";
import {
  generateGraphData,
  getInfographicSum
} from "./components/graphs/graph.helper";
//graphs
import Gauge from "./components/gauge/gauge";
import { PieChart, LineChart, BarChart } from "./components/graphs";
import IndoorMapView from "./components/map/dash-indoor-map.component";
import TempMeter from "./components/gauge/temp-meter";
import NumericInfoGraph from "./components/graphs/dash-numeric-graph.component";
import dateService from "@services/date.service";
import HeatMap from "./components/graphs/heatmap.component";
import { PanelErrorProvider } from "../shared/utils/dash-panel-error.context";
import TableComponent from "./components/table/dash-table.component";
import RidePath from "./components/map/dash-map-location.component";
import { isObject } from "@app/shared/utils/helper.util";
import DashMapEnergyComponent from "./components/map/dash-map-energy.component";
import { useGetDashboardPanelData } from "@app/shared/hooks/get/panel-data";
import { getShadow } from "@app/shared/hooks/get/shadow";

interface IPanelWrapperProps {
  panel: ILineDefination | IPieDefinations | IGaugeDefination | IMapDefination;
  inCarousel?: boolean;
  handlePanelError?: (
    panelId: string,
    panelTitle: string,
    error: string
  ) => void;
  geoMapAdditionalInfo?: any;
  setGeoMapAdditionalInfo?: any;
}

const dontRenderPanelIds = [
  "a86fc472-c8f6-4bb6-835a-e8d97c2c3076",
  "c8dd5768-fddb-4307-adf8-3dc19585bc26",
  "bfd50cc7-e2d7-4758-945f-c2fc4d909f7c"
];

const TIMESTAMP_FORMAT = "DD/MM hh:mm:ss A";

const PanelWrapper: React.FC<IPanelWrapperProps> = React.forwardRef<
  HTMLDivElement,
  IPanelWrapperProps
>(
  (
    {
      panel,
      inCarousel = false,
      handlePanelError,
      geoMapAdditionalInfo,
      setGeoMapAdditionalInfo
    },
    ref
  ) => {
    const location = useLocation();

    // for map panel:
    const deviceShadows = useRef({});
    const deviceShadowDefs = useRef({});
    const [panelError, setPanelError] = useState<string>("");

    const stoppedDataFetchPanelsRef = useRef<{
      [panelId: string]: boolean;
    }>({});

    const [user] = useAuthStore((state) => [state.user]);
    const [selectedProject] = useFleetAndDevicesStore((state) => [
      state.selectedProject
    ]);
    const [activeDashboard, activeFilter, editingLayout] = useDashboardStore(
      (state) => [
        state.activeDashboard,
        state.activeFilter,
        state.editingLayout
      ]
    );

    const boundingBox = useRef([
      73.7115888365174, 18.68774689473389, 73.97315825630326, 18.44895304892448
    ]);

    const duration = formatDuration(activeFilter.span);

    let start = dateService
      .getCurrentUTCDate()
      .subtract(parseInt(activeFilter.span), duration)
      .format();
    if (
      panel.panel_type === "LINE_CHART" &&
      panel.definition.meta?.type === "Info"
    ) {
      start = dateService.getCurrentUTCDate().subtract(24, "hours").format();
    }

    let stop = dateService.getCurrentUTCDate().format();

    let params = { start, stop };

    if (panel.panel_type === "GEO_MAP") {
      params["bounding_box"] = boundingBox.current.join(",");
    }

    // ---------- EV Infinity panels ---------------- //
    if (panel.panel_type === "GENERIC" && "panel_type" in panel.definition) {
      if (
        ["NUMERIC", "GEO_MAP_LOCATION"].includes(panel.definition.panel_type)
      ) {
        if (panel.definition.panel_type === "NUMERIC") {
          if (panel.title === "Active Bookings") {
            params["where"] = JSON.stringify([
              {
                timestamp: {
                  gte: dateService
                    .getCurrentUTCDate()
                    .set({ hour: 0, minute: 0, second: 0 })
                    .subtract(2, "days")
                    .toISOString()
                }
              }
            ]);
          }
          if (panel.id === "abd491dd-7a83-4ea9-a8d6-f779a7510268") {
            params["where"] = JSON.stringify([
              {
                timestamp: {
                  gte: dateService
                    .getCurrentUTCDate()
                    .subtract(1, "month")
                    .toISOString()
                }
              }
            ]);
          }
        } else {
          // params["where"] = JSON.stringify([
          //   {
          //     timestamp: {
          //       gte: dateService
          //         .getCurrentUTCDate()
          //         .set({ hour: 0, minute: 0, second: 0 })
          //         .toISOString()
          //     }
          //   }
          // ]);
        }
      }
    }

    const { data: _panelData, error } = useGetDashboardPanelData(
      activeDashboard.id,
      panel.id,
      !editingLayout && location.pathname.startsWith("/dashboard"),
      params,
      { blockErrorToast: true, noLoader: true },
      (panelData) => {
        if (dontRenderPanelIds.includes(panel.id)) {
          setGeoMapAdditionalInfo((prev) => ({
            ...prev,
            [panel.id]: { ...panel, data: panelData }
          }));
        }

        // fetch shadows of devices for map panel
        if (panel.panel_type === "GEO_MAP") {
          const devices = panelData?.devices?.map((device: any) => ({
            device_id: device.device_id,
            fleet_id: device.fleet_id
          }));
          if (devices) {
            // devices.forEach(async (d) => {
            //   const shadow = await getShadow(
            //     selectedProject.id,
            //     user.selectedOrg.id,
            //     d.fleet_id,
            //     d.device_id,
            //     {},
            //     {
            //       noLoader: true
            //     }
            //   );
            //   deviceShadows.current = {
            //     ...deviceShadows.current,
            //     [d.device_id]: shadow
            //   };
            // });
          }
        }
      }
    );

    useEffect(() => {
      const _err = error as { message: string };
      if (_err) {
        handlePanelError &&
          handlePanelError(panel.id, panel.title, _err.message);
        setPanelError(_err.message);
      }

      return () => {};
    }, [error, handlePanelError, panel.id, panel.title]);

    const onChangeBoundingBox = (bBox: []) => {
      if (bBox.length) {
        boundingBox.current = bBox;
      }
    };

    const renderNoDataPanel = () => {
      return (
        <div
          ref={ref}
          className={`flex flex-col w-full h-full bg-background text-contentColor rounded-md`}
          key={panel.id}
        >
          <GraphHeader panel={panel} disableZoom />
          <div className="flex w-full flex-grow justify-center items-center">
            <h1 className="text-base">No Data Available</h1>
          </div>
        </div>
      );
    };

    return (
      <PanelErrorProvider value={{ panelError, setPanelError }}>
        <RenderPanel
          forwardRef={ref}
          panel={panel}
          panelData={_panelData}
          renderNoDataPanel={renderNoDataPanel}
          onChangeBoundingBox={onChangeBoundingBox}
          inCarousel={inCarousel}
          boundingBox={boundingBox.current}
          deviceShadows={deviceShadows}
          deviceShadowDefs={deviceShadowDefs.current}
          stoppedDataFetchPanelsRef={stoppedDataFetchPanelsRef}
          geoMapAdditionalInfo={geoMapAdditionalInfo}
        />
      </PanelErrorProvider>
    );
  }
);

const RenderPanel: React.FC<any> = ({
  panel,
  panelData,
  forwardRef,
  renderNoDataPanel,
  onChangeBoundingBox,
  inCarousel,
  boundingBox,
  deviceShadows,
  deviceShadowDefs,
  stoppedDataFetchPanelsRef,
  geoMapAdditionalInfo
}) => {
  if (dontRenderPanelIds.includes(panel.id)) {
    return null;
  }

  if (panel.panel_type === "PIE_CHART" && panelData?.options?.length) {
    return (
      <InnerPanelWrapper panel={panel}>
        <PieChart
          data={panelData?.options || []}
          category="count"
          index="value"
          title={panel.title}
          variant="donut"
          key={panel.id}
          colors={panel.definition.options.label_colors}
          zoom={panel.definition.zoom}
        />
      </InnerPanelWrapper>
    );
  }

  if (
    panel.panel_type === "GAUGE" &&
    panelData?.current_value?.value &&
    panelData?.max_value?.value
  ) {
    if (panel.definition.options["gauge-type"] === "v") {
      return (
        <InnerPanelWrapper panel={panel}>
          <TempMeter
            max={panel.definition.options.max_display}
            min={panel.definition.options.min_display}
            title={panel.title}
            minValue={panelData?.current_value?.value}
            maxValue={panelData?.max_value?.value}
            units={panel.definition.options.unit}
            key={panel.id}
            zoom={panel.definition.zoom}
          />
        </InnerPanelWrapper>
      );
    }
    return (
      <InnerPanelWrapper panel={panel}>
        <Gauge
          max={panel.definition.options.max_display}
          min={panel.definition.options.min_display}
          title={panel.title}
          minValue={panelData?.current_value?.value}
          maxValue={panelData?.max_value?.value}
          units={panel.definition.options.unit}
          key={panel.id}
          zoom={panel.definition.zoom}
        />
      </InnerPanelWrapper>
    );
  }

  if (
    panel.panel_type === "LINE_CHART" &&
    panel.definition.meta?.type === "Info"
  ) {
    const infoKeys = Object.keys(panelData ?? {});
    const filter = panel.definition.default_range?.start;
    if (infoKeys?.length && !_.isEmpty(panelData)) {
      const resultantValue = getInfographicSum(panelData[infoKeys[0]], filter);
      return (
        <InnerPanelWrapper panel={panel}>
          <NumericInfoGraph
            title={panel.title}
            info={resultantValue}
            zoom={panel.definition.zoom}
          />
        </InnerPanelWrapper>
      );
    }
  }

  if (panel.panel_type === "LINE_CHART") {
    const sources = Object.keys(panel.data_config?.source ?? {});
    const dataObj = { value: "" };
    let definations = {};
    sources.forEach((s) => {
      definations[s] = panel.data_config.source[s]["data-point-param"];
      dataObj[definations[s]] = "";
    });
    let colors = panel.definition.options.label_colors;
    const { isData, processedData, yMAX, yMIN } = generateGraphData(
      panelData || {},
      sources,
      dataObj
    );

    if (isData) {
      return (
        <InnerPanelWrapper panel={panel}>
          <LineChart
            chartdata={processedData}
            categories={sources}
            colors={colors}
            index="value"
            title={panel.title}
            key={panel.id}
            zoom={panel.definition.zoom}
          />
        </InnerPanelWrapper>
      );
    }
  }

  if (panel.panel_type === "BAR_CHART") {
    const sources = Object.keys(panel.data_config.source);
    const dataObj = { value: "" };
    let definations = {};
    sources.forEach((s) => {
      definations[s] = panel.data_config.source[s]["data-point-param"];
      dataObj[definations[s]] = "";
    });
    let colors = panel.definition.options.bar_colors;
    const { isData, processedData, yMAX, yMIN } = generateGraphData(
      panelData || {},
      sources,
      dataObj
    );

    if (isData) {
      return (
        <InnerPanelWrapper panel={panel}>
          <BarChart
            orientation={panel.definition.options.orientation}
            chartdata={processedData}
            categories={sources}
            colors={colors}
            index="value"
            title={panel.title}
            key={panel.id}
            zoom={panel.definition.zoom}
          />
        </InnerPanelWrapper>
      );
    }
  }

  if (panel.panel_type === "NUMERIC") {
    const infoKeys = Object.keys(panelData || {});
    if (infoKeys?.length && !_.isEmpty(panelData)) {
      const resultantValue = panelData[infoKeys[0]].value;
      return (
        <InnerPanelWrapper panel={panel}>
          <NumericInfoGraph
            title={panel.title}
            info={resultantValue}
            unit={panel.definition.options?.display_unit}
            zoom={panel.definition.zoom}
            minValue={panelData[infoKeys[0]]?.min_value ?? undefined}
            maxValue={panelData[infoKeys[0]]?.max_value ?? undefined}
          />
        </InnerPanelWrapper>
      );
    }
  }

  if (panel.panel_type === "GEO_MAP") {
    return (
      <InnerPanelWrapper panel={panel}>
        <IndoorMapView
          devices={panelData?.devices || []}
          clusters={panelData?.clusters || []}
          bounding_box={boundingBox}
          title={panel.title}
          key={panel.id}
          deviceShadows={deviceShadows}
          deviceShadowDefs={deviceShadowDefs}
          icons={panel.definition.icons}
          onChangeBoundingBox={onChangeBoundingBox}
          zoom={panel.definition.zoom}
          geoMapAdditionalInfo={geoMapAdditionalInfo}
        />
      </InnerPanelWrapper>
    );
  }

  if (panel.panel_type === "HEATMAP") {
    if (
      panelData &&
      Object.keys(panelData).length &&
      panel.definition.options.param &&
      panelData[panel.definition.options.param?.value]?.length
    ) {
      return (
        <InnerPanelWrapper panel={panel}>
          <HeatMap
            title={panel.title}
            configValues={panel.definition.options}
            data={panelData}
            zoom={panel.definition.zoom}
          />
        </InnerPanelWrapper>
      );
    }
  }

  if (panel.panel_type === "GENERIC") {
    if (panel.definition.panel_type === "NUMERIC") {
      const infoKeys = Object.keys(panelData || {});
      if (infoKeys?.length && !_.isEmpty(panelData)) {
        const resultantValue =
          panelData[0].count * (panel.definition.factor || 1);
        return (
          <InnerPanelWrapper panel={panel}>
            <NumericInfoGraph
              title={panel.title}
              info={resultantValue}
              unit={
                panel.definition.display_unit ??
                panel.definition.options?.display_unit
              }
              zoom={panel.definition.zoom}
              minValue={panelData[0].minValue}
              maxValue={panelData[0].maxValue}
            />
          </InnerPanelWrapper>
        );
      }
    } else if (panel.definition.panel_type === "GEO_MAP_LOCATION") {
      if (true) {
        return (
          <InnerPanelWrapper panel={panel}>
            <RidePath
              title={panel.title}
              key={panel.id}
              pathData={
                panelData && isObject(panelData) ? [] : panelData || []
              }
            />
          </InnerPanelWrapper>
        );
      }
    } else if (panel.definition.panel_type === "GEO_MAP_ENERGY") {
      if (true) {
        return (
          <InnerPanelWrapper panel={panel}>
            <DashMapEnergyComponent title={panel.title} />
          </InnerPanelWrapper>
        );
      }
    } else {
      // FIXME: add else if for table
      if (panelData && Object.keys(panelData).length) {
        return (
          <InnerPanelWrapper panel={panel}>
            <TableComponent
              title={panel.title}
              data={panelData || []}
              panelColumnDefs={panel.definition.columnState}
              zoom={panel.definition.zoom}
            />
          </InnerPanelWrapper>
        );
      }
    }
  }

  return renderNoDataPanel();
};

const InnerPanelWrapper = ({ panel, children }) => {
  return (
    <div className={` w-full h-full bg-background rounded-md`}>
      <GraphHeader panel={panel} />
      <div
        className="h-[92%]"
        style={{
          zoom: panel.definition.zoom
            ? panel.definition.zoom.toString() + "%"
            : "100%"
        }}
      >
        {children}
      </div>
    </div>
  );
};

function formatDuration(duration) {
  let _duration: any;
  if (duration.includes("s")) _duration = "seconds";
  else if (duration.includes("m")) _duration = "minutes";
  else _duration = "hours";

  return _duration;
}

export default PanelWrapper;
