import { Popover, Transition } from "@headlessui/react";
import { FieldProps } from "formik";
import React, { Fragment } from "react";
import Dropzone from "react-dropzone";
import { toast } from "react-toastify";

export interface IProfilePictureProps extends FieldProps<string> {}

const ProfilePicture: React.FC<IProfilePictureProps> = ({
  form: { setFieldTouched, setFieldValue },
  field: { name, value },
}) => {
  const onDrop = React.useCallback(
    (acceptedFiles: Array<File>, fileRejections: Array<any>) => {
      fileRejections.forEach((file) => {
        file.errors.forEach((err: any) => {
          if (err.code === "file-too-large") {
            toast.error("File size error. Max allowed file size is 1MB.");
          } else {
            toast.error(err.message);
          }
        });
      });

      acceptedFiles.forEach((file) => {
        try {
          const reader = new FileReader();
          reader.onabort = (reason: any) => toast.info(reason);
          reader.onerror = (error: any) => toast.error(error);
          reader.onload = () => {
            const base64Uri = reader.result;
            setFieldTouched(name);
            setFieldValue(name, base64Uri);
          };
          reader.readAsDataURL(file);
        } catch (err) {
          toast.error(
            err.message ||
              "An error occured while trying to process the file. Please try again later or contact system administrator."
          );
        }
      });
    },
    [name, setFieldTouched, setFieldValue]
  );

  const remove = () => {
    setFieldTouched(name);
    setFieldValue(name, "");
  };

  const buttonRef = React.useRef(null);

  return (
    <Dropzone
      onDrop={onDrop}
      multiple={false}
      accept={{ "image/*": [] }}
      maxSize={1048576}>
      {({ getRootProps, getInputProps, isDragActive }) => (
        <React.Fragment>
          <label className="text-sm font-medium text-gray-400">
            Profile Picture
          </label>

          <div className="mt-2">
            <div
              {...getRootProps()}
              className={`flex justify-center max-w-2xl px-6 pt-5 pb-6 border-2  border-dashed rounded-md cursor-pointer ${
                isDragActive ? "border-primary" : "border-gray-300"
              }`}>
              <div className="space-y-1 text-center">
                <svg
                  className="w-12 h-12 mx-auto text-gray-400"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 48 48"
                  aria-hidden="true">
                  <path
                    d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <div className="flex text-sm font-medium text-gray-400">
                  <span>Upload a file</span>
                  <input
                    {...getInputProps({
                      id: "file-upload",
                      name: "file-upload",
                      className: "sr-only",
                    })}
                  />
                  <p className="pl-1">or drag and drop</p>
                </div>
                <p className="text-xs text-gray-500">PNG, JPG, GIF up to 1MB</p>
              </div>
            </div>

            <div className="flex items-center justify-end mt-4 space-x-4">
              <Popover className="relative">
                {({ open }) => (
                  <div className="flex flex-col">
                    <Popover.Button ref={buttonRef} disabled={!value}>
                      <div className="flex items-center space-x-2 text-gray-400 hover:text-primary disabled:opacity-40 disabled:cursor-not-allowed">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-5 h-5"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor">
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                          />
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                          />
                        </svg>
                        <span>View</span>
                      </div>
                    </Popover.Button>

                    <Transition
                      show={open}
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 translate-y-1">
                      <Popover.Panel static className="z-10 absolute top-6">
                        <img
                          className="object-cover w-16 h-16 rounded-full border-2 border-primary"
                          src={value}
                          alt="profile"
                        />
                      </Popover.Panel>
                    </Transition>
                  </div>
                )}
              </Popover>

              <button
                type="button"
                disabled={!value}
                onClick={remove}
                className="flex items-center space-x-2 text-gray-400 hover:text-primary disabled:opacity-40 disabled:cursor-not-allowed">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-5 h-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                  />
                </svg>
                <span>Remove</span>
              </button>
            </div>
          </div>
        </React.Fragment>
      )}
    </Dropzone>
  );
};

export default ProfilePicture;
