import React, { useMemo, useState } from "react";
import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getPaginationRowModel,
  getSortedRowModel,
  ColumnDef,
  flexRender,
  RowSelectionState
} from "@tanstack/react-table";
import {
  ArrowDownIcon,
  ArrowUpIcon,
  ArrowsUpDownIcon,
  MagnifyingGlassIcon,
  TrashIcon
} from "@heroicons/react/24/outline";
import { Link, useNavigate } from "react-router-dom";
import { DebouncedInput } from "@app/shared/components/deboundced-input.component";
import { reactTableFuzzyFilter } from "@app/shared/utils/helper.util";
import Pagination from "@app/shared/components/pagination.component";
import { useGetDashPanelBlueprints } from "@/app/shared/hooks/get/blueprints";
import ShowLoading from "@/app/shared/components/loading.component";
import dateService from "@/services/date.service";
import { Badge, Button } from "@tremor/react";
import { IndeterminateCheckbox } from "@/app/shared/components/indeterminate-checkbox.component";
import NoData from "@/app/shared/components/no-data.component";
import { useDeleteBlueprint } from "@/app/shared/hooks/delete/delete-blueprint";
import { useConfirmDelete } from "@/app/shared/hooks/use-confirm-delete.hooks";
import { toast } from "react-toastify";

interface IBlueprintRow {
  id: string;
  name: string;
  created_at: string;
  version: string;
  usedBy: React.ReactNode;
}

type PanelBlueprintsAllProps = {
  kind: "PANEL" | "DASHBOARD";
};

const BlueprintsAll: React.FC<PanelBlueprintsAllProps> = ({ kind }) => {
  const navigate = useNavigate();

  const [globalFilter, setGlobalFilter] = useState("");

  const deleteBlueprintMutation = useDeleteBlueprint();
  const { openConfirmDeleteModal } = useConfirmDelete();

  const {
    data: blueprints,
    isLoading,
    isFetching
  } = useGetDashPanelBlueprints({ kind });

  const onBlueprintDelete = (blueprintId: string) => {
    deleteBlueprintMutation.mutate(blueprintId, {
      onSuccess: () => {
        toast.success("Deleted Blueprint successfully.");
      }
    });
  };

  const columns = useMemo<ColumnDef<IBlueprintRow, any>[]>(
    () => [
      {
        id: "select",
        size: 20,
        enableResizing: false,
        header: ({ table }) => (
          <IndeterminateCheckbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler()
            }}
          />
        ),
        cell: ({ row }) => (
          <div className="px-1">
            <IndeterminateCheckbox
              {...{
                checked: row.getIsSelected(),
                disabled: !row.getCanSelect(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler()
              }}
            />
          </div>
        )
      },
      {
        id: "name",
        accessorKey: "name",
        header: "Name",
        cell: ({ getValue, row }) => {
          return (
            <Link
              to={`${row.original.id}?tab=details`}
              className="hover:underline"
            >
              {getValue()}
            </Link>
          );
        },
        size: 30
      },
      {
        id: "kind",
        accessorKey: "kind",
        header: "Kind",
        cell: ({ getValue }) => {
          return <Badge>{getValue()}</Badge>;
        },
        size: 100
      },
      {
        id: "created_at",
        accessorKey: "created_at",
        header: "Created At",
        sortingFn: (a, b, colId) =>
          dateService
            .convertDateStrToMoment(a.getValue(colId))
            .diff(dateService.convertDateStrToMoment(b.getValue(colId))),
        cell: ({ getValue }) => {
          return (
            <div className="text-contentColorLight">
              {dateService.convertUTCToLocalDate(getValue())}
            </div>
          );
        },
        size: 100
      },

      {
        id: "delete",
        header: "Delete",
        cell: ({ row }) => {
          return (
            <Button
              icon={TrashIcon}
              color="red"
              variant="light"
              onClick={() =>
                openConfirmDeleteModal(
                  () => onBlueprintDelete(row.original.id),
                  "Are you sure you want to delete this blueprint? It will also be deleted from everywhere it has been applied."
                )
              }
            />
          );
        },
        size: 100
      }
    ],
    []
  );

  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

  const table = useReactTable({
    data: blueprints ?? [],
    columns,
    state: {
      globalFilter,
      rowSelection
    },
    globalFilterFn: reactTableFuzzyFilter,
    onGlobalFilterChange: setGlobalFilter,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues()
  });

  const onApplyBlueprintsClick = () => {
    navigate("apply-multiple", {
      state: {
        blueprints: blueprints.filter((_, ind) => rowSelection[ind] === true)
      }
    });
  };

  if (isLoading || isFetching) {
    return <ShowLoading />;
  } else if (!blueprints?.length) {
    return <NoData msg="No Blueprints Available" />;
  }

  return (
    <div>
      <div className="my-6 flex justify-between">
        <div className="max-w-sm">
          <DebouncedInput
            value={globalFilter ?? ""}
            icon={MagnifyingGlassIcon}
            onChange={(value) => setGlobalFilter(String(value))}
            className="p-2 font-lg shadow border border-background-layer3 bg-background text-contentColor"
            placeholder="Search all columns..."
          />
        </div>
        <Button
          disabled={Object.keys(rowSelection).length === 0}
          onClick={onApplyBlueprintsClick}
        >
          Apply Blueprints
        </Button>
      </div>
      <hr className="border-background-layer3" />
      <div className="">
        {/* table */}
        <table className="w-full h-full my-6 ">
          <thead className="">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      style={{
                        width: header.column.getSize()
                      }}
                      className="px-2 py-2 text-xs text-center uppercase text-contentColorLight font-normal"
                    >
                      {header.isPlaceholder ? null : (
                        <>
                          <div
                            {...{
                              className: header.column.getCanSort()
                                ? "cursor-pointer select-none flex items-center justify-center gap-1"
                                : "",
                              onClick: header.column.getToggleSortingHandler()
                            }}
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                            {{
                              asc: <ArrowUpIcon width={10} />,
                              desc: <ArrowDownIcon width={10} />
                            }[header.column.getIsSorted() as string] ??
                              (header.column.getCanSort() ? (
                                <ArrowsUpDownIcon width={10} />
                              ) : null)}
                          </div>
                          {header.column.getCanFilter() ? (
                            <div>{""}</div>
                          ) : null}
                        </>
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody className="rounded-md">
            {table.getRowModel().rows.map((row, ind) => {
              return (
                <tr
                  key={row.id}
                  className={`bg-background ${
                    ind === 0
                      ? "!rounded-t-md"
                      : ind === table.getTotalSize() - 1
                      ? "!rounded-b-md"
                      : ""
                  }`}
                >
                  {row.getVisibleCells().map((cell, cellInd) => {
                    return (
                      <td
                        key={cell.id}
                        className={`mx-2 whitespace-nowrap !bg-transparent text-sm text-center p-2 py-3 ${
                          ind === 0 && cellInd === 0
                            ? "rounded-tl-md"
                            : ind === table.getTotalSize() - 1 && cellInd === 0
                            ? "rounded-bl-md"
                            : ind === 0 && cellInd === columns.length - 1
                            ? "rounded-tr-md"
                            : ind === table.getTotalSize() - 1 &&
                              cellInd === columns.length - 1
                            ? "rounded-br-md"
                            : ""
                        }`}
                        style={{
                          width: cell.column.getSize()
                        }}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        <Pagination<IBlueprintRow> table={table} />
      </div>
    </div>
  );
};

export default BlueprintsAll;
