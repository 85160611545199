import { FieldError } from "@/app/shared/components";
import ShowLoading from "@/app/shared/components/loading.component";
import { useGetAppDetails } from "@/app/shared/hooks/get/app-details";
import { useGetDashboards } from "@/app/shared/hooks/get/dashboards";
import { useGetFleets } from "@/app/shared/hooks/get/fleets";
import { useGetTags } from "@/app/shared/hooks/get/tags";
import { useGetUserGroups } from "@/app/shared/hooks/get/user-groups";
import { useGetUserGroupsDetails } from "@/app/shared/hooks/get/user-groups-details";
import { reactSelectClassNames } from "@/app/shared/utils/helper.util";
import { convertPermissionsToFormState } from "@/app/shared/utils/invite.utils";
import { IOption } from "@/interfaces";
import { IProjectApp } from "@/interfaces/apps.interface";
import {
  IUserGroup,
  OrgAccessLevel
} from "@/interfaces/user-groups.interface";
import { useFleetAndDevicesStore } from "@/store";
import { Disclosure } from "@headlessui/react";
import {
  ChevronUpIcon,
  ExclamationTriangleIcon,
  PlusIcon,
  TrashIcon
} from "@heroicons/react/24/outline";
import { Badge, Button } from "@tremor/react";
import {
  Formik,
  Field,
  Form,
  FormikConfig,
  ErrorMessage,
  FieldArray
} from "formik";
import { useEffect, useMemo, useRef, useState } from "react";
import ReactSelect from "react-select";
import * as Yup from "yup";

export interface IInviteFormState {
  email: string;
  name: string;
  description?: string;
  org_access: OrgAccessLevel;
  org_perms: string[];
  groups?: string[];
  groups_admin: boolean;
  resource_access: {
    resource_type: IOption;
    actions: IOption[];
    resource: IOption;
  }[];
}

const CommonSchema = {
  org_access: Yup.string()
    .oneOf([OrgAccessLevel.MEMBER, OrgAccessLevel.ADMIN])
    .required("org access is required"),
  org_perms: Yup.array().of(Yup.string()),
  groups: Yup.array().of(Yup.string()),
  groups_admin: Yup.boolean(),
  resource_access: Yup.array()
    .of(
      Yup.object().shape({
        resource_type: Yup.object()
          .default(undefined)
          .shape({
            label: Yup.string().required(),
            value: Yup.string().required()
          })
          .required("Resource type is required"),
        resource: Yup.object()
          .default(undefined)
          .required("Resource is required.")
          .shape({
            label: Yup.string().required(),
            value: Yup.string().required()
          }),
        actions: Yup.array()
          .min(1, "Minimum 1 permission required")
          .of(
            Yup.object()
              .shape({
                label: Yup.string().required(),
                value: Yup.string().required()
              })
              .required()
          )
      })
    )
    .required()
};

const InviteFormSchema = Yup.object().shape({
  ...CommonSchema,
  email: Yup.string().email().required()
});

const GroupFormSchema = Yup.object().shape({
  ...CommonSchema,
  name: Yup.string().required()
});

const AppFormSchema = Yup.object().shape({
  ...CommonSchema,
  name: Yup.string().required(),
  description: Yup.string().required()
});

const formInitialState: IInviteFormState = {
  email: "",
  name: "",
  org_access: undefined,
  org_perms: [],
  groups: [],
  groups_admin: false,
  resource_access: []
};

interface IInviteFormProps {
  showAddToGroup: boolean;
  renderAs: "group" | "app" | "invite";
  showNameField?: boolean;
  showDescField?: boolean;
  selectedGroup?: IUserGroup;
  selectedApp?: IProjectApp;
  hideOrgPerms?: boolean;
  overlayOpen?: boolean;
  handleSubmit: FormikConfig<IInviteFormState>["onSubmit"];
}

const orgAccessOptions: IOption[] = [
  { label: "ADMIN", value: "ADMIN" },
  { label: "MEMBER", value: "MEMBER" }
];

const orgPerms = [
  { label: "Can manage billings", value: "can_manage_billing" },
  { label: "Can create projects", value: "can_create_projects" }
];

const resourceTypeOptions = [
  { label: "Projects", value: "projects" },
  { label: "Dashboards", value: "dashboards" },
  { label: "Fleets", value: "fleets" },
  { label: "Tags", value: "tags" }
  // { label: "Device Data Definitions", value: "device_data_definitions" },
  // { label: "Device Data Points", value: "device_data_points" },
  // { label: "Device Shadow Definitions", value: "device_shadow_definitions" },
  // { label: "Context Definitions", value: "context_definitions" },
  // { label: "Logs", value: "logs" },
  // { label: "OTA Updates", value: "ota_updates" },
  // { label: "Devices", value: "devices" },
  // { label: "User Groups", value: "groups" }
];

const projPermissions = [
  { label: "Admin", value: "admin" },
  { label: "Member", value: "member" },
  { label: "Can Read Logs", value: "can_read_logs" },
  {
    label: "Can Manage Context Definitions",
    value: "can_manage_context_defs"
  },
  { label: "Can Manage Shadow Definitions", value: "can_manage_shadow_defs" },
  { label: "Can Manage Data Definitions", value: "can_manage_data_defs" },
  { label: "Can Manage Rules", value: "can_manage_rules" },
  { label: "Can Manage Dashboards", value: "can_manage_dashboards" },
  { label: "Can Manage Certificates", value: "can_manage_certificates" },
  {
    label: "Can Manage Consumer Settings",
    value: "can_manage_consumer_settings"
  },
  { label: "Can Manage Devices", value: "can_manage_devices" },
  { label: "Can Manage Device Groups", value: "can_manage_device_groups" },
  { label: "Can Manage OTA Updates", value: "can_manage_ota_updates" }
];

const dashboardPermissions = [{ label: "Can Read", value: "can_read" }];

const deviceGroupPermissions = [
  { label: "Can Read Group", value: "can_read_device_group" },
  { label: "Can Manage Group", value: "can_modify_device_group" },
  { label: "Can Read Device Shadow", value: "can_read_device_shadow" },
  { label: "Can Read Device Data", value: "can_read_device_data" },
  { label: "Can Read Device Dashboards", value: "can_read_device_dashboards" },
  { label: "Can Write Device Shadow", value: "can_write_device_shadow" },
  { label: "Can Read Devices", value: "can_read_devices" }
];

const InviteForm: React.FC<IInviteFormProps> = ({
  showAddToGroup,
  renderAs,
  showNameField = false,
  showDescField = false,
  hideOrgPerms = false,
  selectedGroup,
  selectedApp,
  overlayOpen,
  handleSubmit
}) => {
  const scrollDivRef = useRef<HTMLDivElement>(null);

  const [initialValues, setInitialValues] = useState(formInitialState);

  const { data: userGroups } = useGetUserGroups();

  const { data: selectedGroupDetails, isFetching: isSelectedGroupLoading } =
    useGetUserGroupsDetails(overlayOpen ? selectedGroup?.id : "", {});

  const { data: selectedAppDetails, isFetching: isSelectedAppLoading } =
    useGetAppDetails(selectedApp?.id);

  const userGroupsOptions = useMemo(() => {
    return (
      userGroups?.map((group) => ({
        label: group.group_name,
        value: group.id
      })) ?? []
    );
  }, [userGroups]);

  const projects = useFleetAndDevicesStore((state) => state.projects);

  const projOptions = useMemo(() => {
    return (
      projects?.map((proj) => ({
        label: proj.project_name,
        value: proj.id
      })) ?? []
    );
  }, [projects]);

  const { data: fleets } = useGetFleets();

  const fleetsOptions = useMemo(() => {
    return (
      fleets?.map((fleet) => ({
        label: fleet.fleet_name,
        value: fleet.id
      })) ?? []
    );
  }, [fleets]);

  const { data: tags } = useGetTags();

  const tagsOptions = useMemo(() => {
    return (
      tags?.map((tag) => ({
        label: tag.name,
        value: tag.id
      })) ?? []
    );
  }, [tags]);

  const { data: dashboards } = useGetDashboards();

  const dashOptions = useMemo(() => {
    return (
      dashboards?.map((dash) => ({
        label: dash.dashboard_name,
        value: dash.id
      })) ?? []
    );
  }, [dashboards]);

  useEffect(() => {
    if (selectedGroupDetails) {
      // convert group to form state
      const formState: IInviteFormState = {
        email: "",
        name: selectedGroupDetails.group_name,
        org_access: selectedGroupDetails.org_access,
        org_perms: [], // not used in groups
        groups: [],
        groups_admin: false,
        resource_access: convertPermissionsToFormState(
          selectedGroupDetails.permissions,
          resourceTypeOptions,
          projPermissions,
          dashboardPermissions,
          deviceGroupPermissions,
          projOptions,
          dashOptions,
          fleetsOptions,
          tagsOptions
        )
      };

      setInitialValues(formState);
    } else {
      setInitialValues(formInitialState);
    }

    return () => {};
  }, [
    dashOptions,
    fleetsOptions,
    projOptions,
    selectedGroupDetails,
    tagsOptions
  ]);

  useEffect(() => {
    if (selectedAppDetails) {
      // convert group to form state
      const formState: IInviteFormState = {
        email: "",
        name: selectedAppDetails.app_name,
        org_access: selectedAppDetails.org_access,
        org_perms: [], // not used in apps
        groups: [],
        groups_admin: false,
        resource_access: convertPermissionsToFormState(
          selectedAppDetails.permissions,
          resourceTypeOptions,
          projPermissions,
          dashboardPermissions,
          deviceGroupPermissions,
          projOptions,
          dashOptions,
          fleetsOptions,
          tagsOptions
        )
      };

      setInitialValues(formState);
    } else {
      setInitialValues(formInitialState);
    }

    return () => {};
  }, [
    dashOptions,
    fleetsOptions,
    projOptions,
    selectedAppDetails,
    tagsOptions
  ]);

  if (isSelectedGroupLoading || isSelectedAppLoading) {
    return <ShowLoading />;
  }

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={
        showNameField
          ? GroupFormSchema
          : showDescField
          ? AppFormSchema
          : InviteFormSchema
      }
      onSubmit={handleSubmit}
    >
      {({ values, errors, touched }) => (
        <Form className="w-full flex flex-col flex-1">
          <fieldset disabled={!!selectedApp}>
            <div
              ref={scrollDivRef}
              className="w-full flex flex-col max-h-[70vh] overflow-y-auto flex-1 mt-4 px-1"
            >
              {showNameField || renderAs === "app" ? (
                <div className="mb-5">
                  <label className="text-sm font-medium text-contentColor">
                    Name
                  </label>

                  <Field
                    type="text"
                    id="name"
                    name="name"
                    placeholder={
                      renderAs === "group" ? "Name of group." : "Name of app."
                    }
                    className="block w-full p-3 bg-background text-contentColor border-background-layer3 rounded-md focus:ring focus:ring-opacity-40 focus:ring-primary focus:border-primaryLight sm:text-sm"
                  />
                  <ErrorMessage name="name">
                    {(msg) => <FieldError message={msg} />}
                  </ErrorMessage>
                </div>
              ) : (
                <div className="mb-5">
                  <label className="text-sm font-medium text-contentColor">
                    Email of collaborator
                  </label>

                  <Field
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Email of collaborator"
                    className="block w-full p-3 mt-2 bg-background text-contentColor border-background-layer3 rounded-md focus:ring focus:ring-opacity-40 focus:ring-primary focus:border-primaryLight sm:text-sm"
                  />
                  <ErrorMessage name="email">
                    {(msg) => <FieldError message={msg} />}
                  </ErrorMessage>
                </div>
              )}

              {showDescField ? (
                <div className="mb-5">
                  <label className="text-sm font-medium text-contentColor">
                    Description
                  </label>
                  <Field
                    as="textarea"
                    id="description"
                    name="description"
                    placeholder="Description of the app."
                    className="block w-full p-3 bg-background text-contentColor border-background-layer3 rounded-md focus:ring focus:ring-opacity-40 focus:ring-primary focus:border-primaryLight sm:text-sm"
                  />
                  <ErrorMessage name="description">
                    {(msg) => <FieldError message={msg} />}
                  </ErrorMessage>
                </div>
              ) : null}

              <div className="mb-5">
                <label className="text-sm font-medium text-contentColor">
                  Org Access
                </label>
                <Field
                  id="org_access"
                  name="org_access"
                  component={({ field, form, ...props }) => (
                    <ReactSelect
                      {...field}
                      {...form}
                      id={"org_access"}
                      name={"org_access"}
                      value={orgAccessOptions.find(
                        (opt) => opt.value === values.org_access
                      )}
                      onChange={(opt: IOption) => {
                        form.setFieldValue(`org_access`, opt.value);
                      }}
                      placeholder="Select Org Access."
                      options={orgAccessOptions}
                      className="block w-full bg-background text-contentColor rounded-md focus:ring focus:ring-opacity-40 focus:ring-primary focus:border-primaryLight sm:text-sm"
                      classNames={reactSelectClassNames}
                    />
                  )}
                />
                <ErrorMessage name="org_access">
                  {(msg) => <FieldError message={msg} />}
                </ErrorMessage>
              </div>
              {values.org_access === OrgAccessLevel.MEMBER && !hideOrgPerms ? (
                <div className="mb-5">
                  <div className="flex justify-start space-x-2">
                    {orgPerms.map((orgPerm) => (
                      <label
                        key={"org-perm-" + orgPerm.value}
                        htmlFor={"org_perm-" + orgPerm.value}
                        className={`inline-flex select-none cursor-pointer items-center ${
                          values.org_perms?.includes(orgPerm.value)
                            ? "bg-primaryLight !text-white"
                            : "bg-background-layer2"
                        } rounded-full px-3 py-1`}
                      >
                        <Field
                          type="checkbox"
                          name="org_perm"
                          id={"org_perm-" + orgPerm.value}
                          value={orgPerm.value}
                          className="form-checkbox rounded-full"
                        />
                        <span className="ml-2 text-sm">{orgPerm.label}</span>
                      </label>
                    ))}
                  </div>
                </div>
              ) : null}

              {showAddToGroup ? (
                <div className="mb-5">
                  <label className="text-sm font-medium text-contentColor">
                    Add to groups
                  </label>
                  <Field
                    id="groups"
                    name="groups"
                    component={({ field, form, ...props }) => (
                      <ReactSelect
                        {...field}
                        {...form}
                        id={"groups"}
                        name={"groups"}
                        isMulti
                        value={
                          userGroupsOptions.filter((opt) =>
                            values.groups?.includes(opt.value)
                          ) ?? []
                        }
                        onChange={(opt: IOption[]) => {
                          form.setFieldValue(
                            `groups`,
                            opt.map((o) => o.value)
                          );
                        }}
                        placeholder="Select groups."
                        options={userGroupsOptions}
                        className="block w-full bg-background text-contentColor rounded-md focus:ring focus:ring-opacity-40 focus:ring-primary focus:border-primaryLight sm:text-sm"
                        classNames={reactSelectClassNames}
                      />
                    )}
                  />
                  {values.groups?.length > 0 ? (
                    <>
                      <div className="mt-5">
                        {/* Checkbox for add as admin */}
                        <label
                          htmlFor="groups_admin"
                          className={`inline-flex select-none bg-background-layer2 cursor-pointer items-center
                      rounded-full px-3 py-1`}
                        >
                          <Field
                            type="checkbox"
                            name="groups_admin"
                            id="groups_admin"
                            className="form-checkbox rounded-full"
                          />
                          <span className="ml-2 text-sm">Add as admin</span>
                        </label>
                      </div>
                      <ErrorMessage name="groups">
                        {(msg) => <FieldError message={msg} />}
                      </ErrorMessage>
                    </>
                  ) : null}
                </div>
              ) : null}

              <hr className="border-background-layer3 mb-2" />
              <div className="mb-5">
                <FieldArray
                  name={"resource_access"}
                  render={(arrayHelpers) => (
                    <div className="pb-12">
                      <div className="flex justify-between items-center pb-2 border-b border-background-layer3">
                        <h3>Resource Permissions</h3>
                        <Button
                          type="button"
                          icon={PlusIcon}
                          size={"xs"}
                          variant="secondary"
                          onClick={(e) => {
                            arrayHelpers.push({
                              resource_type: undefined,
                              actions: [],
                              resource: undefined
                            });
                            setTimeout(() => {
                              scrollDivRef.current?.scrollTo({
                                top: scrollDivRef.current.scrollHeight,
                                behavior: "smooth"
                              });
                            });
                          }}
                        >
                          Add Permission
                        </Button>
                      </div>

                      <div>
                        {values.resource_access.map((perm, permInd) => (
                          <>
                            <Disclosure defaultOpen={true}>
                              {({ open }) => (
                                <div>
                                  <Disclosure.Button
                                    className={`flex w-full justify-between mt-2 ${
                                      open ? "rounded-t-lg" : "rounded-lg"
                                    } bg-background-layer1 hover:bg-background-layer2 px-4 py-2 text-left text-sm font-medium  focus:outline-none focus-visible:ring focus-visible:ring-blue-500/75`}
                                  >
                                    <div className="flex gap-2">
                                      {open ? (
                                        <>
                                          <h3 className="block text-sm font-medium text-contentColor">
                                            Set Resource Permission
                                          </h3>
                                        </>
                                      ) : (
                                        <>
                                          <Badge
                                            color={
                                              perm.resource_type
                                                ? "primary"
                                                : "red"
                                            }
                                            size="sm"
                                            className="text-xs"
                                          >
                                            {perm.resource_type?.label ?? (
                                              <ExclamationTriangleIcon
                                                color="red"
                                                width={14}
                                              />
                                            )}
                                          </Badge>
                                          <Badge
                                            color={
                                              perm.resource ? "yellow" : "red"
                                            }
                                            size="sm"
                                            className="text-xs"
                                          >
                                            {perm.resource?.label ?? (
                                              <ExclamationTriangleIcon
                                                color="red"
                                                width={14}
                                              />
                                            )}
                                          </Badge>
                                          <Badge
                                            color={
                                              perm.actions.length > 0
                                                ? "green"
                                                : "red"
                                            }
                                            size="sm"
                                            className="text-xs"
                                          >
                                            {perm.actions.length} Permission(s)
                                          </Badge>
                                        </>
                                      )}
                                    </div>
                                    <div className="flex gap-2">
                                      <Button
                                        type="button"
                                        variant="light"
                                        size="xs"
                                        color="red"
                                        className="!text-xs rounded-md !px-2 py-0.5"
                                        icon={TrashIcon}
                                        onClick={(e) => {
                                          e.stopPropagation();

                                          arrayHelpers.remove(permInd);
                                        }}
                                      />
                                      <ChevronUpIcon
                                        width={20}
                                        className={`transition-transform duration-100 ${
                                          open ? "rotate-0" : "rotate-180"
                                        }`}
                                      />
                                    </div>
                                  </Disclosure.Button>
                                  <Disclosure.Panel
                                    className={
                                      "px-4 pb-2 bg-background-layer1 rounded-b-lg"
                                    }
                                  >
                                    <div className="mb-2">
                                      <label className="text-sm font-medium text-contentColor">
                                        Resource Type
                                      </label>
                                      <Field
                                        id={`resource_access.${permInd}.resource_type`}
                                        name={`resource_access.${permInd}.resource_type`}
                                        component={({
                                          field,
                                          form,
                                          ...props
                                        }) => (
                                          <ReactSelect
                                            {...field}
                                            {...form}
                                            id={`resource_access.${permInd}.resource_type`}
                                            name={`resource_access.${permInd}.resource_type`}
                                            value={
                                              values.resource_access[permInd]
                                                .resource_type
                                            }
                                            onChange={(opt: IOption) => {
                                              form.setFieldValue(
                                                `resource_access.${permInd}.resource_type`,
                                                opt
                                              );
                                              form.setFieldValue(
                                                `resource_access.${permInd}.resource`,
                                                undefined
                                              );
                                              form.setFieldValue(
                                                `resource_access.${permInd}.actions`,
                                                []
                                              );
                                            }}
                                            placeholder="Select a resource type."
                                            options={resourceTypeOptions}
                                            className="block w-full bg-background text-contentColor rounded-md focus:ring focus:ring-opacity-40 focus:ring-primary focus:border-primaryLight sm:text-sm"
                                            classNames={reactSelectClassNames}
                                          />
                                        )}
                                      />
                                      <ErrorMessage
                                        name={`resource_access.${permInd}.resource_type`}
                                      >
                                        {(msg) => <FieldError message={msg} />}
                                      </ErrorMessage>
                                    </div>
                                    <div className="mb-2">
                                      <label className="text-sm font-medium text-contentColor">
                                        Resource
                                      </label>
                                      <Field
                                        id={`resource_access.${permInd}.resource`}
                                        name={`resource_access.${permInd}.resource`}
                                        isDisabled={
                                          !values.resource_access[permInd]
                                            .resource_type
                                        }
                                        component={({
                                          field,
                                          form,
                                          ...props
                                        }) => (
                                          <ReactSelect
                                            {...field}
                                            {...form}
                                            id={`resource_access.${permInd}.resource`}
                                            name={`resource_access.${permInd}.resource`}
                                            value={
                                              values.resource_access[permInd]
                                                .resource
                                            }
                                            onChange={(val: IOption) => {
                                              form.setFieldValue(
                                                `resource_access.${permInd}.resource`,
                                                val
                                              );
                                              form.setFieldValue(
                                                `resource_access.${permInd}.actions`,
                                                []
                                              );
                                            }}
                                            placeholder="Select a resource."
                                            options={
                                              values.resource_access[permInd]
                                                .resource_type?.value ===
                                              "projects"
                                                ? projOptions
                                                : values.resource_access[
                                                    permInd
                                                  ].resource_type?.value ===
                                                  "dashboards"
                                                ? dashOptions
                                                : values.resource_access[
                                                    permInd
                                                  ].resource_type?.value ===
                                                  "fleets"
                                                ? fleetsOptions
                                                : values.resource_access[
                                                    permInd
                                                  ].resource_type?.value ===
                                                  "tags"
                                                ? tagsOptions
                                                : []
                                            }
                                            className="block w-full bg-background text-contentColor rounded-md focus:ring focus:ring-opacity-40 focus:ring-primary focus:border-primaryLight sm:text-sm"
                                            classNames={reactSelectClassNames}
                                          />
                                        )}
                                      />
                                      <ErrorMessage
                                        name={`resource_access.${permInd}.resource`}
                                      >
                                        {(msg) => <FieldError message={msg} />}
                                      </ErrorMessage>
                                    </div>
                                    <div className="mb-2">
                                      <label className="text-sm font-medium text-contentColor">
                                        Permissions
                                      </label>
                                      <Field
                                        id={`resource_access.${permInd}.actions`}
                                        name={`resource_access.${permInd}.actions`}
                                        component={({
                                          field,
                                          form,
                                          ...props
                                        }) => (
                                          <ReactSelect
                                            {...field}
                                            {...form}
                                            isDisabled={
                                              !values.resource_access[permInd]
                                                .resource
                                            }
                                            id={`resource_access.${permInd}.actions`}
                                            name={`resource_access.${permInd}.actions`}
                                            value={
                                              values.resource_access[permInd]
                                                .actions
                                            }
                                            isMulti
                                            onChange={(vals: IOption[]) => {
                                              form.setFieldValue(
                                                `resource_access.${permInd}.actions`,
                                                vals
                                              );
                                            }}
                                            placeholder="Select Permissions."
                                            options={
                                              values.resource_access[permInd]
                                                .resource_type?.value ===
                                              "projects"
                                                ? projPermissions
                                                : values.resource_access[
                                                    permInd
                                                  ].resource_type?.value ===
                                                  "dashboards"
                                                ? dashboardPermissions
                                                : values.resource_access[
                                                    permInd
                                                  ].resource_type?.value ===
                                                  "fleets"
                                                ? deviceGroupPermissions
                                                : values.resource_access[
                                                    permInd
                                                  ].resource_type?.value ===
                                                  "tags"
                                                ? deviceGroupPermissions
                                                : []
                                            }
                                            className="block w-full bg-background text-contentColor rounded-md focus:ring focus:ring-opacity-40 focus:ring-primary focus:border-primaryLight sm:text-sm"
                                            classNames={reactSelectClassNames}
                                          />
                                        )}
                                      />
                                      <ErrorMessage
                                        name={`resource_access.${permInd}.actions`}
                                      >
                                        {(msg) => <FieldError message={msg} />}
                                      </ErrorMessage>
                                    </div>
                                  </Disclosure.Panel>
                                </div>
                              )}
                            </Disclosure>
                          </>
                        ))}

                        {values.resource_access.length > 1 ? (
                          <div className="mt-4 flex justify-end">
                            <Button
                              type="button"
                              icon={PlusIcon}
                              size={"xs"}
                              variant="secondary"
                              onClick={(e) => {
                                arrayHelpers.push({
                                  resource_type: undefined,
                                  actions: [],
                                  resource: undefined
                                });
                                setTimeout(() => {
                                  scrollDivRef.current?.scrollTo({
                                    top: scrollDivRef.current.scrollHeight,
                                    behavior: "smooth"
                                  });
                                });
                              }}
                            >
                              Add
                            </Button>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  )}
                />
              </div>
            </div>

            {selectedApp ? null : (
              <div>
                <button className="w-full inline-block px-12 py-3 mt-6 font-medium text-center text-white transition-colors duration-200 transform rounded-md bg-primary hover:bg-opacity-80">
                  {selectedGroup ? "Update" : "Create"}
                </button>
              </div>
            )}
          </fieldset>
        </Form>
      )}
    </Formik>
  );
};

export default InviteForm;
