import { useQuery } from "react-query";

import networkService from "@services/network.service";
import { useAuthStore } from "@store/index";
import { IUserGroup } from "@/interfaces/user-groups.interface";

interface IUserGroupsResponse {
  data: IUserGroup[];
  ok: number;
}

export const getUserGroups = async (orgId: string, params) => {
  const { data, ok } = await networkService.get<IUserGroupsResponse>(
    `organizations/groups`,
    params,
    {
      headers: {
        "ORG-ID": orgId
      }
    }
  );

  if (ok) {
    return data || [];
  } else return null;
};

export const useGetUserGroups = (
  params: any = {},
  successCb: ((data: IUserGroup[]) => void) | null = null
) => {
  const [user] = useAuthStore((state) => [state.user]);
  const res = useQuery(
    ["getUserGroups", user.selectedOrg.id, params],
    async () => {
      const userGroups = await getUserGroups(user.selectedOrg.id, params);

      successCb && successCb(userGroups);

      return userGroups;
    },
    {
      enabled: !!user.selectedOrg.id
    }
  );

  return res;
};
