import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import PanelCreationSteps from "./components/panel-creation-steps.component";
import { useAuthStore, useDashboardStore } from "../../store";

//Panels
import {
  BarChart,
  LineChart,
  MetricChart,
  PieChart,
  GaugeChart,
  TempChart,
  HeatMap
} from "./components/graphs";
import { chartdata, data, metricData } from "./sample-data";
import Table from "./components/table/dash-table.component";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { DASH_PANEL_TYPE } from "@/interfaces/dashboard-panel.interface";
import DashMapComponent from "./components/map/dash-map-component";
import { ILocation } from "@/interfaces";

interface IPanelType {
  name: string;
  description: string;
  type: string;
  component: React.ReactNode;
}

function DashboardNewPanelType() {
  const navigate = useNavigate();

  const [updateAuthUser] = useAuthStore((state) => [state.updateAuthUser]);
  const [createPanelState, activeDashboard, setCreatePanelState] =
    useDashboardStore((state) => [
      state.createPanelState,
      state.activeDashboard,
      state.setCreatePanelState
    ]);

  const [activePanel, setActivePanel] = useState(0);

  useEffect(() => {
    updateAuthUser({ panelCreationStatus: "0" });
  }, [updateAuthUser]);

  const handleClick = () => {
    const selectedPanel = PANELS[activePanel].type;

    setCreatePanelState({
      ...createPanelState,
      selectedPanel
    });
    navigate("/dashboard/new-panel-options/");
  };

  const PANELS: Array<IPanelType> = [
    {
      name: "Line Chart",
      description: "A single widget with multiple tag based graph",
      type: DASH_PANEL_TYPE.LINE_CHART,
      component: (
        <LineChart
          title={createPanelState.panelName}
          chartdata={chartdata}
          categories={["Item A", "Item B", "Item C"]}
          colors={["yellow", "blue", "green"]}
          index="temp"
          showExample
        />
      )
    },
    {
      name: "Bar Chart",
      description: "A single widget with multiple tag based graph",
      type: DASH_PANEL_TYPE.BAR_CHART,
      component: (
        <BarChart
          title={createPanelState.panelName}
          chartdata={chartdata}
          categories={["Item A", "Item B", "Item C"]}
          colors={["yellow", "blue", "green"]}
          index="temp"
          showExample
        />
      )
    },
    {
      name: "Pie Chart",
      description: "A single widget with multiple tag based graph",
      type: DASH_PANEL_TYPE.PIE_CHART,
      component: (
        <PieChart
          title={createPanelState.panelName}
          data={data}
          category="Item A"
          colors={["yellow", "blue", "green"]}
          index="hosts"
          variant="donut"
        />
      )
    },
    {
      name: "Gauge 1",
      description: "A single widget with multiple tag based graph",
      type: DASH_PANEL_TYPE.GAUGE_1,
      component: (
        <GaugeChart
          minValue={120}
          maxValue={180}
          min={0}
          max={240}
          title={createPanelState.panelName}
          showExample
        />
      )
    },
    {
      name: "Gauge 2",
      description: "A single widget with multiple tag based graph",
      type: DASH_PANEL_TYPE.GAUGE_2,
      component: (
        <TempChart
          title={createPanelState.panelName}
          minValue={120}
          maxValue={180}
          min={0}
          max={240}
          showExample
        />
      )
    },
    {
      name: "Number Infographic",
      description: "A single widget with multiple tag based graph",
      type: DASH_PANEL_TYPE.NUMERIC,
      component: (
        <MetricChart
          datakey="amount"
          category="year"
          dataPrev={metricData[0]}
          dataCurr={metricData[1]}
          title={createPanelState.panelName}
        />
      )
    },
    {
      name: "Heatmap",
      description: "A single widget with multiple tag based graph",
      type: "HEATMAP",
      component: <HeatMap title="Heatmap" showSampleHeatmap />
    },
    {
      name: "Table",
      description: "A single widget with multiple tag based graph",
      type: DASH_PANEL_TYPE.TABLE,
      component: <Table title="Table" showSampleTable={true} />
    }
  ];

  return (
    <main className="flex-1 mx-8 mt-7 space-y-4 mr-28 overflow-y-auto lg:px-8 sm:px-6 bg-background text-contentColor rounded-md">
      <div className="flex w-full h-full">
        <div className="w-6/12 flex-grow h-full">
          <div className="mt-7 mb-5 w-11/12">
            <PanelCreationSteps />
          </div>
          <div>
            <h1 className="text-lg text-left font-medium mb-2.5">
              Type of Panel
            </h1>
            <div className="lh:h-[95%] md:h-[44vh] relative overflow-auto flex flex-col pr-2">
              {PANELS.map((panel, index) => {
                return (
                  <div
                    key={index}
                    className={`
                        w-full h-auto border-2 border-background-layer3 rounded-lg px-4 py-2 my-2
                        ${index === activePanel ? "border-[#546CCC]" : ""}
                        cursor-pointer
                    `}
                    onClick={() => {
                      setActivePanel(index);
                    }}
                  >
                    <h1
                      className={`text-base text-left font-medium ${
                        index === activePanel ? "text-[#546CCC]" : ""
                      }`}
                    >
                      {panel.name}
                    </h1>
                    <span
                      className={`text-sm text-left text-contentColorLight`}
                    >
                      {panel.description}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="w-11/12 flex items-center mt-2 space-x-4">
            <Link
              to={
                "/dashboard" +
                (activeDashboard.id ? "/" + activeDashboard.id : "")
              }
              className="w-1/2 px-8 py-3 space-x-3 font-medium text-center transition-colors duration-200 transform border rounded-md focus:outline-none text-primary border-primary hover:bg-white"
            >
              Cancel
            </Link>

            <button
              onClick={handleClick}
              className="w-1/2 px-8 py-3 font-medium text-center text-white transition-colors duration-200 transform rounded-md focus:outline-none bg-primary hover:bg-opacity-80 "
            >
              Next
            </button>
          </div>
        </div>

        <div className="w-6/12">
          <div className="ml-8 mt-7 lg:h-[90%] md:h-full bg-background-layer0.5 rounded relative">
            <div className="px-5 pt-7 mb-7 relative">
              <div className="flex items-center">
                <InformationCircleIcon width={16} className="text-primary" />

                <h1 className="font-medium text-sm ml-3.5 text-primary">
                  Example Chart
                </h1>
              </div>
              <div className="h-full flex justify-center items-center">
                <div className="w-[95%] h-max flex pb-2 flex-col bg-background-layer1 my-5 rounded-md">
                  {PANELS[activePanel].component}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default DashboardNewPanelType;
