import { useMutation, useQueryClient } from "react-query";

import networkService from "@services/network.service";
import { useAuthStore, useFleetAndDevicesStore } from "@store/index";

export const deleteProject = async (projectId: string, orgId: string) => {
  const { ok } = await networkService.delete<{ ok: number }>(
    `projects/${projectId}/`,
    {},
    {
      headers: {
        "ORG-ID": orgId
      }
    }
  );

  return ok;
};

export const useDeleteProject = () => {
  const [selectedProject] = useFleetAndDevicesStore((state) => [
    state.selectedProject
  ]);
  const [user] = useAuthStore((state) => [state.user]);
  const queryClient = useQueryClient();

  const res = useMutation(
    async () => await deleteProject(selectedProject.id, user.selectedOrg.id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getProjects");
      }
    }
  );

  return res;
};
