import { useMutation, useQueryClient } from "react-query";

import networkService from "@services/network.service";
import { useAuthStore } from "@store/index";

export interface ICreateAuthUserPayload {
  username: string;
  first_name: string;
  last_name: string;
  email: string;
  email_verified: boolean;
  initial_password: string;
}

interface ICreateAuthUserResponse {
  ok: number;
}

export const createAuthUser = async (
  groupId: string,
  orgId: string,
  payload: ICreateAuthUserPayload
) => {
  const { ok } = await networkService.post<ICreateAuthUserResponse>(
    `organizations/groups/${groupId}/custom_auth/users`,
    payload,
    {
      headers: {
        "ORG-ID": orgId
      }
    }
  );

  return ok;
};

export const useCreateAuthUser = (groupId: string) => {
  const [user] = useAuthStore((state) => [state.user]);
  const queryClient = useQueryClient();

  const res = useMutation(
    async (data: ICreateAuthUserPayload) =>
      await createAuthUser(groupId, user.selectedOrg.id, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getUserGroupDetails");
      }
    }
  );

  return res;
};
