import { useMutation, useQueryClient } from "react-query";

import networkService from "@services/network.service";
import { useAuthStore } from "@store/index";

export const restoreProject = async (projectId: string, orgId: string) => {
  const { ok } = await networkService.post<{ ok: number }>(
    `projects/${projectId}/restore`,
    {},
    {
      headers: {
        "ORG-ID": orgId
      }
    }
  );

  return ok;
};

export const useRestoreProject = () => {
  const [user] = useAuthStore((state) => [state.user]);
  const queryClient = useQueryClient();

  const res = useMutation(
    async (projectId: string) =>
      await restoreProject(projectId, user.selectedOrg.id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getProjects");
      }
    }
  );

  return res;
};
