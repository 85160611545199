import React from "react";

interface ISettingsCommonHeaderProps {
  title: string;
  subtitle?: React.ReactNode;
}

function SettingsCommonHeader({
  title,
  subtitle
}: ISettingsCommonHeaderProps) {
  return (
    <div className="bg-background text-contentColor border-background-layer3 border-b lg:px-8 sm:px-6 ">
      <div className="flex items-center py-5">
        <div className="flex flex-col justify-center mt-3">
          <h1 className="text-2xl font-medium mr-1.5">{title}</h1>
          {subtitle ? (
            <span className="text-contentColorLight mt-2 text-sm">
              {subtitle}
            </span>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default SettingsCommonHeader;
