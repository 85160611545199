import { Badge } from "@tremor/react";

interface NewTagProps extends React.HTMLAttributes<HTMLDivElement> {}

const NewTag: React.FC<NewTagProps> = ({ className, ...props }) => {
  return (
    <Badge size="xs" className={`px-1.5 py-0 !rounded-md ${className}`}>
      <span className="!text-xs">New</span>
    </Badge>
  );
};

export default NewTag;
