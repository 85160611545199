import { useDisableUserGroupCustomAuth } from "@/app/shared/hooks/delete/disable-custom-auth-user-groups";
import { useEnableUserGroupCustomAuth } from "@/app/shared/hooks/post/enable-user-group-custom-auth";
import { useConfirmDelete } from "@/app/shared/hooks/use-confirm-delete.hooks";
import { copyToClipboard } from "@/app/shared/utils/helper.util";
import { IUserGroup } from "@/interfaces/user-groups.interface";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import { Button } from "@tremor/react";
import { Field, Formik, Form } from "formik";
import React from "react";
import { toast } from "react-toastify";
import { Tooltip } from "react-tooltip";

type IUserGroupCustomAuthProps = {
  userGroup: IUserGroup;
};

const UserGroupCustomAuth: React.FC<IUserGroupCustomAuthProps> = ({
  userGroup
}) => {
  const enableCustomAuthMutation = useEnableUserGroupCustomAuth(userGroup.id);
  const disableCustomAuthMutation = useDisableUserGroupCustomAuth(
    userGroup.id
  );

  const { openConfirmDeleteModal } = useConfirmDelete();

  const onCustomAuthEnable = () => {
    enableCustomAuthMutation.mutate(
      {},
      {
        onSuccess: () => {
          toast.success("Custom Auth Enabled");
        }
      }
    );
  };

  const onCustomAuthDisable = () => {
    openConfirmDeleteModal(() => {
      disableCustomAuthMutation.mutate(
        {},
        {
          onSuccess: () => {
            toast.success("Custom Auth Disabled");
          }
        }
      );
    }, "Are you sure you want to disable custom auth?");
  };

  return userGroup.external_auth_org === null ? (
    <div className="flex-1 flex flex-col items-center justify-center">
      <h1 className="text-2xl text-center font-medium mb-2.5">
        Enable Custom Auth
      </h1>
      <p className="text-xs text-contentColorLight text-center w-[468px]">
        Enable custom auth to allow users to sign in using
        <br /> their own authentication organization on Zitadel.
      </p>

      <Button
        className="mt-6"
        loading={enableCustomAuthMutation.isLoading}
        onClick={onCustomAuthEnable}
      >
        Enable Custom Auth
      </Button>
    </div>
  ) : (
    <>
      <Formik
        initialValues={{ orgName: "", website: "", admin: "" }}
        onSubmit={() => {}}
      >
        <Form className="flex flex-col flex-1">
          <div>
            <h1 className="text-lg text-left font-medium mb-2.5">
              Auth Details
            </h1>

            <div className="flex-1 w-[396px]">
              <div className="mb-5">
                <label className="text-sm font-medium text-contentColorLight">
                  Zitadel Org ID
                </label>

                <div className="flex items-end">
                  <Field
                    type="text"
                    id="orgName"
                    name="orgName"
                    value={userGroup.external_auth_org}
                    placeholder="Org Name"
                    className="block bg-background w-10/12 p-3 mt-2 border-background-layer3 rounded-md focus:ring focus:ring-opacity-40 focus:ring-primary focus:border-primaryLight sm:text-sm"
                  />
                  <div>
                    <button
                      type="button"
                      onClick={() =>
                        copyToClipboard(userGroup.external_auth_org)
                      }
                      className="block ml-3.5 bg-[#166ff629] p-3 rounded"
                    >
                      <svg
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.41667 5.50016V2.75016C6.41667 2.50705 6.51324 2.27389 6.68515 2.10198C6.85706 1.93007 7.09022 1.8335 7.33333 1.8335H18.3333C18.5764 1.8335 18.8096 1.93007 18.9815 2.10198C19.1534 2.27389 19.25 2.50705 19.25 2.75016V15.5835C19.25 15.8266 19.1534 16.0598 18.9815 16.2317C18.8096 16.4036 18.5764 16.5002 18.3333 16.5002H15.5833V19.2502C15.5833 19.7562 15.1708 20.1668 14.6603 20.1668H3.67308C3.5522 20.1676 3.43236 20.1444 3.32046 20.0987C3.20856 20.0529 3.1068 19.9855 3.02102 19.9004C2.93524 19.8152 2.86714 19.7139 2.82063 19.6023C2.77412 19.4907 2.75012 19.371 2.75 19.2502L2.75275 6.41683C2.75275 5.91083 3.16525 5.50016 3.67492 5.50016H6.41667ZM4.58517 7.3335L4.58333 18.3335H13.75V7.3335H4.58517ZM8.25 5.50016H15.5833V14.6668H17.4167V3.66683H8.25V5.50016ZM6.41667 10.0835H11.9167V11.9168H6.41667V10.0835ZM6.41667 13.7502H11.9167V15.5835H6.41667V13.7502Z"
                          fill="#546CCC"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              <div className="mb-5">
                <label className="flex gap-2 text-sm font-medium text-contentColorLight">
                  Auth Scope
                  <span data-tooltip-id="auth-scope-help-tooltip">
                    <QuestionMarkCircleIcon width={20} />
                  </span>
                </label>

                <div className="flex items-end">
                  <Field
                    type="text"
                    id="created_at"
                    name="created_at"
                    value={`urn:zitadel:iam:org:id:${userGroup.external_auth_org}`}
                    placeholder=""
                    className="block bg-background w-10/12 p-3 mt-2 border-background-layer3 rounded-md focus:ring focus:ring-opacity-40 focus:ring-primary focus:border-primaryLight sm:text-sm"
                  />
                  <div>
                    <button
                      type="button"
                      onClick={() =>
                        copyToClipboard(
                          `urn:zitadel:iam:org:id:${userGroup.external_auth_org}`
                        )
                      }
                      className="block ml-3.5 bg-[#166ff629] p-3 rounded"
                    >
                      <svg
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.41667 5.50016V2.75016C6.41667 2.50705 6.51324 2.27389 6.68515 2.10198C6.85706 1.93007 7.09022 1.8335 7.33333 1.8335H18.3333C18.5764 1.8335 18.8096 1.93007 18.9815 2.10198C19.1534 2.27389 19.25 2.50705 19.25 2.75016V15.5835C19.25 15.8266 19.1534 16.0598 18.9815 16.2317C18.8096 16.4036 18.5764 16.5002 18.3333 16.5002H15.5833V19.2502C15.5833 19.7562 15.1708 20.1668 14.6603 20.1668H3.67308C3.5522 20.1676 3.43236 20.1444 3.32046 20.0987C3.20856 20.0529 3.1068 19.9855 3.02102 19.9004C2.93524 19.8152 2.86714 19.7139 2.82063 19.6023C2.77412 19.4907 2.75012 19.371 2.75 19.2502L2.75275 6.41683C2.75275 5.91083 3.16525 5.50016 3.67492 5.50016H6.41667ZM4.58517 7.3335L4.58333 18.3335H13.75V7.3335H4.58517ZM8.25 5.50016H15.5833V14.6668H17.4167V3.66683H8.25V5.50016ZM6.41667 10.0835H11.9167V11.9168H6.41667V10.0835ZM6.41667 13.7502H11.9167V15.5835H6.41667V13.7502Z"
                          fill="#546CCC"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              <div className="mb-5">
                <label className="flex gap-2 text-sm font-medium text-contentColorLight">
                  Disable Custom Auth
                </label>

                <div className="flex items-end">
                  <Button
                    color="red"
                    className="mt-2"
                    loading={disableCustomAuthMutation.isLoading}
                    onClick={onCustomAuthDisable}
                  >
                    Disable Custom Auth
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </Formik>
      <Tooltip
        variant="light"
        className="shadow-lg"
        id="auth-scope-help-tooltip"
        clickable
      >
        <span className="text-xs">
          Limit the scope of the authentication to a specific organization.{" "}
          <br />
          To use, add an environment variable called{" "}
          <b>REACT_APP_ZITADEL_SCOPE</b>
          <br />
          with the value given below.
        </span>
      </Tooltip>
    </>
  );
};

export default UserGroupCustomAuth;
