import { IHeatMap } from "@interfaces/index";
import { useEffect, useId, useMemo, useState } from "react";
import { HeatMapGrid } from "react-grid-heatmap";
import {
  RANDOM_DATA,
  calculateInterpolationFraction,
  initialValues,
  interpolateColors
} from "../forms/heatmap.helper";
import dateService from "@services/date.service";
import "./heatmap.style.css";
import { Tooltip } from "react-tooltip";
import { formatNumber } from "@/app/shared/utils/dashboard.utils";

/* 
    Component: Headmap
    Props:  {
        title : Title of heatmap [ panel name ]
        configValues : {
            minColor : color for min value
            maxColor : color for max value
            startDate : start date for heatmap
            endDate : end date for heatmap
            timeBucket : time bucket for heatmap
            timeBucketUnit : time bucket unit for heatmap
            aggregationMode : aggregation mode for heatmap
            showValues : show values in heatmap
            boxRoundness : box roundness for heatmap
            timePeriod [ -1d, -1m, -3min ] : {
              start : start date for heatmap 
            }
            rowsCount : rows count for heatmap
        }
        data : data for heatmap 
          
    }
    Author: Yash Kandalkar
*/

const WEEKDAY_LABELS = ["Mon", "Tue", "Wed", "Thur", "Fri", "Sat", "Sun"];

const HeatMap: React.FC<IHeatMap> = ({
  title,
  configValues = initialValues,
  data,
  zoom = 100,
  showSampleHeatmap = false
}) => {
  const [bounds, setBounds] = useState({ min: Infinity, max: -Infinity });
  const [xLabels, setXLabels] = useState([]);
  const [yLabels, setYLabels] = useState([]);
  const [showHeatmap, setShowHeatmap] = useState(false);
  const [adjustedRowsCount, setAdjustedRowsCount] = useState(
    configValues.rowsCount
  );

  const tooltipId = useId();

  const validData = useMemo(() => {
    return (
      data &&
      Object.keys(data).length &&
      configValues.param &&
      data[configValues.param?.value]?.length
    );
  }, [configValues.param, data]);

  const numOfBuckets = validData
    ? data[configValues?.param?.value]?.length
    : 20;

  useEffect(() => {
    // numOfBuckets can be smaller than rowsCount, so we need to adjust the rowsCount
    if (numOfBuckets < configValues.rowsCount) {
      setAdjustedRowsCount(numOfBuckets);
    } else {
      setAdjustedRowsCount(configValues.rowsCount);
    }
  }, [numOfBuckets, configValues.rowsCount]);

  useEffect(() => {
    if (!validData) {
      return;
    }

    let firstTimestampDay = dateService.getDay(
      dateService.convertUTCDateToUserTimezone(
        data[configValues.param.value][0].time
      )
    );

    // create yLabels
    switch (configValues.timeBucketUnit) {
      case "days":
        if (adjustedRowsCount === 7) {
          setYLabels(
            WEEKDAY_LABELS.slice(firstTimestampDay - 1).concat(
              WEEKDAY_LABELS.slice(0, firstTimestampDay - 1)
            )
          );
        } else {
          setYLabels(
            data[configValues.param.value]
              .slice(0, adjustedRowsCount)
              .map((d) =>
                dateService
                  .convertUTCDateToUserTimezone(d.time)
                  .format("DD-MM HH:mm")
              )
          );
        }
        break;
      case "hours":
        setYLabels(
          data[configValues.param.value]
            .slice(0, adjustedRowsCount)
            .map((d) =>
              dateService
                .convertUTCDateToUserTimezone(d.time)
                .format("DD-MM HH:mm")
            )
        );
        break;
      case "mins":
        setYLabels(
          data[configValues.param.value]
            .slice(0, adjustedRowsCount)
            .map((d) =>
              dateService
                .convertUTCDateToUserTimezone(d.time)
                .format("DD-MM HH:mm")
            )
        );
        break;
      default:
        setYLabels(
          data[configValues.param.value]
            .slice(0, adjustedRowsCount)
            .map((d) =>
              dateService
                .convertUTCDateToUserTimezone(d.time)
                .format("DD-MM HH:mm")
            )
        );
    }

    // create xLabels
    // select all elements from the data list with adjustedRowsCount step
    const step = adjustedRowsCount;

    setXLabels(
      data[configValues.param.value]
        .filter((_, i) => i % step === 0)
        .map((d) => {
          switch (configValues.timeBucketUnit) {
            case "days":
              return dateService
                .convertUTCDateToUserTimezone(d.time)
                .format("DD-MM HH:mm");
            case "hours":
              return dateService
                .convertUTCDateToUserTimezone(d.time)
                .format("DD/MM HH:mm");
            case "mins":
              return dateService
                .convertUTCDateToUserTimezone(d.time)
                .format("DD/MM HH:mm");
            default:
              return dateService
                .convertUTCDateToUserTimezone(d.time)
                .format("DD/MM HH:mm");
          }
        })
    );

    return () => {};
  }, [data, configValues, adjustedRowsCount, numOfBuckets, validData]);

  useEffect(() => {
    setTimeout(() => {
      setShowHeatmap(true);
    }, 500);
  }, []);

  let dataT = useMemo(() => {
    let colsCount = Math.ceil(numOfBuckets / configValues.rowsCount);
    // convert flat data to 2D array of size rowsCount x Math.ceil(numOfBuckets / rowsCount)
    const convertedData = [];
    let pushed = 0;
    let min = Infinity,
      max = -Infinity;

    // create rows
    for (let i = 0; i < adjustedRowsCount; ++i) {
      convertedData.push([]);
    }

    // fill data vertically (dummy data)
    for (let i = 0; i < colsCount; ++i) {
      for (let j = 0; j < adjustedRowsCount; ++j) {
        if (pushed < numOfBuckets) {
          const val = validData
            ? data[configValues.param.value][pushed].value[
                configValues.aggregationMode
              ]
            : RANDOM_DATA[i * adjustedRowsCount + j];
          if (val > max) {
            max = val;
          }
          if (val < min) {
            min = val;
          }

          convertedData[j].push(val);
          pushed++;
        }
      }
    }

    setBounds({ min, max });

    return convertedData;
  }, [numOfBuckets, configValues, data, adjustedRowsCount, validData]);

  function getCellLabelT(row, col) {
    const cellCount = col * adjustedRowsCount + row;
    const cellDate = validData
      ? dateService.convertUTCDateToUserTimezone(
          data[configValues.param.value][cellCount].time
        )
      : dateService.getMomentDate();

    switch (configValues.timeBucketUnit) {
      case "days":
        return cellDate.format("DD-MM-YYYY");
      case "hours":
        return cellDate.format("DD-MM-YYYY HH:mm");
      case "mins":
        return cellDate.format("DD-MM-YYYY HH:mm");
      default:
        return cellDate.format("DD-MM-YYYY");
    }
  }

  return (
    <>
      <div className="relative h-full w-full flex flex-col">
        <div
          onMouseDown={(e) => {
            // to prevent react-grid-layout from dragging
            e.preventDefault();
            e.stopPropagation();
          }}
          style={{ zoom: zoom.toString() + "%" }}
          className="p-4 py-2 mb-6 flex-grow heatmap-container max-h-[75%] text-sm flex flex-col w-full overflow-auto"
        >
          {(showSampleHeatmap || validData) && showHeatmap ? (
            <HeatMapGrid
              xLabels={xLabels}
              yLabels={yLabels}
              yLabelsPos={"left"}
              xLabelsPos={"top"}
              data={dataT}
              square
              cellHeight={"20px"}
              onClick={(x, y) => getCellLabelT(x, y)}
              cellStyle={(x, y, ratio) => {
                return {
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  background: dataT[x][y]
                    ? interpolateColors(
                        configValues.minColor,
                        configValues.maxColor,
                        calculateInterpolationFraction(
                          bounds.min,
                          bounds.max,
                          dataT[x][y]
                        )
                      )
                    : "transparent",
                  fontSize: "8px",
                  color: "#444",
                  borderRadius: configValues?.boxRoundness
                    ? configValues.boxRoundness + "%"
                    : "10%",
                  // borderWidth: 0,
                  borderColor: "theme('colors.gray.200')"
                };
              }}
              cellRender={(x, y, value) => {
                return (
                  <a
                    key={x + "-" + y}
                    href="/"
                    onClick={(e) => {
                      e.preventDefault();
                      getCellLabelT(x, y);
                    }}
                    data-tooltip-id={"heatmap-tooltip-" + tooltipId}
                    data-tooltip-cell-label={getCellLabelT(x, y)}
                    data-tooltip-cell-value={formatNumber(value)}
                    data-tooltip-place="top"
                    className="flex justify-center items-center w-full h-full m-0"
                  >
                    {configValues?.showValues && value
                      ? value.toFixed(0)
                      : null}
                  </a>
                );
              }}
            />
          ) : (
            "No Data Available"
          )}

          <Tooltip
            id={"heatmap-tooltip-" + tooltipId}
            render={({ content, activeAnchor }) =>
              activeAnchor?.getAttribute("data-tooltip-cell-value") ? (
                <div className="flex flex-col items-center">
                  <span className="text-gray-400 text-sm">
                    {activeAnchor?.getAttribute("data-tooltip-cell-label") +
                      ":" ?? "not set"}
                  </span>
                  <span>
                    {" "}
                    {activeAnchor?.getAttribute("data-tooltip-cell-value") ??
                      "not set"}
                  </span>
                </div>
              ) : null
            }
          />
        </div>
        <div className="flex justify-end absolute right-2 bottom-8">
          <div className="flex flex-row gap-3 items-start">
            <div className="flex gap-1 text-sm justify-center items-center ">
              <div
                style={{
                  background: configValues.minColor,
                  width: 15,
                  height: 15,
                  border: "1px solid #aaa",
                  borderRadius: configValues.boxRoundness + "%"
                }}
              />
              <div>Min: {formatNumber(bounds.min)}</div>
            </div>
            <div className="flex gap-1 text-sm justify-center items-center ">
              <div
                style={{
                  background: configValues.maxColor,
                  width: 15,
                  height: 15,
                  border: "1px solid #222",
                  borderRadius: configValues.boxRoundness + "%"
                }}
              />
              <div>Max: {formatNumber(bounds.max)}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeatMap;
