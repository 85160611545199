import { XMarkIcon } from "@heroicons/react/24/outline";

interface addOverlayLayoutProps {
  overlayOpen: boolean;
  setOverlayOpen: (open: boolean) => void;
  title: string;
  children: React.ReactNode;
}

const addOverlayLayout: React.FC<addOverlayLayoutProps> = ({
  overlayOpen,
  setOverlayOpen,
  title,
  children
}) => {
  return (
    <div className="text-contentColor bg-background font-dm-sans">
      <div
        onClick={() => setOverlayOpen(false)}
        className={
          "fixed inset-0 z-40 transition-opacity bg-contentColor opacity-50 " +
          (overlayOpen ? "block" : "hidden")
        }
      ></div>

      <div
        className={
          "fixed inset-y-0 right-0 z-50 flex flex-col bg-background h-screen overflow-y-auto transition duration-200 transform border-r border-background-layer3 w-full sm:w-[28rem] " +
          (overlayOpen ? "translate-x-0 ease-in" : "translate-x-full ease-out")
        }
        id="overlay"
      >
        <div className="flex items-center justify-between px-6 mt-12 py-2 space-x-4 sm:space-x-0">
          <div>
            <h3 className="text-2xl font-medium text-contentColor">{title}</h3>
          </div>

          <button
            onClick={() => setOverlayOpen(false)}
            className="text-contentColorLight focus:text-primary focus:outline-none"
          >
            <XMarkIcon className="text-contentColor" width={16} />
          </button>
        </div>

        <main className="flex flex-col flex-1 px-6 pb-6">{children}</main>
      </div>
    </div>
  );
};

export default addOverlayLayout;
