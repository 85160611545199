import { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import CreateBlueprintInfo from "./create-device-blueprint-info.component";
import { ApplyDataPoints, ShadowPolicy } from "@/app/fleet-and-devices";
import {
  ICreateFleetDeviceBlueprintPayload,
  useCreateFleetDeviceBlueprint
} from "@/app/shared/hooks/post/create-device-blueprint";
import { IDataPointDefinition } from "@/interfaces";
import { useFleetAndDevicesStore } from "@/store";
import { toast } from "react-toastify";

// import DeviceCreationSteps from "../components/device-creation-steps.component";
// import SuggestionPanel from "../components/fad-suggestion-panel.component";

const validationSchema = yup.object({
  deviceBlueprintName: yup.string().required("Blueprint Name is required.")
});

function CreateDeviceBlueprint() {
  const navigate = useNavigate();

  const selectedFleet = useFleetAndDevicesStore(
    (state) => state.selectedFleet
  );

  const [createBlueprintStep, setCreateBlueprintStep] = useState(0);
  const [infoFormState, setInfoFormState] = useState({
    deviceBlueprintName: "",
    deviceNamePrefix: "",
    deviceNameSuffix: "",
    selectedShadowDefId: ""
  });

  const createDeviceBlueprintMutation = useCreateFleetDeviceBlueprint();

  const handleSubmit = (
    dataPointPolicyId: string,
    selectedDps: IDataPointDefinition[]
  ) => {
    const createDeviceBlueprintPayload: ICreateFleetDeviceBlueprintPayload = {
      template_name: infoFormState.deviceBlueprintName,
      template: {
        device_suffix: infoFormState.deviceNameSuffix,
        device_prefix: infoFormState.deviceNamePrefix,
        shadow_definition_id: infoFormState.selectedShadowDefId,
        device_data_point_config: selectedDps?.map((dp) => ({
          data_point_name: dp.name,
          data_point_definition_id: dp.id,
          device_data_policy_id: dataPointPolicyId,
          active: true
        }))
      }
    };

    createDeviceBlueprintMutation.mutate(
      {
        data: createDeviceBlueprintPayload,
        fleetId: selectedFleet.id
      },
      {
        onSuccess: (templateId) => {
          toast.success(
            `Created device template '${infoFormState.deviceBlueprintName}' successfully.`
          );
          navigate(`/blueprints/devices/${templateId}`);
        }
      }
    );
  };

  return (
    <>
      <div className="flex w-full h-full">
        <div className="pb-8">
          <div className="mt-7 mb-5 w-10/12">
            {/* <DeviceCreationSteps /> */}
            <h1 className="text-lg text-left font-medium mb-2.5">
              Create Device Blueprint
            </h1>
          </div>

          {createBlueprintStep === 0 ? (
            <CreateBlueprintInfo
              initialState={infoFormState}
              onCancel={() => navigate("/blueprints/devices")}
              onNext={(inputFieldValues: typeof infoFormState) => {
                setInfoFormState((prev) => ({
                  ...prev,
                  ...inputFieldValues
                }));
                setCreateBlueprintStep(1);
              }}
            />
          ) : createBlueprintStep === 1 ? (
            <ShadowPolicy
              hideSteps
              backClick={() => {
                setCreateBlueprintStep(0);
              }}
              nextClick={(shadowDefId: string) => {
                setInfoFormState((prev) => ({
                  ...prev,
                  selectedShadowDefId: shadowDefId
                }));
                setCreateBlueprintStep(2);
              }}
            />
          ) : createBlueprintStep === 2 ? (
            <ApplyDataPoints
              hideSteps
              nextButtonLoading={createDeviceBlueprintMutation.isLoading}
              onBackClick={() => {
                setCreateBlueprintStep(1);
              }}
              onNextClick={handleSubmit}
            />
          ) : null}
        </div>

        <div className="">{/* <SuggestionPanel type="device" /> */}</div>
      </div>
    </>
  );
}

export default CreateDeviceBlueprint;
