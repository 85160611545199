import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Field, Form, Formik } from "formik";
import { FieldError } from "../shared/components";
import useOnboardingStore, {
  IOnboardingDetails
} from "../../store/onboarding/onboarding.store";
import { toast } from "react-toastify";
import {
  useAuthStore,
  useDashboardStore,
  useFleetAndDevicesStore
} from "../../store";
import { data } from "../shared/utils/data-file.util";
import { useGetOrganizations } from "@app/shared/hooks/get/organizations";
import {
  ICreateOrgPayload,
  useCreateOrg
} from "@app/shared/hooks/post/create-org";
import { Button } from "@tremor/react";
import { useCreateProject } from "../shared/hooks/post/create-project";
import { useGetProjects } from "../shared/hooks/get";
import ReactFlagsSelect from "react-flags-select";
import useWalkthroughStore from "@/store/walkthrough.store";

function OnboardingIndividualDetails() {
  const navigate = useNavigate();
  const [updateAuthUser] = useAuthStore((state) => [
    state.updateAuthUser
  ]);
  const [user] = useAuthStore((state) => [state.user]);

  const [onboardingDetails, setOnboardingDetails] = useOnboardingStore(
    (state) => [state.onboardingDetails, state.setOnboardingDetails]
  );
  const [errors, setErrors] = useState({
    firstName: false,
    lastName: false,
    email: false,
    country: false
  });

  const [
    setSelectedProject,
    setProjects,
    setFleets,
    setDevices,
    setCombinedDevices
  ] = useFleetAndDevicesStore((state) => [
    state.setSelectedProject,
    state.setProjects,
    state.setFleets,
    state.setDevices,
    state.setCombinedDevices
  ]);

  const [setDashboards] = useDashboardStore((state) => [state.setDashboards]);
  const [setShownOverviewTour] = useWalkthroughStore((state) => [
    state.setShownOverview
  ]);

  useEffect(() => {
    setOnboardingDetails({
      ...onboardingDetails,
      email: user.email
    });
    updateAuthUser({
      onboardingStatus: "1"
    });
  }, [user.email]);

  const { refetch: refetchOrgs } = useGetOrganizations();
  const createOrgMutation = useCreateOrg();
  const createProjectMutation = useCreateProject();
  const { refetch: refetchProjects } = useGetProjects();

  const [createButtonLoading, setCreateButtonLoading] = useState(false);
  const [createButtonStatus, setCreateButtonStatus] = useState("Next");

  const isError = () => {
    let err = false;

    let newErrs = { ...errors };
    if (!onboardingDetails.email?.trim()) {
      newErrs["email"] = true;
      err = true;
    } else {
      newErrs["email"] = false;
    }

    if (!onboardingDetails.firstName?.trim()) {
      newErrs["firstName"] = true;
      err = true;
    } else {
      newErrs["firstName"] = false;
    }

    if (!onboardingDetails.lastName?.trim()) {
      newErrs["lastName"] = true;

      err = true;
    } else {
      newErrs["lastName"] = false;
    }

    if (!onboardingDetails.country?.trim()) {
      newErrs["country"] = true;

      err = true;
    } else {
      newErrs["country"] = false;
    }

    setErrors(newErrs);

    return err;
  };

  const handleSubmit = async (
    values: {
      firstName: string;
      lastName: string;
      // mobileNumber: string;
      email: string;
    },
    { resetForm }
  ) => {
    if (isError()) {
      return;
    }
    const orgCreationPayload: ICreateOrgPayload = {
      email: onboardingDetails.email,
      firstname_user: onboardingDetails.firstName,
      lastname_user: onboardingDetails.lastName,
      org_name: onboardingDetails.org_name,
      type: onboardingDetails.type
    };

    setCreateButtonLoading(true);
    setCreateButtonStatus("Settings things up");

    createOrgMutation.mutate(orgCreationPayload, {
      onSuccess: (orgId) => {
        setCreateButtonStatus("Creating a Project");
        refetchOrgs().then(({ data }) => {
          const newOrg = data.find((org) => org.id === orgId);

          updateAuthUser({
            selectedOrg: newOrg,
            organization: data
          });

          const projCreationPayload = {
            project_name: "default",
            region: "IN",
            hosting_platform: "AWS"
          };

          setTimeout(() => {
            setCreateButtonStatus("Provisioning Resources");

            createProjectMutation.mutate(projCreationPayload, {
              onSuccess: (id) => {
                setCreateButtonStatus("Almost There!");

                refetchProjects().then(({ data: projects }) => {
                  const newProj = projects.find((p) => p.id === id);
                  setSelectedProject(newProj);
                  setProjects(projects);

                  setFleets([]);
                  setDevices([]);
                  setCombinedDevices([]);
                  setDashboards([]);

                  setOnboardingDetails({
                    country: "IN"
                  } as IOnboardingDetails);

                  setCreateButtonStatus("Done!");
                  setCreateButtonLoading(false);

                  setShownOverviewTour(false);
                  toast.success("We've created a default project for you!");

                  navigate("/home", {
                    state: { showOverviewTourModal: true }
                  });
                });
              },
              onError: () => {
                setCreateButtonStatus("Next");
                setCreateButtonLoading(false);
              }
            });
          });
        });
      },
      onError: () => {
        setCreateButtonLoading(false);
        setCreateButtonStatus("Next");
      }
    });
  };

  const initialValues = {
    firstName: user.name ?? "",
    lastName: user.family_name ?? "",
    email: user.email
  };

  return (
    <div className="flex flex-col flex-1 mt-[3.5rem]">
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        <Form className="flex flex-col flex-1">
          <div>
            <h3 className="text-2xl font-bold text-gray-800">
              {data.onboarding.indivDetails.left.heading}
            </h3>

            <p className="mt-2 text-base text-gray-500">
              {data.onboarding.indivDetails.left.subHeading}
            </p>
          </div>

          <div className="flex-1 space-y-6 mt-8">
            <div className="flex-1">
              <div className="mb-5 flex justify-between">
                <div className="w-full">
                  <label className="text-sm font-medium text-[#B7B9C1]">
                    First Name*
                  </label>

                  <Field
                    type="text"
                    id="firstName"
                    name="firstName"
                    placeholder="First Name"
                    value={onboardingDetails.firstName}
                    onChange={(e) =>
                      setOnboardingDetails({
                        ...onboardingDetails,
                        firstName: e.target.value
                      })
                    }
                    className="block w-full p-3 mt-2 border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm"
                  />
                  {errors.firstName ? (
                    <FieldError message={"First Name is required"} />
                  ) : null}
                </div>

                <div className="ml-6 w-full">
                  <label className="text-sm font-medium text-[#B7B9C1]">
                    Last Name*
                  </label>

                  <Field
                    type="text"
                    id="lastName"
                    name="lastName"
                    placeholder="Last Name"
                    value={onboardingDetails.lastName}
                    onChange={(e) =>
                      setOnboardingDetails({
                        ...onboardingDetails,
                        lastName: e.target.value
                      })
                    }
                    className="block w-full p-3 mt-2 border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm"
                  />
                  {errors.lastName ? (
                    <FieldError message={"Last Name is required"} />
                  ) : null}
                </div>
              </div>

              <div className="mb-5">
                <label className="text-sm font-medium text-[#B7B9C1]">
                  Email ID
                </label>

                <Field
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Last Name"
                  value={onboardingDetails.email}
                  onChange={(e) =>
                    setOnboardingDetails({
                      ...onboardingDetails,
                      email: e.target.value
                    })
                  }
                  className="block w-full p-3 mt-2 border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm"
                />
                {errors.email ? (
                  <FieldError message={"Email is required"} />
                ) : null}
              </div>
              <div className="mb-5">
                <label className="text-sm font-medium text-[#B7B9C1]">
                  Country
                </label>

                <ReactFlagsSelect
                  selected={onboardingDetails.country}
                  searchable
                  onSelect={(country) => {
                    setOnboardingDetails({
                      ...onboardingDetails,
                      country
                    });
                  }}
                  className="block w-full !border-background-layer3 !rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm"
                />
                {errors.country ? (
                  <FieldError message={"Country is required"} />
                ) : null}
                <p className="flex items-center mt-1 space-x-2 text-sm italic text-[#00AC47]">
                  Select a region close to your users for the best performance
                </p>
              </div>
            </div>
          </div>

          <div className="flex items-center mt-12 space-x-4">
            <Link
              to="/onboarding/organization-details"
              className="w-1/2 px-8 py-3 space-x-3 font-medium text-center transition-colors duration-200 transform border rounded-md focus:outline-none text-primary border-primary hover:bg-white"
            >
              Back
            </Link>

            <Button
              type="submit"
              loading={createButtonLoading}
              disabled={createButtonStatus === "Done!"}
              className="w-1/2 px-8 py-3 font-medium text-center !text-white transition-colors duration-200 transform rounded-md focus:outline-none bg-primary hover:bg-opacity-80"
            >
              {createButtonStatus}
            </Button>
          </div>
        </Form>
      </Formik>
    </div>
  );
}

export default OnboardingIndividualDetails;
