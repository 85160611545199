import { IPermission } from "./apps.interface";

export interface IUserGroup {
  id: string;
  group_name: string;
  org_id: string;
  created_at: string;
  updated_at: string;
  permissions?: IPermission[];
  org_access: OrgAccessLevel;
  external_auth_org: string | null;
}

export enum OrgAccessLevel {
  OWNER = "OWNER",
  ADMIN = "ADMIN",
  MEMBER = "MEMBER"
}
