import { useMutation, useQueryClient } from "react-query";

import networkService from "@services/network.service";
import { useAuthStore } from "@store/index";

export interface IDeleteAuthUserPayload {}

interface IDeleteAuthUserResponse {
  ok: number;
}

export const deleteCustomAuthUser = async (
  userId: string,
  groupId: string,
  orgId: string
) => {
  const { ok } = await networkService.delete<IDeleteAuthUserResponse>(
    `organizations/groups/${groupId}/custom_auth/users/${userId}`,
    {},
    {
      headers: {
        "ORG-ID": orgId
      }
    }
  );

  return ok;
};

export const useDeleteCustomAuthUser = (groupId: string) => {
  const [user] = useAuthStore((state) => [state.user]);
  const queryClient = useQueryClient();

  const res = useMutation(
    async (userId: string) =>
      await deleteCustomAuthUser(userId, groupId, user.selectedOrg.id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getUserGroupDetails");
      }
    }
  );

  return res;
};
