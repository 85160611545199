import { IOption } from "@/interfaces";
import { IPermission } from "@/interfaces/apps.interface";

export const convertPermissionsToFormState = (
  permissions: IPermission[],
  resourceTypeOptions: IOption[],
  projPermissions: IOption[],
  dashboardPermissions: IOption[],
  deviceGroupPermissions: IOption[],
  projOptions: IOption[],
  dashOptions: IOption[],
  fleetsOptions: IOption[],
  tagsOptions: IOption[]
) => {
  const formPermissions =
    permissions?.map((perm) => ({
      resource_type: resourceTypeOptions.find(
        (opt) => opt.value === perm.resource_type
      ),
      actions:
        perm.resource_type === "projects"
          ? projPermissions.filter((p) => perm.actions.includes(p.value))
          : perm.resource_type === "dashboards"
          ? dashboardPermissions.filter((p) => perm.actions.includes(p.value))
          : perm.resource_type === "fleets"
          ? deviceGroupPermissions.filter((p) =>
              perm.actions.includes(p.value)
            )
          : perm.resource_type === "tags"
          ? deviceGroupPermissions.filter((p) =>
              perm.actions.includes(p.value)
            )
          : [],

      resource: perm.resource
        ? perm.resource_type === "projects"
          ? projOptions.find((opt) => opt.value === perm.resource)
          : perm.resource_type === "dashboards"
          ? dashOptions.find((opt) => opt.value === perm.resource)
          : perm.resource_type === "fleets"
          ? fleetsOptions.find((opt) => opt.value === perm.resource)
          : perm.resource_type === "tags"
          ? tagsOptions.find((opt) => opt.value === perm.resource)
          : undefined
        : undefined
    })) || [];

  return formPermissions;
};
