import { useQuery } from "react-query";

import networkService from "@services/network.service";
import { useAuthStore } from "@store/index";
import { IUserGroup } from "@/interfaces/user-groups.interface";

export type TUserGroupDetails = IUserGroup & {
  users: { email: string; user_id: string }[];
};

interface IUserGroupsResponse {
  data: TUserGroupDetails;
  ok: number;
}

export const getUserGroupDetails = async (
  groupId: string,
  orgId: string,
  params
) => {
  const { data, ok } = await networkService.get<IUserGroupsResponse>(
    `organizations/groups/${groupId}`,
    params,
    {
      headers: {
        "ORG-ID": orgId
      }
    }
  );

  if (ok) {
    return data || undefined;
  } else return null;
};

export const useGetUserGroupsDetails = (
  groupId: string,
  params: any = {},
  successCb: ((data: TUserGroupDetails) => void) | null = null
) => {
  const [user] = useAuthStore((state) => [state.user]);
  const res = useQuery(
    ["getUserGroupDetails", groupId, user.selectedOrg.id, params],
    async () => {
      const groupDetails = await getUserGroupDetails(
        groupId,
        user.selectedOrg.id,
        params
      );

      successCb && successCb(groupDetails);

      return groupDetails;
    },
    {
      enabled: !!user.selectedOrg.id && !!groupId
    }
  );

  return res;
};
