import { FieldError } from "@/app/shared/components";
import Modal from "@/app/shared/components/modal.component";
import { useDashboardStore } from "@/store";
import { Button, Callout } from "@tremor/react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as yup from "yup";
import React from "react";
import {
  ICreateBlueprintPayload,
  useCreateDashPanelBlueprint
} from "@/app/shared/hooks/post/create-panel-blueprint";
import { IDashboard } from "@/interfaces";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const validationSchema = yup.object({
  blueprintName: yup
    .string()
    .required("Blueprint Name is required!")
    .min(3, "Blueprint Name should be minimum 3 characters long!")
});

interface DashPanelCreateBlueprintModalProps {
  dashboard?: IDashboard;
}

const DashPanelCreateBlueprintModal: React.FC<
  DashPanelCreateBlueprintModalProps
> = ({ dashboard }) => {
  const navigate = useNavigate();

  const [
    blueprintPanel,
    createBlueprintKind,
    createBlueprintModalOpen,
    panels,
    setCreateBlueprintModalOpen
  ] = useDashboardStore((state) => [
    state.blueprintPanel,
    state.createBlueprintKind,
    state.createBlueprintModalOpen,
    state.panels,
    state.setCreateBlueprintModalOpen
  ]);

  const createBlueprintMutation = useCreateDashPanelBlueprint();

  const onBlueprintCreate = ({ blueprintName }) => {
    let createBlueprintPayload: ICreateBlueprintPayload;

    if (createBlueprintKind === "DASHBOARD") {
      const dashboard_blueprint_definition = {
        title: dashboard.dashboard_name,
        description: dashboard.dashboard_description,
        panel_ids: panels.map((panel) => panel.id)
      };

      createBlueprintPayload = {
        name: blueprintName,
        kind: createBlueprintKind,
        definition: dashboard_blueprint_definition
      };
    } else {
      const panel_blueprint_definition = {
        title: blueprintPanel.title,
        type: blueprintPanel.panel_type,
        panel_definition: blueprintPanel.definition,
        data_config: blueprintPanel.data_config
      };

      createBlueprintPayload = {
        name: blueprintName,
        kind: createBlueprintKind,
        definition: panel_blueprint_definition
      };
    }

    createBlueprintMutation.mutate(createBlueprintPayload, {
      onSuccess: (blueprintId) => {
        setCreateBlueprintModalOpen(false);
        toast.success("Created Blueprint Succesfullt!");
        if (createBlueprintKind === "DASHBOARD") {
          navigate("/blueprints/dashboards/" + blueprintId);
        } else {
          navigate("/blueprints/panels/" + blueprintId);
        }
      },
      onError: () => {
        setCreateBlueprintModalOpen(false);
      }
    });
  };

  return (
    <Modal
      open={createBlueprintModalOpen}
      setOpen={setCreateBlueprintModalOpen}
      title="Create Panel Blueprint"
      className="w-full max-w-2xl"
      disableClickOutside
    >
      <div className="flex flex-col gap-4 p-6 bg-background-layer1 text-contentColor">
        <h1 className="text-lg font-bold">
          Create {createBlueprintKind === "DASHBOARD" ? "Dashboard" : "Panel"}{" "}
          Blueprint
        </h1>
        <p>
          Apply blueprints to any tag, group of devices, a data-points or a
          fleet!
        </p>
        <Callout title="Note">
          [placeholder] You can only edit the default values and blueprint name
          once you create a blueprint{" "}
        </Callout>
        <Formik
          validationSchema={validationSchema}
          initialValues={{ blueprintName: "" }}
          onSubmit={onBlueprintCreate}
        >
          <Form>
            <div>
              <label htmlFor="blueprintName">
                Blueprint Name
                <Field
                  id="blueprintName"
                  name="blueprintName"
                  placeholder="Awesome Blueprint"
                  className="block w-10/12 p-3 mt-2 border-background-layer3 bg-background rounded-md focus:ring focus:ring-opacity-40 focus:ring-primary focus:border-primaryLight sm:text-sm"
                ></Field>
              </label>
              <ErrorMessage name="blueprintName">
                {(msg) => <FieldError message={msg} />}
              </ErrorMessage>
            </div>
            <div className="flex gap-4 mt-8 justify-end">
              <Button
                type="button"
                variant="secondary"
                onClick={() => setCreateBlueprintModalOpen(false)}
              >
                Cancel
              </Button>
              <Button
                loading={createBlueprintMutation.isLoading}
                variant="primary"
                type="submit"
              >
                Create
              </Button>
            </div>
          </Form>
        </Formik>
      </div>
    </Modal>
  );
};

export default DashPanelCreateBlueprintModal;
