import { useMutation, useQueryClient } from "react-query";

import networkService from "@services/network.service";
import { useAuthStore } from "@store/index";

export interface IRemoveUsersFromGroupPayload {
  user_ids: string;
}

interface IRemoveUsersFromGroupResponse {
  ok: number;
}

export const removeUsersFromGroup = async (
  groupId: string,
  orgId: string,
  payload: IRemoveUsersFromGroupPayload
) => {
  const { ok } = await networkService.delete<IRemoveUsersFromGroupResponse>(
    `organizations/groups/${groupId}/users`,
    payload,
    {
      headers: {
        "ORG-ID": orgId
      }
    }
  );

  return ok;
};

export const useRemoveUsersFromGroup = (groupId: string) => {
  const [user] = useAuthStore((state) => [state.user]);
  const queryClient = useQueryClient();

  const res = useMutation(
    async (data: IRemoveUsersFromGroupPayload) =>
      await removeUsersFromGroup(groupId, user.selectedOrg.id, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getUserGroupDetails");
      }
    }
  );

  return res;
};
