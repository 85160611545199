import { TBlueprintKind } from "@/interfaces/blueprint.interface";
import { DASH_PANEL_TYPE } from "@/interfaces/dashboard-panel.interface";
import networkService from "@services/network.service";
import { useAuthStore, useFleetAndDevicesStore } from "@store/index";
import { useQuery } from "react-query";

export interface IBlueprint {
  id: string;
  name: string;
  kind: TBlueprintKind;
  meta_data: any;
  definition: {
    title: string;
    type: DASH_PANEL_TYPE;
    panel_definition: string;
    data_config: string;
  };
  created_at: string;
  deleted_at: string;
  project_id: string;
}

interface IBlueprintsResponse {
  ok: number;
  data: {
    blueprints: IBlueprint[];
  };
}

export const getBlueprints = async (
  projectId: string,
  orgId: string,
  params
) => {
  const { data, ok } = await networkService.get<IBlueprintsResponse>(
    `projects/${projectId}/blueprints/`,
    params,
    {
      headers: {
        "ORG-ID": orgId
      }
    }
  );

  if (ok) {
    return data.blueprints;
  } else return null;
};

export const useGetDashPanelBlueprints = (
  params: any = {},
  successCb: ((data: IBlueprint[]) => void) | null = null
) => {
  const [selectedProject] = useFleetAndDevicesStore((state) => [
    state.selectedProject
  ]);
  const [user] = useAuthStore((state) => [state.user]);
  const res = useQuery(
    ["getBlueprints", selectedProject.id, user.selectedOrg.id, params],
    async () => {
      const blueprints = await getBlueprints(
        selectedProject.id,
        user.selectedOrg.id,
        params
      );

      successCb && successCb(blueprints);

      return blueprints;
    },
    {
      enabled: !!selectedProject.id && !!user.selectedOrg.id
    }
  );

  return res;
};
