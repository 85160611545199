import { useMutation, useQueryClient } from "react-query";

import networkService from "@services/network.service";
import { useAuthStore } from "@store/index";

export interface IEnableUserGroupCustomAuthPayload {}

interface IEnableCustomAuthResponse {
  ok: number;
}

export const enableUserGroupCustomAuth = async (
  groupId: string,
  orgId: string,
  payload: IEnableUserGroupCustomAuthPayload
) => {
  const { ok } = await networkService.post<IEnableCustomAuthResponse>(
    `organizations/groups/${groupId}/custom_auth`,
    payload,
    {
      headers: {
        "ORG-ID": orgId
      }
    }
  );

  return ok;
};

export const useEnableUserGroupCustomAuth = (groupId: string) => {
  const [user] = useAuthStore((state) => [state.user]);
  const queryClient = useQueryClient();

  const res = useMutation(
    async (data: IEnableUserGroupCustomAuthPayload) =>
      await enableUserGroupCustomAuth(groupId, user.selectedOrg.id, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getUserGroupDetails");
      }
    }
  );

  return res;
};
