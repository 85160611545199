import { useMemo, useState } from "react";
import SettingsGenericTable from "../components/settings-generic-table.component";
import ApiKeyForm from "../components/settings-api-keys-form.component";
import useSettingsStore from "../../../store/settings/settings.store";
import dateService from "../../../services/date.service";
import { useGetApps } from "@app/shared/hooks/get/apps";
import { Button } from "@tremor/react";
import { useDeleteApp } from "@/app/shared/hooks/delete/delete-app";
import { toast } from "react-toastify";
import { OrgAccessLevel } from "@/interfaces/user-groups.interface";
import { TrashIcon } from "@heroicons/react/24/outline";
import { useConfirmDelete } from "@/app/shared/hooks/use-confirm-delete.hooks";
import { IProjectApp } from "@/interfaces/apps.interface";

function APIKeys() {
  const [selectedApp, setSelectedApp] = useState<IProjectApp>(null);

  const [setShowGenerateAPIKeyForm] = useSettingsStore((state) => [
    state.setShowGenerateAPIKeyForm
  ]);

  const { data: projectApps } = useGetApps();
  const deleteAppMutation = useDeleteApp();

  const { openConfirmDeleteModal } = useConfirmDelete();

  const renderStatus = (status: OrgAccessLevel) => {
    const statusUI = {
      [OrgAccessLevel.ADMIN]: (
        <span className="flex">
          <span className="bg-[#25a96a36] py-2 px-5 rounded text-[#25A96A] font-medium text-xs mr-6">
            ADMIN
          </span>
        </span>
      ),
      [OrgAccessLevel.MEMBER]: (
        <span className="flex">
          <span className="bg-[#166ff629] py-2 px-[17px] rounded text-[#1366E5] font-medium text-xs mr-6">
            MEMBER
          </span>
        </span>
      )
    };

    return statusUI[status];
  };

  const collaborators = useMemo(() => {
    const filterApps =
      projectApps?.map((app) => {
        return {
          app_name: (
            <span
              className="text-primary cursor-pointer"
              onClick={() => {
                setSelectedApp(app);
                setShowGenerateAPIKeyForm(true);
              }}
            >
              {app.app_name}
            </span>
          ),
          org_access: renderStatus(app.org_access),
          joining_date: dateService.convertUTCToLocalDate(
            app?.created_at || ""
          ),
          delete: (
            <Button
              variant="light"
              icon={TrashIcon}
              color="red"
              onClick={() => {
                openConfirmDeleteModal(() => {
                  deleteAppMutation.mutate(app.id, {
                    onSuccess: () => {
                      toast.success("App deleted successfully");
                    }
                  });
                }, "Are you sure you want to delete this API Key?");
              }}
            />
          )
        };
      }) || [];

    return filterApps;
  }, [deleteAppMutation, projectApps]);

  return (
    <>
      <ApiKeyForm selectedApp={selectedApp} />
      <div>
        <button
          onClick={() => {
            setSelectedApp(null);
            setShowGenerateAPIKeyForm(true);
          }}
          className="inline-block px-2.5 py-3 font-medium text-center text-white transition-colors duration-200 transform rounded-md bg-primary hover:bg-opacity-80"
        >
          + Add API Key
        </button>

        <div className="flex flex-col mt-8 w-full h-[50vh] bg-background text-contentColor pb-6">
          <div className="-my-2 overflow-x-auto xl:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <div className="overflow-hidden rounded-xl">
                {!!collaborators?.length && (
                  <SettingsGenericTable
                    column={Object.keys(collaborators[0])}
                    row={collaborators}
                    searchField={
                      collaborators.length
                        ? Object.keys(collaborators[0])[0]
                        : ""
                    }
                    searchTerm=""
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default APIKeys;
