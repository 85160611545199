import { useMutation, useQueryClient } from "react-query";

import networkService from "@services/network.service";
import { useAuthStore } from "@store/index";
import { IPermission } from "@interfaces/apps.interface";
import { ICreateInviteResponse } from "@interfaces/invites.interface";
import { OrgAccessLevel } from "@/interfaces/user-groups.interface";

export interface ICreateUserGroupPayload {
  name: string;
  permissions: IPermission[];
  org_access: OrgAccessLevel;
}

export const createUserGroup = async (
  orgId: string,
  payload: ICreateUserGroupPayload
) => {
  const { data, ok } = await networkService.post<ICreateInviteResponse>(
    `organizations/groups`,
    payload,
    {
      headers: {
        "ORG-ID": orgId
      }
    }
  );

  if (ok) {
    return data;
  } else return null;
};

export const useCreateUserGroup = () => {
  const [user] = useAuthStore((state) => [state.user]);
  const queryClient = useQueryClient();

  const res = useMutation(
    async (data: ICreateUserGroupPayload) =>
      await createUserGroup(user.selectedOrg.id, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getUserGroups");
      }
    }
  );

  return res;
};
