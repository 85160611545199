import { isEmpty } from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  IGaugeDefination,
  ILineDefination,
  IPieDefinations
} from "../../interfaces";
import { useDashboardStore, useFleetAndDevicesStore } from "../../store";
import DashboardUtilsHeader from "./components/dash-filter-header.component";
import PanelWrapper from "./dash-wrapper-panel.component";
import CarouselWrapper from "./dash-carousel-warpper.component";
import { toast } from "react-toastify";
import { Tooltip } from "react-tooltip";
import { CoreProps, Responsive, WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import { GRID_COLS, generateLayout } from "./dash.helper";
import { Cog6ToothIcon } from "@heroicons/react/24/outline";
import { useGetDashboardPanels } from "@app/shared/hooks/get/dashboard-panels";
import ShowLoading from "@app/shared/components/loading.component";
import ShowError from "@app/shared/components/error.component";
import { Badge, Color } from "@tremor/react";
import { useGetFleets } from "@app/shared/hooks/get/fleets";
import { PlusIcon } from "@heroicons/react/24/outline";
import { useGetDevicePanels } from "../shared/hooks/get/device-panels";
import { TDashPanel } from "@/store/dashboard/dashboard.store";
import { IServerQuery } from "../query-builder/query-builder.helper";
import Modal from "../shared/components/modal.component";

const ResponsiveGridLayout = WidthProvider(Responsive);

const _towerData = [
  {
    Tower: "T1",
    Area: "Pre-roll",
    Vehicle: 3,
    Bounds: [
      {
        lat: 12.973277894811467,
        lng: 80.24247097337478
      },
      {
        lat: 12.973249807394115,
        lng: 80.24253995457309
      }
    ],
    Devices: [
      {
        VSN: "1",
        EOL: 1,
        position: {
          lat: 12.973272088771743,
          lng: 80.24247856569514
        }
      },
      {
        VSN: "2",
        EOL: 3,
        position: {
          lat: 12.973273103491316,
          lng: 80.24250078007046
        }
      },
      {
        VSN: "3",
        EOL: 5,
        position: {
          lat: 12.973261603335855,
          lng: 80.24252230024649
        }
      }
    ]
  },
  {
    Tower: "T2",
    Area: "WA-Parking",
    Vehicle: 4,
    Bounds: [
      {
        lat: 12.973378252074562,
        lng: 80.24244927506355
      },
      {
        lat: 12.973315765481548,
        lng: 80.24249947264919
      }
    ],
    Devices: [
      {
        VSN: "",
        EOL: 1,
        position: {
          lat: 12.973367850070757,
          lng: 80.2424623921639
        }
      },
      {
        VSN: "",
        EOL: 3,
        position: {
          lat: 12.973367156997115,
          lng: 80.24248064701766
        }
      },
      {
        VSN: "",
        EOL: 3,
        position: {
          lat: 12.973334120485058,
          lng: 80.24246476292414
        }
      },
      {
        VSN: "",
        EOL: 5,
        position: {
          lat: 12.973332734337612,
          lng: 80.24247922456155
        }
      }
    ]
  },
  {
    Tower: "T3",
    Area: "Post-roll/3rd Line",
    Vehicle: 6,
    Bounds: [
      {
        lat: 12.973276948044647,
        lng: 80.24254157385003
      },
      {
        lat: 12.973254856817691,
        lng: 80.24269508130543
      }
    ],
    Devices: [
      {
        VSN: "",
        EOL: 1,
        position: {
          lat: 12.973269664620227,
          lng: 80.24255390350874
        },
        path: [
          {
            lat: 12.973272767618393,
            lng: 80.24254210909433
          },
          {
            lat: 12.973269053527318,
            lng: 80.24255328913306
          }
        ]
      },
      {
        VSN: "",
        EOL: 1,
        position: {
          lat: 12.973269895644854,
          lng: 80.24257215836248
        },
        path: [
          {
            lat: 12.973271900997139,
            lng: 80.24254198204844
          },
          {
            lat: 12.973269301133385,
            lng: 80.24257221897145
          }
        ]
      },
      {
        VSN: "",
        EOL: 3,
        position: {
          lat: 12.973268509497053,
          lng: 80.2425961030408
        },
        path: [
          {
            lat: 12.973269796345534,
            lng: 80.24254185500254
          },
          {
            lat: 12.973268186906063,
            lng: 80.24259623064557
          }
        ]
      },
      {
        VSN: "",
        EOL: 3,
        position: {
          lat: 12.973268971546332,
          lng: 80.24261625450274
        },
        path: [
          {
            lat: 12.973269796345534,
            lng: 80.24254185500254
          },
          {
            lat: 12.97326831070909,
            lng: 80.24261643094289
          }
        ]
      },
      {
        VSN: "",
        EOL: 5,
        position: {
          lat: 12.973268509497053,
          lng: 80.24264209578925
        },
        path: [
          {
            lat: 12.973269467412821,
            lng: 80.24254191137467
          },
          {
            lat: 12.973268114453358,
            lng: 80.24264204961331
          }
        ]
      },
      {
        VSN: "",
        EOL: 5,
        position: {
          lat: 12.973268971546332,
          lng: 80.24267149321611
        },
        path: [
          {
            lat: 12.973269467412821,
            lng: 80.24254191137467
          },
          {
            lat: 12.973267931935458,
            lng: 80.2426717302921
          }
        ]
      }
    ]
  },
  {
    Tower: "T4",
    Area: "Minor-Repair",
    Vehicle: 2,
    Bounds: [
      {
        lat: 12.97339084836023,
        lng: 80.24261065421723
      },
      {
        lat: 12.973362826403465,
        lng: 80.242695152522
      }
    ],
    Devices: [
      {
        VSN: "",
        EOL: 1,
        position: {
          lat: 12.973379366367073,
          lng: 80.24262920022012
        }
      },
      {
        VSN: "",
        EOL: 3,
        position: {
          lat: 12.973379366367073,
          lng: 80.242673791945
        }
      }
    ]
  },
  {
    Tower: "T5",
    Area: "Shower Area",
    Vehicle: 4,
    Bounds: [
      {
        lat: 12.973322392848209,
        lng: 80.24268989961918
      },
      {
        lat: 12.97328610012381,
        lng: 80.24273362009697
      }
    ],
    Devices: [
      {
        VSN: "",
        EOL: 1,
        position: {
          lat: 12.973315329625345,
          lng: 80.24270094931126
        }
      },
      {
        VSN: "",
        EOL: 1,
        position: {
          lat: 12.973315002907242,
          lng: 80.242721401155
        }
      },
      {
        VSN: "",
        EOL: 1,
        position: {
          lat: 12.973295399819714,
          lng: 80.24269793182611
        }
      },
      {
        VSN: "",
        EOL: 1,
        position: {
          lat: 12.97329474638343,
          lng: 80.24272073060276
        }
      }
    ]
  },
  {
    Tower: "T6",
    Area: "Outer Area",
    Vehicle: 0,
    Bounds: [
      {
        lat: 12.973664795234614,
        lng: 80.2423384666278
      },
      {
        lat: 12.973481375271325,
        lng: 80.24255475985298
      }
    ],
    Devices: []
  }
];

interface IDashboardDetails {
  device?: any;
  onAddPanel?: () => void;
  dashboardConfigs?: {
    hideAddPanel?: boolean;
    hideDelete?: boolean;
    hideEditLayout?: boolean;
  };
}

const Details: React.FC<IDashboardDetails> = ({
  device,
  onAddPanel,
  dashboardConfigs
}) => {
  const navigate = useNavigate();
  const wrapperRef = useRef(null);
  const panelErrors = useRef({});
  const errorToastTimer = useRef(null);
  const [geoMapAdditionalInfo, setGeoMapAdditionalInfo] = useState({});
  const [showUtilsHeader, setShowUtilsHeader] = useState(false);

  const selectedFleet = useFleetAndDevicesStore(
    (state) => state.selectedFleet
  );

  const [editDashboard, savedLayouts, expandedItemId, setExpandedItemId] =
    useDashboardStore((state) => [
      state.editingLayout,
      state.layouts,
      state.expandedItemId,
      state.setExpandedItemId
    ]);

  const devicePanels = getEvInfPanels(device);

  const towerData = useMemo(() => {
    let vsnCounter = 1;

    return _towerData.map((val) => ({
      ...val,
      Devices: val.Devices.map((device) => ({
        ...device,
        VSN: "MECXXXXXXXX" + vsnCounter++
      }))
    }));
  }, []);

  const [
    dashboards,
    panels,
    carousels,
    activeDashboard,
    setPanels,
    setCarousels,
    clearCreatePanelState,
    zoomLevel,
    showAddPanel
  ] = useDashboardStore((state) => [
    state.dashboards,
    state.panels,
    state.carousels,
    state.activeDashboard,
    state.setPanels,
    state.setCarousels,
    state.clearCreatePanelState,
    state.zoomLevel,
    state.showAddPanel
  ]);

  const sortPanels = (obj: any) => {
    const sortedObj = {};
    const rowKeys = Object.keys(obj);

    if (rowKeys.length) {
      rowKeys.forEach((row) => {
        const sortedPanels = obj[row].sort(
          (a, b) => a.definition.panel_position - b.definition.panel_position
        );

        sortedObj[row] = sortedPanels;
      });
    }
    return { ...sortedObj };
  };

  // if rendered in project level dashbaord, fetch panels from that dashboard
  const { isLoading: isDashPanelsLoading, error } = useGetDashboardPanels(
    device?.id ? "" : activeDashboard?.id,
    {},
    (dashboardPanels) => {
      if (device?.id) return;
      if (dashboardPanels?.length) {
        let carouselObj = {};
        let panelsArr = [];
        dashboardPanels.forEach((panel) => {
          if (panel.definition.arrangement) {
            if (panel.definition.arrangement.carousel in carouselObj) {
              carouselObj[panel.definition.arrangement.carousel].push(panel);
            } else {
              carouselObj[panel.definition.arrangement.carousel] = [panel];
            }
          } else {
            panelsArr.push(panel);
          }
        });
        if (!isEmpty(carouselObj)) {
          setCarousels(sortPanels(carouselObj));
        }
        if (panelsArr.length) {
          const newState = {
            currentBreakpoint: "lg",
            compactType: "vertical",
            resizeHandles: ["se"],
            mounted: true,
            // generate layouts according to the current breakpoint,
            // and merge them with the saved layouts
            layouts: {
              ...Object.keys(GRID_COLS).reduce((acc, cur) => {
                acc[cur] = generateLayout(panelsArr, GRID_COLS[cur], ["se"]);
                return acc;
              }, {}),
              ...savedLayouts
            }
          };
          setLayoutState(newState);
          setPanels(panelsArr);
        }
      } else {
        setPanels([]);
      }
    }
  );

  // if rendered inside a device, fetch device panels
  const { isLoading: isDevicePanelsLoading } = useGetDevicePanels(
    selectedFleet.id,
    device?.id,
    {},
    (blueprintPanels) => {
      if (!blueprintPanels) setPanels([]);
      const panelsArr: TDashPanel[] = [];

      // convert blueprint panels to standard panels
      blueprintPanels.forEach((bp) => {
        const panel: TDashPanel = {
          id: "",
          blueprint_id: bp.id,
          title: bp.definition.title,
          panel_description: bp.name,
          panel_type: bp.definition.type,
          definition: JSON.parse(bp.definition.panel_definition),
          data_config: JSON.parse(bp.definition.data_config) as IServerQuery,
          dashboard_id: "",
          project_id: bp.project_id,
          org_id: "",
          created_at: bp.created_at
        };
        panelsArr.push(panel);
      });

      setPanels(panelsArr);
    }
  );

  const { data: fleets } = useGetFleets();

  useEffect(() => {
    if (activeDashboard.id) {
      clearCreatePanelState();
    }
  }, [activeDashboard.id, clearCreatePanelState]);

  useEffect(() => {
    if (!wrapperRef.current) return;
    wrapperRef.current.style.zoom = `${zoomLevel}%`;
  }, [zoomLevel]);

  useEffect(() => {
    let timer = errorToastTimer.current;

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const renderCarouselPanels = () => {
    const carouselKeys = Object.keys(carousels);
    return carouselKeys.map((carousel) => (
      <CarouselWrapper title={carousel} panels={carousels[carousel]} />
    ));
  };

  const handleAddPanel = () => {
    if (device?.id) {
      onAddPanel && onAddPanel();
      return;
    }

    if (fleets.length > 0) {
      navigate("/dashboard/new-panel-type");
    } else {
      toast.error(
        "Please add at least one Fleet before creating a new panel!"
      );
    }
  };

  const handlePanelError = (
    panelId: string,
    panelTitle: string,
    error: string
  ) => {
    panelErrors.current[panelId] = { error, panelTitle };

    // batch the errors into one toast to avoid spamming the user with toasts
    if (Object.keys(panelErrors.current).length === 1) {
      errorToastTimer.current = setTimeout(() => {
        if (Object.keys(panelErrors.current).length)
          toast.error(
            <div>
              <span className="text-sm font-bold mb-1 block">
                There were some errors fetching the data of following panels:{" "}
              </span>
              <div className="flex flex-col">
                {Object.keys(panelErrors.current).map((panelId) => (
                  <div className="flex items-center" key={panelId}>
                    <span className="text-xs font-medium whitespace-nowrap  ">
                      {panelErrors.current[panelId].panelTitle}:
                    </span>
                    <span className="text-xs ml-2">
                      {panelErrors.current[panelId].error}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          );
        panelErrors.current = {};
        clearTimeout(errorToastTimer.current);
      }, 500);
    }
  };

  const [layoutState, setLayoutState] = useState({
    currentBreakpoint: "lg",
    compactType: "vertical",
    resizeHandles: ["se"],
    mounted: false,
    layouts: {
      ...Object.keys(GRID_COLS).reduce((acc, cur) => {
        acc[cur] = generateLayout(panels, GRID_COLS[cur], ["se"]);
        return acc;
      }, {}),
      ...savedLayouts
    }
  });

  const onBreakpointChange: (Breakpoint) => void = (breakpoint) => {
    setLayoutState((curState) => ({
      ...curState,
      currentBreakpoint: breakpoint
    }));
  };

  const panelsToRender = (device?.id ? devicePanels : panels) as any;

  const memoizedPanels = useMemo(() => {
    if (!layoutState.layouts[layoutState.currentBreakpoint]?.length) return;
    if (device?.id) return [];

    return panelsToRender.map(
      (panel: ILineDefination | IPieDefinations | IGaugeDefination) => (
        <div
          key={panel.id || panel.blueprint_id}
          data-grid={layoutState.layouts[layoutState.currentBreakpoint].find(
            (el) => el.i === panel.id || panel.blueprint_id
          )}
          className={`${editDashboard ? "select-none" : ""} ${
            !editDashboard && "hide-resize"
          }`}
        >
          <PanelWrapper
            panel={panel}
            // deviceId={device?.id}
            key={panel.id || panel.blueprint_id}
            handlePanelError={handlePanelError}
            geoMapAdditionalInfo={geoMapAdditionalInfo}
            setGeoMapAdditionalInfo={setGeoMapAdditionalInfo}
          />
        </div>
      )
    );
  }, [
    layoutState.layouts,
    layoutState.currentBreakpoint,
    device?.id,
    panelsToRender,
    editDashboard,
    geoMapAdditionalInfo
  ]);

  // need to do this because for some reason RGL calls the onLayoutChange callback with
  // a layout object that arranges all the panels into a single column
  useEffect(
    () => {
      setLayoutState((curState) => ({
        ...curState,
        layouts: {
          ...Object.keys(GRID_COLS).reduce((acc, cur) => {
            acc[cur] = generateLayout(panels, GRID_COLS[cur], ["se"]);
            return acc;
          }, {}),
          ...savedLayouts
        }
      }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeDashboard.id]
  );

  const expandedPanel = useMemo(
    () =>
      panels.find(
        (p) => p.id === expandedItemId || p.blueprint_id === expandedItemId
      ),
    [panels, expandedItemId]
  );

  if (isDashPanelsLoading || isDevicePanelsLoading) {
    return <ShowLoading />;
  }

  if (error) {
    return <ShowError />;
  }

  if (!device?.id && !dashboards.length) {
    return null;
  }

  return (
    <main className="w-full relative h-auto py-4 overflow-y-auto sm:px-4">
      <DashboardUtilsHeader
        showUtilsHeader={showUtilsHeader}
        hideAddPanel={dashboardConfigs?.hideAddPanel}
        hideDelete={dashboardConfigs?.hideDelete}
        hideEditLayout={dashboardConfigs?.hideEditLayout}
        addPanel={handleAddPanel}
        layouts={layoutState.layouts}
        setLayouts={setLayoutState}
        panels={panels}
        setShowUtilsHeader={setShowUtilsHeader}
      />
      {!showUtilsHeader ? (
        <Cog6ToothIcon
          width={20}
          className="absolute top-2 right-2 cursor-pointer"
          onClick={() => setShowUtilsHeader(true)}
        />
      ) : null}
      <div ref={wrapperRef} className="w-full py-4">
        {!isEmpty(carousels) && renderCarouselPanels()}
        <div className="flex flex-wrap gap-4 w-full h-full">
          {activeDashboard?.id === "f98203d3-2cae-4249-9e8d-6dd31932d74e" ? (
            <div className="min-h-[400px] w-full border border-background-layer3 p-4 rounded-md">
              <h4>Report</h4>
              <div>
                <table className={`text-center w-full`}>
                  <tr className="border-b border-background-layer3">
                    {/* <th className="pl-4">Tower</th>÷ */}
                    <th className="">Area</th>
                    <th className="pr-4">Devices</th>
                  </tr>
                  <tbody>
                    {towerData.map((val, ind) => (
                      <tr
                        className={`border-b cursor-pointer select-none border-background-layer3`}
                      >
                        {/* <td>{val.Tower}</td> */}
                        <td>{val.Area}</td>
                        <td>
                          <div className="flex gap-2 max-w-full overflow-x-auto">
                            {val.Devices.map((dev) => (
                              <div
                                className={`p-2 rounded-md border border-background-layer3 ${
                                  dev.EOL < 2
                                    ? "bg-green-200"
                                    : dev.EOL < 4
                                    ? "bg-yellow-200"
                                    : "bg-red-200"
                                }`}
                              >
                                {dev.VSN}
                              </div>
                            ))}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <ResponsiveGridLayout
              className="layout w-full"
              breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
              cols={GRID_COLS}
              rowHeight={260}
              width={1200}
              isDraggable={editDashboard}
              isResizable={editDashboard}
              layouts={layoutState.layouts}
              onBreakpointChange={onBreakpointChange}
              measureBeforeMount={false}
              useCSSTransforms={layoutState.mounted}
              compactType={layoutState.compactType as CoreProps["compactType"]}
              preventCollision={!layoutState.compactType}
              onLayoutChange={(layout, layouts) => {
                if (!layout.length) return;

                // save the current layout to all the breakpoints, let the library handle the arrangement
                const newLayouts = Object.keys(GRID_COLS).reduce(
                  (acc, cur) => {
                    if (!layout.length) return acc;
                    acc[cur] = layout;
                    return acc;
                  },
                  {}
                );
                if (Object.keys(newLayouts).length) {
                  setLayoutState((curState) => ({
                    ...curState,
                    layouts: newLayouts
                  }));
                }
              }}
            >
              {memoizedPanels}
            </ResponsiveGridLayout>
          )}
        </div>
      </div>
      <Tooltip
        id="panel-error-tooltip"
        className="border border-red-500"
        style={{
          zIndex: 100000,
          backgroundColor: "#F87171",
          color: "#fff"
        }}
      />
      <Tooltip
        id={`tooltip-badge-hover`}
        className="z-20 transform"
        variant="light"
        border={"1px solid black"}
        render={({ activeAnchor }) => {
          const labels = activeAnchor
            ?.getAttribute("data-tooltip-labels")
            ?.split(",");
          const values = activeAnchor
            ?.getAttribute("data-tooltip-values")
            ?.split(",");
          const colorsProp = activeAnchor?.getAttribute("data-tooltip-colors");
          const colors = colorsProp ? JSON.parse(colorsProp) : {};

          return (
            <div className="flex flex-col gap-1">
              {labels?.map((label, i) => (
                <Badge
                  size="xs"
                  key={i}
                  color={colors[label] as Color}
                  className={`text-xs`}
                >
                  <span style={{ fontSize: "0.2rem !important" }}>
                    {label}: {values[i]}
                  </span>
                </Badge>
              ))}
            </div>
          );
        }}
      />
      <Modal
        open={!!expandedPanel}
        setOpen={(x: boolean) => setExpandedItemId(x ? expandedItemId : null)}
      >
        <div className="flex flex-col gap-4 p-6 w-[95vw] h-[95vh] bg-background text-contentColor">
          <h1 className="text-lg font-bold">{expandedPanel?.title}</h1>

          {expandedPanel ? (
            <PanelWrapper
              panel={expandedPanel}
              // deviceId={device?.id}
              handlePanelError={handlePanelError}
            />
          ) : null}
        </div>
      </Modal>
    </main>
  );
};

function getEvInfPanels(device) {
  if (!device?.id) return [];
  const ev_inv_dev_map_panel = {
    panel_type: "GENERIC",
    title: "Device Location",
    definition: {
      panel_type: "GEO_MAP_LOCATION",
      title: "Device Location"
    },
    dataConfig: {
      data_point_id: "4165553f-722b-4485-8d3b-2c32b026e14c",
      select: [
        {
          alias: "ridePath",
          expression: {
            pattern: "st_asgeojson(st_makeline({c}))",
            values: {
              c: "geom"
            }
          }
        }
      ],
      where: [
        {
          device_id: {
            eq: "35ba339d-901c-4608-a3e9-b35637fb05ab"
          }
        }
      ],
      order: [
        {
          time: -1
        }
      ]
    }
  };

  return [ev_inv_dev_map_panel];
}

export default Details;
