import { useCallback, useMemo } from "react";
import SettingsGenericTable from "../components/settings-generic-table.component";
import dateService from "@services/date.service";
import { Button } from "@tremor/react";
import NoData from "@/app/shared/components/no-data.component";
import ShowLoading from "@/app/shared/components/loading.component";
import { useGetProjects } from "@/app/shared/hooks/get";
import RestoreIcon from "@/app/shared/icons/restore-clock.icon";
import { useRestoreProject } from "@/app/shared/hooks/post/restore-project";
import { toast } from "react-toastify";

function DeletedProjects() {
  const { data: deletedProjects, isLoading } = useGetProjects({
    deleted: true
  });

  const restoreProjectMutation = useRestoreProject();

  const onProjectRestore = useCallback(
    (projectId: string) => {
      restoreProjectMutation.mutate(projectId, {
        onSuccess: () => {
          toast.success("Project restored successfully");
        }
      });
    },
    [restoreProjectMutation]
  );

  const groups = useMemo(() => {
    if (deletedProjects?.length <= 0) return [];
    return deletedProjects?.map((project) => {
      return {
        name: project.project_name,
        "created at": dateService.convertUTCToLocalDate(project?.created_at),
        "deleted at": dateService.convertUTCToLocalDate(project?.deleted_at),
        "time remaining": (
          <span className="text-red-500">
            {"Deleting " +
              dateService.getTimeRemaining(
                dateService.parseUTCDate(project?.deleted_at).add(1, "hour"),
                dateService.getMomentDate()
              )}
          </span>
        ),
        Restore: (
          <Button
            variant="secondary"
            icon={() => (
              <RestoreIcon className="mr-1 stroke-primary fill-primary" />
            )}
            loading={restoreProjectMutation.isLoading}
            size="xs"
            onClick={() => {
              onProjectRestore(project.id);
            }}
          >
            Restore
          </Button>
        )
      };
    });
  }, [deletedProjects, onProjectRestore, restoreProjectMutation.isLoading]);

  if (isLoading) {
    return <ShowLoading />;
  }

  return (
    <>
      <div>
        <div className="flex flex-col mt-4 w-full max-h-[70vh] bg-background text-contentColor pb-6">
          <div className="overflow-auto">
            {!!groups?.length ? (
              <SettingsGenericTable
                column={Object.keys(groups[0])}
                row={groups}
                searchField={groups.length ? Object.keys(groups[0])[0] : ""}
                searchTerm=""
              />
            ) : (
              <NoData msg="No deleted projects" />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default DeletedProjects;
