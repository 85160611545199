import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useAuthStore, useSidebarStore } from "../store";
import { Sidebar } from "./shared/components";
import CommonHeader from "./shared/components/common-header.component";
import useFleetAndDevicesStore from "../store/fleet-and-devices/fleet-and-devices.store";
import { IFleet, IProject } from "../interfaces";
import { useGetProjects } from "./shared/hooks/get";
import { useGetOrganizations } from "./shared/hooks/get/organizations";
import ShowLoading from "./shared/components/loading.component";

const Layout: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [user, updateAuthUser] = useAuthStore((state) => [
    state.user,
    state.updateAuthUser
  ]);
  const [
    setFleets,
    setSelectedFleet,
    setDevices,
    setProjects,
    setSelectedProject,
    selectedProject
  ] = useFleetAndDevicesStore((state) => [
    state.setFleets,
    state.setSelectedFleet,
    state.setDevices,
    state.setProjects,
    state.setSelectedProject,
    state.selectedProject
  ]);

  const [sidebarOpen, toggleSidebar] = useSidebarStore((state) => [
    state.sidebarOpen,
    state.toggleSidebar
  ]);

  const { isLoading: orgsLoading } = useGetOrganizations((organizations) => {
    const data = { organization: organizations };
    if (organizations && organizations.length && !user.selectedOrg?.id) {
      data["selectedOrg"] = organizations[0];
    } else if (organizations?.length === 0) {
      navigate("/onboarding/organization-details");
    }

    updateAuthUser(data);
  });

  const { data: projects, isLoading: projectsLoading } = useGetProjects();

  useEffect(() => {
    if (projectsLoading) return () => {};
    if (projects && projects.length) {
      setProjects(projects);
      if (
        !selectedProject.id ||
        !projects.find((project) => project.id === selectedProject.id) ||
        selectedProject.org_id !== user.selectedOrg.id
      ) {
        setSelectedProject(projects[0]);
      }
    } else {
      setProjects([]);
      setFleets([]);
      setDevices([]);

      setSelectedProject({} as IProject);
      setSelectedFleet({} as IFleet);

      // if not on project creation page, redirect to projects page
      if (
        location.pathname !== "/fleet-and-devices/projects/project-details"
      ) {
        navigate("/fleet-and-devices/projects");
      }
    }
  }, [
    projects,
    selectedProject.id,
    selectedProject.org_id,
    user.selectedOrg.id,
    projectsLoading
  ]);

  return (
    <div className="bg-background-layer1 min-h-screen pt-[64px] flex flex-col pb-8 font-dm-sans">
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={toggleSidebar} />
      <div className="flex flex-col flex-1 h-full overflow-hidden bg-background-layer1 text-contentColor">
        <CommonHeader />
        {projectsLoading || orgsLoading ? <ShowLoading /> : <Outlet />}
      </div>
    </div>
  );
};

export default Layout;
