import Modal from "@/app/shared/components/modal.component";
import { useDeleteProject } from "@/app/shared/hooks/delete/delete-project";
import { useFleetAndDevicesStore } from "@/store";
import { Button, Callout } from "@tremor/react";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

type IDeleteProjectModalProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

const DeleteProjectModal: React.FC<IDeleteProjectModalProps> = ({
  open,
  setOpen
}) => {
  const [inputValue, setInputValue] = useState("");
  const selectedProject = useFleetAndDevicesStore(
    (state) => state.selectedProject
  );

  useEffect(() => {
    setInputValue("");
  }, [open]);

  const deleteProjectMutation = useDeleteProject();

  const onProjectDelete = () => {
    deleteProjectMutation.mutate(null, {
      onSuccess: () => {
        setOpen(false);
        toast.success("Project deleted successfully");
      }
    });
  };

  return (
    <Modal open={open} setOpen={setOpen}>
      <div className="flex flex-col gap-4 p-6 max-w-md  bg-background text-contentColor">
        <h1 className="text-lg font-bold">{"Delete Project"}</h1>
        <Callout title="Danger" color={"red"}>
          Once you delete a project, you get <b>1 Hour</b> to restore it. After
          that, it will be permanently deleted, along with all its resources.{" "}
          <br />
          Restore a project by going to <br />
          <b>Organization Settings &gt; Deleted Projects</b>
        </Callout>
        <p>
          Enter the project name (<b>{selectedProject?.project_name}</b>) to
          confirm deletion
        </p>
        <input
          type="text"
          placeholder="Project Name"
          onPaste={(e) => e.preventDefault()}
          onDrop={(e) => e.preventDefault()}
          className="block p-3 bg-background border-background-layer3 rounded-md focus:ring focus:ring-opacity-40 focus:ring-primaryLight focus:border-primaryLight sm:text-sm"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
        />
        <div className="flex justify-end gap-4">
          <Button variant="secondary" onClick={() => setOpen(false)}>
            {"Cancel"}
          </Button>
          <Button
            variant="primary"
            loading={deleteProjectMutation.isLoading}
            color="red"
            onClick={onProjectDelete}
            disabled={inputValue !== selectedProject?.project_name}
          >
            {"Delete"}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteProjectModal;
