import useSettingsStore from "@store/settings/settings.store";
import { AddOverlayLayout } from "../../shared/components";
import {
  ICreateInvitePayload,
  useCreateInvite
} from "@app/shared/hooks/post/create-invite";
import InviteForm, { IInviteFormState } from "./invite-form.component";
import { FormikHelpers } from "formik";
import { toast } from "react-toastify";

const CreateInvite = () => {
  const [showCreateInviteForm, setShowCreateInviteForm] = useSettingsStore(
    (state) => [state.showCreateInviteForm, state.setShowCreateInviteForm]
  );

  const createInviteMutation = useCreateInvite();

  const handleSubmit = async (
    values: IInviteFormState,
    formikHelpers: FormikHelpers<IInviteFormState>
  ) => {
    const payload: ICreateInvitePayload = {
      emails: [values.email],
      permissions: values.resource_access.map((perm) => ({
        resource_type: perm.resource_type.value,
        resource: perm.resource.value,
        actions: perm.actions.map((acc) => acc.value)
      })),
      org_access: values.org_access.toLowerCase() as "member" | "admin",
      org_permissions: values.org_perms
    };

    if (values.groups?.length) {
      values.groups.forEach((group) => {
        payload.permissions.push({
          resource_type: "group",
          resource: group,
          actions: [values.groups_admin ? "admin" : "member"]
        });
      });
    }

    createInviteMutation.mutate(payload, {
      onSuccess: () => {
        toast.success("Invited Successfully");

        formikHelpers.resetForm();
      }
    });
  };

  return (
    <AddOverlayLayout
      title="Invite a Collaborator"
      overlayOpen={showCreateInviteForm}
      setOverlayOpen={setShowCreateInviteForm}
    >
      <div className="flex flex-col h-full items-center">
        <InviteForm
          showAddToGroup={true}
          renderAs="invite"
          handleSubmit={handleSubmit}
        />
      </div>
    </AddOverlayLayout>
  );
};

export default CreateInvite;
