import React, { useMemo } from "react";
import { Link, Outlet, useLocation, useParams } from "react-router-dom";
import { NotFound } from "../shared/components";
// import { AnalyticsProjects } from "."

import OrgNavigation from "./components/org-navigation.component";
import ProjectNavigation from "./components/project-navigation.component";
import SettingsCommonHeader from "./components/settings-common-header.component";
import { useGetUserGroupsDetails } from "../shared/hooks/get/user-groups-details";

const SettingsProvider: React.FC = () => {
  const location = useLocation();
  const params = useParams();

  const groupId = params["groupId"];

  const { data: groupDetails } = useGetUserGroupsDetails(groupId);

  const orgflag =
    location.pathname.includes(
      "/settings/organization-settings/org-details"
    ) ||
    location.pathname.includes("/settings/organization-settings/members") ||
    location.pathname.includes("/settings/organization-settings/invites") ||
    location.pathname.includes("/settings/organization-settings/api-keys") ||
    location.pathname.includes(
      "/settings/organization-settings/deleted-projects"
    );

  const projectFlag = location.pathname.includes(
    "/settings/project-settings/project-details"
  );

  const userGroupFlag = location.pathname.includes(
    "/settings/organization-settings/groups"
  );

  const commonHeaderSubtitle = useMemo(() => {
    if ((orgflag || userGroupFlag) && !groupId) {
      return "Organization Settings";
    } else if (projectFlag) {
      return "Project Settings";
    } else if (userGroupFlag && groupId) {
      return (
        <div className="flex gap-2">
          <Link
            to="/settings/organization-settings/groups"
            className="text-primary hover:underline"
          >
            User Groups
          </Link>
          <span>&gt;</span>
          <span>{groupDetails?.group_name}</span>
        </div>
      );
    }
  }, [groupDetails?.group_name, groupId, orgflag, projectFlag, userGroupFlag]);

  return (
    <>
      <SettingsCommonHeader
        title={orgflag || userGroupFlag ? "Org Settings" : "Project settings"}
        subtitle={commonHeaderSubtitle}
      />
      {orgflag ? (
        <>
          <main className="flex-1 mx-8 mt-7 space-y-4 overflow-hidden lg:px-8 sm:px-6 bg-background text-contentColor rounded-md">
            <OrgNavigation />
            <Outlet />
          </main>
        </>
      ) : projectFlag ? (
        <>
          <main className="flex-1 mx-8 mt-7 space-y-4 overflow-hidden lg:px-8 sm:px-6 bg-background text-contentColor rounded-md">
            <ProjectNavigation />
            <Outlet />
          </main>
        </>
      ) : userGroupFlag ? (
        <>
          <main className="flex-1 flex flex-col mx-8 mt-7 overflow-hidden lg:px-8 sm:px-6 bg-background text-contentColor rounded-md">
            {!groupId ? <OrgNavigation /> : null}
            <Outlet />
          </main>
        </>
      ) : (
        <NotFound />
      )}
    </>
  );
};

export default SettingsProvider;
