import { useMutation, useQueryClient } from "react-query";

import networkService from "@services/network.service";
import { useAuthStore, useFleetAndDevicesStore } from "@store/index";
import { IDeviceBlueprintDefinition } from "@/interfaces/device-blueprint.interface";

export interface ICreateFleetDeviceBlueprintPayload {
  template_name: string;
  template: IDeviceBlueprintDefinition;
}

interface ICreateFleetDeviceBlueprintResponse {
  data: { fleetDeviceTemplateId: string };
  ok: number;
}

export const createFleetDeviceBlueprint = async (
  fleetId,
  projectId: string,
  orgId: string,
  payload: ICreateFleetDeviceBlueprintPayload
) => {
  const { data, ok } =
    await networkService.post<ICreateFleetDeviceBlueprintResponse>(
      `projects/${projectId}/fleets/${fleetId}/device_templates`,
      payload,
      {
        headers: {
          "ORG-ID": orgId
        }
      }
    );

  if (ok) {
    return data.fleetDeviceTemplateId;
  } else return null;
};

export const useCreateFleetDeviceBlueprint = () => {
  const [selectedProject] = useFleetAndDevicesStore((state) => [
    state.selectedProject
  ]);
  const [user] = useAuthStore((state) => [state.user]);
  const queryClient = useQueryClient();

  const res = useMutation(
    async ({
      data,
      fleetId
    }: {
      data: ICreateFleetDeviceBlueprintPayload;
      fleetId: string;
    }) =>
      await createFleetDeviceBlueprint(
        fleetId,
        selectedProject.id,
        user.selectedOrg.id,
        data
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getFleetDeviceBlueprints");
      }
    }
  );

  return res;
};
