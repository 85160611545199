import { useMutation, useQueryClient } from "react-query";

import networkService from "@services/network.service";
import { useAuthStore } from "@store/index";
import { IPermission } from "@interfaces/apps.interface";
import { ICreateInviteResponse } from "@interfaces/invites.interface";

export interface ICreateUserGroupPayload {
  name: string;
  permissions: IPermission[];
  org_access: "MEMBER" | "ADMIN" | "OWNER";
}

export const deleteUserGroup = async (groupId: string, orgId: string) => {
  const { data, ok } = await networkService.delete<ICreateInviteResponse>(
    `organizations/groups/${groupId}`,
    {},
    {
      headers: {
        "ORG-ID": orgId
      }
    }
  );

  if (ok) {
    return data;
  } else return null;
};

export const useDeleteUserGroup = () => {
  const [user] = useAuthStore((state) => [state.user]);
  const queryClient = useQueryClient();

  const res = useMutation(
    async (groupId: string) =>
      await deleteUserGroup(groupId, user.selectedOrg.id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getUserGroups");
      }
    }
  );

  return res;
};
