import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import { useFleetAndDevicesStore } from "@/store";
import TemplatesAll from "./device-blueprints-all.component";
import { useGetFleets } from "@/app/shared/hooks/get/fleets";
import FleetHeader from "../../fleet-and-devices/components/fleet-header.component";
import ShowLoading from "@/app/shared/components/loading.component";

const DeviceBlurprintsProvider: React.FC = () => {
  const location = useLocation();

  const [setFleets] = useFleetAndDevicesStore((state) => [state.setFleets]);

  const { data: fleets, isLoading: isFleetLoading } = useGetFleets(
    {},
    (fleets) => {
      setFleets(fleets);
    }
  );

  const flag =
    location.pathname === "/blueprints/devices" ||
    location.pathname === "/blueprints/devices/";

  if (isFleetLoading) {
    return <ShowLoading />;
  }

  return (
    <>
      {fleets?.length > 0 ? (
        <FleetHeader
          baseURL={"/blueprints/devices"}
          showCreate={false}
          showDelete={false}
          showRefresh={false}
        />
      ) : null}
      {flag ? (
        <>
          <TemplatesAll />
        </>
      ) : (
        <>
          <main className="flex-1 flex flex-col mx-8 mt-7 xl:mr-28 lg:px-8 sm:px-6 bg-background text-contentColor rounded-md">
            <Outlet />
          </main>
        </>
      )}
    </>
  );
};

export default DeviceBlurprintsProvider;
